import type { UserOpResponse } from '@biconomy/account'
import {
	type NetworkId,
	PerpsProvider,
	type SimulatedRequest,
	type SnxV2NetworkIds,
	type SnxV3NetworkIds,
} from '@kwenta/sdk/types'
import { isSimulatedRequest, simulatedRequestToTxRequest } from '@kwenta/sdk/utils'

import { createAsyncThunk } from '@reduxjs/toolkit'
import type { SendUserOpParameters, Transaction } from 'types/accountAbstraction'
import { type Address, type Hash, type Hex, isHash } from 'viem'

import { monitorAndAwaitTransaction, monitorAndAwaitUserOp } from 'state/app/helpers'
import { handleTransactionError } from 'state/app/reducer'
import { handleFetchError } from 'state/helpers'
import { FetchStatus, type ThunkConfig } from 'state/types'
import { serializeIsolatedMarginBalanceInfo } from 'utils/futures'
import logError from 'utils/logError'

import { setQueryStatus, updateAccountData } from '../reducer'
import { selectAccountContext, selectAccountContexts } from '../selectors'

import type { UserOperationStruct_v7 } from '@aa-sdk/core'
import type KwentaSDK from '@kwenta/sdk'
import type { AlchemySmartAccountService } from 'services/alchemy/alchemySmartAccount'
import { selectAbstractionWalletEnabled } from 'state/wallet/selectors'
import { selectCrossMarginAssetsForPrices } from '../snxPerpsV3/selectors'
import { selectOneClickTradingSelected } from './selectors'

export const fetchAccountMarginInfo = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchAccountMarginInfo',
	async (providers, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())
		for (const provider of providers) {
			try {
				const { network: networkId, accountId, wallet } = contexts[provider]
				if (!accountId || !networkId || !wallet) continue

				dispatch(setQueryStatus({ key: 'get_balance_info', status: FetchStatus.Loading, provider }))

				switch (provider) {
					case PerpsProvider.SNX_V3_BASE: {
						const assets = selectCrossMarginAssetsForPrices(getState())

						const {
							availableMargin,
							withdrawableMargin,
							requiredInitialMargin,
							requiredMaintenanceMargin,
							maxLiquidationReward,
							debt,
						} = await sdk.snxPerpsV3.getAccountMarginInfo(
							BigInt(accountId),
							assets,
							networkId as SnxV3NetworkIds
						)
						dispatch(
							updateAccountData({
								wallet,
								data: {
									provider: provider,
									account: accountId,
									network: networkId,
									marginInfo: {
										availableMargin: availableMargin.toString(),
										withdrawableMargin: withdrawableMargin.toString(),
										requiredInitialMargin: requiredInitialMargin.toString(),
										requiredMaintenanceMargin: requiredMaintenanceMargin.toString(),
										maxLiquidationReward: maxLiquidationReward.toString(),
										debt: debt.toString(),
									},
								},
							})
						)
						break
					}

					case PerpsProvider.SNX_V2_OP: {
						const balanceInfoSnxV2 = await sdk.snxPerpsV2.getSmartMarginBalanceInfo(
							wallet,
							accountId as Address,
							networkId as SnxV2NetworkIds
						)

						dispatch(
							updateAccountData({
								wallet,
								data: {
									provider: PerpsProvider.SNX_V2_OP,
									balanceInfo: serializeIsolatedMarginBalanceInfo({
										...balanceInfoSnxV2,
										totalMarginByMarket: {},
									}),
									network: networkId,
									account: accountId,
								},
							})
						)
						break
					}
				}

				dispatch(setQueryStatus({ key: 'get_balance_info', status: FetchStatus.Success, provider }))
			} catch (err) {
				handleFetchError(dispatch, 'get_balance_info', err, { provider })
			}
		}
	}
)

export const submitFuturesTransaction = createAsyncThunk<
	void,
	{
		request: Transaction | SimulatedRequest | SendUserOpParameters
		onSuccess?: VoidFunction
		onError?: (err: Error) => void
	},
	ThunkConfig
>(
	'futures/submitFuturesTransaction',
	async (
		{ request, onSuccess, onError },
		{ dispatch, getState, extra: { sdk, accountAbstractionFactory, smartAccount } }
	) => {
		const state = getState()
		const { provider, network } = selectAccountContext(state)
		const isOneClickReady = selectOneClickTradingSelected(state)
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const abstractionWalletEnabled = selectAbstractionWalletEnabled(state)

		const isUsingOneClick = Boolean(accountAbstraction && isOneClickReady)

		const isSimulatedAction = isSimulatedRequest(request)

		let res: Hash | UserOpResponse

		try {
			if (abstractionWalletEnabled) {
				const txParams =
					'userOp' in request
						? { userOp: request.userOp as UserOperationStruct_v7 }
						: {
								simulateTxs: isSimulatedAction ? [request] : [],
								readyTxs: isSimulatedAction ? [] : [request],
							}
				res = await smartAccount.submitTransaction(txParams)
			} else if (isUsingOneClick) {
				if ('userOp' in request) {
					res = await accountAbstraction!.sendUserOperation(request)
				} else {
					res = await accountAbstraction!.sendTransactions({
						simulateTxs: isSimulatedAction ? [request] : [],
						readyTxs: isSimulatedAction ? [] : [request],
					})
				}
			} else {
				if ('userOp' in request) {
					throw new Error('UserOps are not supported for non-AA providers')
				}

				res = await sdk.transactions.sendTransaction(
					isSimulatedAction ? simulatedRequestToTxRequest(request) : request,
					network
				)
			}

			if (typeof res === 'string' && isHash(res)) {
				await monitorAndAwaitTransaction(network, dispatch, res)
			} else {
				await monitorAndAwaitUserOp(dispatch, res)
			}
			onSuccess?.()
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			onError?.(err)
		}
	}
)

export const executeTransaction = async (
	request: Transaction | SimulatedRequest,
	network: NetworkId,
	smartAccount: AlchemySmartAccountService,
	sdk: KwentaSDK,
	abstractionWalletEnabled = false
): Promise<Hex | undefined> => {
	const isSimulatedAction = isSimulatedRequest(request)
	try {
		if (abstractionWalletEnabled) {
			return await smartAccount.submitTransaction({
				simulateTxs: isSimulatedAction ? [request] : [],
				readyTxs: isSimulatedAction ? [] : [request],
			})
		}

		return isSimulatedAction
			? await sdk.transactions.writeContract({ ...request, value: undefined }, network)
			: await sdk.transactions.sendTransaction(request, network)
	} catch (err) {
		logError(err)
		throw err
	}
}

export const submitTransaction = createAsyncThunk<
	void,
	{ request: Transaction | SimulatedRequest },
	ThunkConfig
>(
	'futures/submitTransaction',
	async ({ request }, { getState, dispatch, extra: { sdk, smartAccount } }) => {
		const { network } = selectAccountContext(getState())
		const abstractionWalletEnabled = selectAbstractionWalletEnabled(getState())

		const txHash = await executeTransaction(
			request,
			network,
			smartAccount,
			sdk,
			abstractionWalletEnabled
		)

		if (txHash) {
			await monitorAndAwaitTransaction(network, dispatch, txHash)
		}
	}
)
