import KwentaSDK from '@kwenta/sdk'

import { type NetworkId, SupportedNetworkIds } from '@kwenta/sdk/types'

import logError from 'utils/logError'

enum TenderlyNetwork {
	ETHEREUM_MAINNET = 'mainnet',
	OPTIMISM_MAINNET = 'optimism',
	OPTIMISM_SEPOLIA = 'optimism-sepolia',
	BASE_SEPOLIA = 'base-sepolia',
	BASE_MAINNET = 'base',
}

export const TENDERLY_NETWORK_LOOKUP: Record<number, TenderlyNetwork> = {
	1: TenderlyNetwork.ETHEREUM_MAINNET,
	10: TenderlyNetwork.OPTIMISM_MAINNET,
	11155420: TenderlyNetwork.OPTIMISM_SEPOLIA,
	84532: TenderlyNetwork.BASE_SEPOLIA,
	8453: TenderlyNetwork.BASE_MAINNET,
}

enum AlchemyNetwork {
	ETHEREUM_MAINNET = 'eth-mainnet',
	OPTIMISM_MAINNET = 'opt-mainnet',
	OPTIMISM_SEPOLIA = 'opt-sepolia',
	BASE_SEPOLIA = 'base-sepolia',
	BASE_MAINNET = 'base-mainnet',
}

export const ALCHEMY_NETWORK_LOOKUP: Record<number, AlchemyNetwork> = {
	1: AlchemyNetwork.ETHEREUM_MAINNET,
	10: AlchemyNetwork.OPTIMISM_MAINNET,
	11155420: AlchemyNetwork.OPTIMISM_SEPOLIA,
	84532: AlchemyNetwork.BASE_SEPOLIA,
	8453: AlchemyNetwork.BASE_MAINNET,
}

const NETWORK_IDS = [
	SupportedNetworkIds.BASE_MAINNET,
	SupportedNetworkIds.BASE_SEPOLIA,
	SupportedNetworkIds.OPTIMISM_MAINNET,
	SupportedNetworkIds.OPTIMISM_SEPOLIA,
	SupportedNetworkIds.ETHEREUM_MAINNET,
]

export const supportedChains: Partial<Record<NetworkId, string[]>> = NETWORK_IDS.reduce(
	(acc, id) => {
		acc[id] = [
			`https://${ALCHEMY_NETWORK_LOOKUP[id]}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY!}`,
			`https://${TENDERLY_NETWORK_LOOKUP[id]}.gateway.tenderly.co/${process.env.NEXT_PUBLIC_TENDERLY_PROJECT_ID!}`,
		]
		return acc
	},
	{} as Partial<Record<NetworkId, string[]>>
)

const sdk = new KwentaSDK({
	supportedChains,
	apiUrl: process.env.NEXT_PUBLIC_KWENTA_SERVER!,
	pythUrl: process.env.NEXT_PUBLIC_PYTH_HERMES_ENDPOINT,
	proxyUrl: process.env.NEXT_PUBLIC_SERVICES_PROXY!,
	satsumaApiKey: process.env.NEXT_PUBLIC_SATSUMA_API_KEY!,
	logError,
})

export default sdk
