import { ZERO_WEI } from '@kwenta/sdk/constants'
import {
	type ConditionalOrderV2,
	FuturesMarginType,
	type FuturesMarketAsset,
	type FuturesTrade,
	OrderTypeEnum,
	type PerpsMarketV2,
	PerpsProvider,
	PositionSide,
	TransactionStatus,
} from '@kwenta/sdk/types'
import { isZero } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import type { ConditionOrderTableItemIsolated } from 'types/futures'
import type { Address } from 'viem'

import { ERROR_MESSAGES } from 'components/ErrorNotifier'
import { DEFAULT_DELAYED_CANCEL_BUFFER } from 'constants/defaults'
import { previewErrorI18n } from 'queries/futures/constants'
import { selectTransaction } from 'state/app/selectors'
import { selectPrices } from 'state/prices/selectors'
import type { RootState } from 'state/store'
import { FetchStatus } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import {
	formatFuturesPositions,
	orderPriceInvalidLabel,
	providerIsCrossMargin,
	stopLossValidity,
	takeProfitValidity,
} from 'utils/futures'

import {
	selectEditPositionInputs,
	selectIsolatedMarginAccountData,
	selectIsolatedMarginProvider,
	selectIsolatedMaxLeverage,
	selectLeverageSide,
	selectMarkPriceInfos,
	selectMarkPrices,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketInfo,
	selectMarketOnchainPrice,
	selectMarketPriceInfo,
	selectMarkets,
	selectMarketsByProvider,
	selectPerpsProvider,
	selectRawTradePanelInputs,
	selectSlTpTradeInputs,
	selectSnxPerpsV2Network,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectUserInfoShowAllMarkets,
	selectV2Markets,
} from '../common/selectors'
import type { MarkPrices } from '../common/types'
import type { AsyncOrderWithDetails } from '../snxPerpsV3/types'

import type { IsolatedMarginBalanceInfo } from './types'

export const selectSnxV2Account = createSelector(
	selectWallet,
	(state: RootState) => state.futures,
	(wallet, futures) => {
		return wallet
			? (futures.accounts[PerpsProvider.SNX_V2_OP]?.[wallet]?.account as Address)
			: undefined
	}
)

export const selectSnxV2AccountContext = createSelector(
	selectWallet,
	selectSnxPerpsV2Network,
	selectSnxV2Account,
	(wallet, network, accountId) => {
		return { wallet, network, accountId, provider: PerpsProvider.SNX_V2_OP, isIsolatedMargin: true }
	}
)

export const selectIsCreatingIsolatedMarginAccount = createSelector(
	selectTransaction,
	(tx) =>
		tx?.status === TransactionStatus.AwaitingExecution &&
		tx?.type === 'create_isolated_margin_account'
)

export const selectIsolatedMarginMarginDelta = createSelector(selectRawTradePanelInputs, (inputs) =>
	wei(inputs.marginDelta || 0)
)

export const selectMarginDeltaInputValue = createSelector(
	selectRawTradePanelInputs,
	(inputs) => inputs.marginDelta ?? ''
)

export const selectSnxV2AccountData = createSelector(
	selectWallet,
	(state: RootState) => state.futures,
	(wallet, futures) => {
		return wallet ? futures.accounts[PerpsProvider.SNX_V2_OP]?.[wallet] : null
	}
)

export const selectOptimismMarkPrices = createSelector(
	selectV2Markets,
	selectPrices,
	(optimismMarkets, prices) => {
		const markPrices: MarkPrices<string> = {}
		return optimismMarkets.reduce((acc, market) => {
			const price = prices[market.asset]?.offChain ?? wei(0)
			acc[market.asset] = wei(price)
				.mul(wei(market.marketSkew).div(market.settings.skewScale).add(1))
				.toString()
			return acc
		}, markPrices)
	}
)

export const selectAllIsolatedConditionalOrders = createSelector(
	selectMarketsByProvider,
	selectSnxV2AccountData,
	selectMarkPriceInfos,
	(markets, snxAccount, prices) => {
		const format = (orders: ConditionalOrderV2<string>[], provider: PerpsProvider) =>
			orders.reduce<ConditionOrderTableItemIsolated<string>[]>((acc, o) => {
				const price = prices[o.asset]
				const market = markets[provider]?.find((m) => m.asset === o.asset)
				if (!price || !market || market.marginType !== FuturesMarginType.ISOLATED_MARGIN) return acc

				const tradeDirection = wei(o.size).lt(0) ? PositionSide.SHORT : PositionSide.LONG

				acc.push({
					...o,
					provider: market.provider,
					maxExecutorFee: '0',
					marketName: market.marketName,
					marketAddress: market.marketAddress,
					currentPrice: price,
					tradeDirection,
				})
				return acc
			}, [])

		return {
			[PerpsProvider.SNX_V2_OP]: format(
				snxAccount?.conditionalOrders ?? [],
				PerpsProvider.SNX_V2_OP
			),
		}
	}
)

export const selectAllSnxV2Positions = createSelector(
	selectSnxV2AccountData,
	selectAllIsolatedConditionalOrders,
	selectV2Markets,
	selectMarkPrices,
	(account, orders, markets, prices) => {
		return formatFuturesPositions(
			account?.positionHistory ?? [],
			markets,
			orders[PerpsProvider.SNX_V2_OP],
			prices
		)
	}
)

export const selectSmartMarginActivePositions = createSelector(
	selectSnxV2AccountData,
	selectAllIsolatedConditionalOrders,
	selectV2Markets,
	selectMarkPrices,
	(account, orders, markets, prices) => {
		return formatFuturesPositions(
			account?.positions ?? [],
			markets,
			orders[PerpsProvider.SNX_V2_OP],
			prices
		)
	}
)

export const selectIsolatedPositionsCount = createSelector(
	selectSmartMarginActivePositions,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(snxPositions, userInfoShowAllMarkets, currentMarket) => {
		const snxPositionsCount = userInfoShowAllMarkets
			? snxPositions.length
			: snxPositions.filter((p) => p.market.asset === currentMarket).length

		return {
			[PerpsProvider.SNX_V2_OP]: snxPositionsCount,
		}
	}
)

const selectSubmittingFuturesTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)
const selectIsMarketCapReached = createSelector(
	selectLeverageSide,
	selectMarketInfo,
	selectMarketIndexPrice,
	(leverageSide, marketInfo, marketAssetRate) => {
		const maxMarketValueUSD = wei(
			(leverageSide === PositionSide.LONG
				? marketInfo?.marketLimitUsd.long
				: marketInfo?.marketLimitUsd.short) ?? 0
		)
		const oi = marketInfo?.openInterest ?? { long: '0', short: '0' }
		const marketSkew = wei(marketInfo?.marketSkew ?? 0)
		if (!marketInfo) return true

		return leverageSide === PositionSide.LONG
			? wei(oi.long).add(marketSkew).div('2').abs().mul(wei(marketAssetRate)).gte(maxMarketValueUSD)
			: wei(oi.short)
					.sub(marketSkew)
					.div('2')
					.abs()
					.mul(wei(marketAssetRate))
					.gte(maxMarketValueUSD)
	}
)

export const selectSelectedSnxV2Position = createSelector(
	selectSmartMarginActivePositions,
	selectMarketInfo,
	(positions, market) => positions.find((p) => p.market.asset === market?.asset)
)

export const selectSnxV2IsolatedBalanceInfo = createSelector(
	selectSnxV2AccountData,
	(snxAccount) => {
		return snxAccount
			? snxAccount.balanceInfo
			: ({
					freeMargin: '0',
					keeperEthBal: '0',
					allowance: '0',
					factoryApproved: false,
					idleMarginByMarket: {},
					totalMarginByMarket: {},
					balance: '0',
				} as IsolatedMarginBalanceInfo<string>)
	}
)

const selectSnxV2AvailableMarginInMarkets = createSelector(
	selectSnxV2IsolatedBalanceInfo,
	(balanceInfo) => {
		const totalIdleMargin = Object.keys(balanceInfo.idleMarginByMarket).reduce((acc, key) => {
			const assetKey = key as FuturesMarketAsset
			return acc.add(wei(balanceInfo.idleMarginByMarket[assetKey] ?? 0))
		}, wei(0))
		return totalIdleMargin
	}
)

const selectAvailableMarginInMarkets = createSelector(
	selectSnxV2IsolatedBalanceInfo,
	(balanceInfo) => {
		const totalIdleMargin = Object.keys(balanceInfo.idleMarginByMarket).reduce((acc, key) => {
			const assetKey = key as FuturesMarketAsset
			return acc.add(wei(balanceInfo.idleMarginByMarket[assetKey] ?? 0))
		}, wei(0))
		return totalIdleMargin.toString()
	}
)

export const selectSwapDepositAllowance = createSelector(
	selectSnxV2IsolatedBalanceInfo,
	(smartMarginBalanceInfo) => smartMarginBalanceInfo.allowance
)

export const selectApprovingSwapDeposit = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app.transaction,
	(submitting, transaction) => submitting && transaction?.type === 'approve_cross_margin'
)

export const selectKeeperEthBalance = createSelector(selectSnxV2AccountData, (account) =>
	wei(account?.balanceInfo.keeperEthBal || 0).toString()
)

const selectIsolatedTradePanelPreview = createSelector(
	selectIsolatedMarginProvider,
	(state: RootState) => state.futures,
	(provider, { tradePreviews }) => {
		if (!provider) return
		const preview = tradePreviews[provider]
		if (preview && preview.action === 'trade') {
			return preview
		}
		return
	}
)

export const selectAllSnxV2SLTPOrders = createSelector(
	selectAllIsolatedConditionalOrders,
	(orders) => {
		return orders[PerpsProvider.SNX_V2_OP].filter(
			(o) =>
				o.reduceOnly && (o.orderTypeDisplay === 'Stop Loss' || o.orderTypeDisplay === 'Take Profit')
		)
	}
)

const selectIsolatedMarginTradePreview = createSelector(
	selectIsolatedTradePanelPreview,
	(tradePreview) => {
		if (tradePreview) {
			return {
				...tradePreview,
				leverage: wei(tradePreview.margin).gt(0)
					? wei(tradePreview.notionalValue).div(tradePreview.margin).abs().toNumber()
					: 0,
			}
		}
		return null
	}
)

const selectIsolatedActivePositions = createSelector(selectSmartMarginActivePositions, (snxV2) => {
	return snxV2
})

export const selectSnxV2LockedMarginInMarkets = createSelector(
	selectSnxV2IsolatedBalanceInfo,
	selectMarkets,
	(balanceInfo, markets) => {
		return Object.keys(balanceInfo.idleMarginByMarket)
			.reduce((acc, key) => {
				const assetKey = key as FuturesMarketAsset
				const market = markets.find((m) => m.asset === assetKey)

				if (market?.isSuspended) {
					return acc.add(wei(balanceInfo.idleMarginByMarket[assetKey] ?? 0))
				}

				return acc.add(ZERO_WEI)
			}, wei(0))
			.toString()
	}
)

export const selectIdleAccountMargin = createSelector(
	selectAvailableMarginInMarkets,
	selectSnxV2LockedMarginInMarkets,
	selectSnxV2IsolatedBalanceInfo,
	(availableInMarkets, lockedMargin, { freeMargin }) => {
		return wei(lockedMargin).add(wei(availableInMarkets)).add(wei(freeMargin))
	}
)

export const selectEditMarginAllowanceValid = createSelector(
	selectIsolatedMarginAccountData,
	selectSnxV2IsolatedBalanceInfo,
	selectAvailableMarginInMarkets,
	selectEditPositionInputs,
	(account, { freeMargin }, idleInMarkets, { marginDelta }) => {
		if (!account || !marginDelta || Number.isNaN(Number(marginDelta))) return false

		const totalIdleMargin = wei(freeMargin).add(wei(idleInMarkets))
		const marginDelatWei = wei(marginDelta || 0)
		const marginDeposit = marginDelatWei.sub(totalIdleMargin)
		return (
			totalIdleMargin.gte(marginDelatWei) ||
			wei(account.balanceInfo.allowance || 0).gte(marginDeposit)
		)
	}
)

const selectSnxV2AvailableMargin = createSelector(
	selectSnxV2AvailableMarginInMarkets,
	selectSnxV2IsolatedBalanceInfo,
	(idleInMarkets, { freeMargin }) => {
		return idleInMarkets.add(wei(freeMargin)).toString()
	}
)

export const selectIsolatedAvailableMargin = createSelector(
	selectSnxV2AvailableMargin,
	(snxV2AvailableMargin) => {
		return snxV2AvailableMargin
	}
)

export const selectIsolatedActivePositionsMargin = createSelector(
	selectIsolatedActivePositions,
	(positions) => {
		return positions
			.reduce((acc, p) => {
				return acc.add(p.details.margin.remainingMargin)
			}, wei(0))
			.toString()
	}
)

export const selectIsolatedAccountEquity = createSelector(
	selectPerpsProvider,
	selectIsolatedAvailableMargin,
	selectIsolatedActivePositionsMargin,
	(provider, availableMargin, positionsMargin) => {
		if (providerIsCrossMargin(provider)) return wei(0)
		return wei(availableMargin).add(positionsMargin).toString()
	}
)

const selectIsolatedTradePanelLeverage = createSelector(
	selectIsolatedAvailableMargin,
	selectTradePanelInputs,
	(availableMargin, { susdSize }) => {
		if (!availableMargin || wei(availableMargin).eq(0) || !susdSize) return wei(0)
		return wei(susdSize || 0).div(availableMargin)
	}
)

export const selectAccountMarginTransfers = createSelector(selectSnxV2AccountData, (account) => {
	if (!account) return []
	return account?.accountTransfers ?? []
})

const selectTradePreviewError = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.get_trade_preview_trade?.error
	}
)

export const selectCloseTradePreviewError = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.get_trade_preview_close?.error
	}
)

export const selectIsFetchingTradePreviewSmartMargin = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return (
			futures.queryStatuses.get_trade_preview_close?.status === FetchStatus.Loading ||
			futures.queryStatuses.get_trade_preview_edit?.status === FetchStatus.Loading ||
			futures.queryStatuses.get_trade_preview_trade?.status === FetchStatus.Loading
		)
	}
)

const selectTradePreviewStatus = createSelector(
	(state: RootState) => state.futures,
	(futures) => {
		return futures.queryStatuses.get_trade_preview_trade?.status
	}
)

type SmartMarginTrade<T> = FuturesTrade<T> & {
	market: PerpsMarketV2<T>
}

export const selectAllSmartMarginTrades = createSelector(
	selectSnxV2AccountData,
	selectV2Markets,
	(smartMarginAccountData, markets) => {
		const trades = smartMarginAccountData?.trades ?? ([] as FuturesTrade<string>[])
		return trades.reduce<SmartMarginTrade<string>[]>((acc, t) => {
			const market = markets.find((m) => m.asset === t.asset)
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [] as SmartMarginTrade<string>[])
	}
)

export const selectSelectedPortfolioTimeframe = (state: RootState) =>
	state.futures.dashboard.selectedPortfolioTimeframe

export const selectCancellingConditionalOrder = (state: RootState) =>
	state.futures.cancellingConditionalOrder

const selectMaxUsdSizeInput = createSelector(
	selectIsolatedMaxLeverage,
	selectMarginDeltaInputValue,
	(maxLeverage, marginDelta) => {
		return wei(maxLeverage).mul(marginDelta || 0)
	}
)

export const selectTotalFuturesFees = createSelector(
	(state: RootState) => state.futures.providerData,
	(data) => {
		return {
			[PerpsProvider.SNX_V2_OP]: wei(data.totalFeesPaid[PerpsProvider.SNX_V2_OP] ?? '0'),
		}
	}
)

const selectFuturesFeesForAccount = createSelector(selectSnxV2AccountData, (snxV2Account) => {
	return {
		[PerpsProvider.SNX_V2_OP]: wei(snxV2Account?.totalFeesPaid ?? '0'),
	}
})

export const selectFeeShare = createSelector(
	selectFuturesFeesForAccount,
	selectTotalFuturesFees,
	(accountFees, totalFees) => {
		return {
			[PerpsProvider.SNX_V2_OP]: totalFees[PerpsProvider.SNX_V2_OP].gt(0)
				? accountFees[PerpsProvider.SNX_V2_OP].div(totalFees[PerpsProvider.SNX_V2_OP])
				: ZERO_WEI,
		}
	}
)

export const selectSMPlaceOrderTranslationKey = createSelector(
	selectPerpsProvider,
	selectSelectedSnxV2Position,
	selectTradeOrderType,
	(provider, snxV2Position, orderType) => {
		if (orderType === OrderTypeEnum.LIMIT) return 'futures.market.trade.button.place-limit-order'
		if (orderType === OrderTypeEnum.STOP) return 'futures.market.trade.button.place-stop-order'
		if (provider === PerpsProvider.SNX_V2_OP && !!snxV2Position)
			return 'futures.market.trade.button.modify-position'
		return 'futures.market.trade.button.open-position'
	}
)

export const selectOldestIsolatedGlobalTrade = createSelector(
	selectPerpsProvider,
	selectMarketAsset,
	(state: RootState) => state.futures.providerData.globalTradeHistory,
	(perpsProvider, marketAsset, tradesHistory) => {
		if (!marketAsset || providerIsCrossMargin(perpsProvider)) return undefined
		const trades = tradesHistory[perpsProvider][marketAsset]
		return trades?.length && trades?.length > 0 ? trades[trades?.length - 1] : undefined
	}
)

const selectIsolatedTradePanelSLTPValidity = createSelector(
	selectSlTpTradeInputs,
	selectIsolatedMarginTradePreview,
	selectMarketIndexPrice,
	selectMarketOnchainPrice,
	selectLeverageSide,
	(
		{ stopLossPrice, takeProfitPrice },
		smTradePreview,
		currentPrice,
		onChainPrice,
		leverageSide
	) => {
		const tpValidity = takeProfitValidity(
			takeProfitPrice,
			leverageSide,
			wei(currentPrice),
			onChainPrice
		)
		const slValidity = stopLossValidity(
			stopLossPrice,
			wei(smTradePreview?.liqPrice ?? 0),
			leverageSide,
			wei(currentPrice),
			onChainPrice
		)
		return {
			takeProfit: tpValidity,
			stopLoss: slValidity,
		}
	}
)

export const selectIsolatedMarginDelayedOrders = createSelector(
	selectSnxV2AccountData,
	selectV2Markets,
	(account, markets) => {
		if (!account) return []
		const orders = account?.delayedOrders ?? []

		return orders.reduce<AsyncOrderWithDetails[]>((acc, o) => {
			const market = markets.find((m) => m.asset === o.asset)

			if (market?.provider === PerpsProvider.SNX_V2_OP) {
				const timePastExecution = Math.floor(Date.now() - o.executableAtTimestamp)
				const expirationTime = o.executableAtTimestamp + market.settings.offchainDelayedOrderMaxAge
				const executable = timePastExecution <= market.settings.offchainDelayedOrderMaxAge

				const order = {
					market,
					account: Number(account.account),
					size: o.size,
					executableStartTime: o.executableAtTimestamp / 1000,
					expirationTime: expirationTime,
					marginDelta: '0',
					desiredFillPrice: o.desiredFillPrice,
					side: o.side,
					settlementWindowDuration: market.settings.offchainDelayedOrderMaxAge,
					settlementFee: o.keeperDeposit,
					isExecutable: executable,
					isStale:
						timePastExecution >
						DEFAULT_DELAYED_CANCEL_BUFFER + (market?.settings.offchainDelayedOrderMaxAge ?? 0),
				}
				acc.push(order)
			}
			return acc
		}, [] as AsyncOrderWithDetails[])
	}
)

const selectSnxV2PendingDelayedOrder = createSelector(
	selectIsolatedMarginDelayedOrders,
	selectMarketAsset,
	(delayedOrders, marketAsset) => {
		return delayedOrders.find((o) => o.market.asset === marketAsset)
	}
)

export const selectIsolatedTradeDisabledReason = createSelector(
	selectIsolatedMarginTradePreview,
	selectTradePreviewError,
	selectMarketPriceInfo,
	selectIsolatedTradePanelLeverage,
	selectIsolatedMaxLeverage,
	selectMarketInfo,
	selectIsMarketCapReached,
	selectSelectedSnxV2Position,
	selectLeverageSide,
	selectTradePanelOrderPriceInput,
	selectMarketIndexPrice,
	selectTradeOrderType,
	selectMaxUsdSizeInput,
	selectTradePanelInputs,
	selectPerpsProvider,
	selectTradePreviewStatus,
	selectSnxV2PendingDelayedOrder,
	selectIsolatedTradePanelSLTPValidity,
	(
		previewTrade,
		previewError,
		indexPrice,
		leverage,
		maxLeverageValue,
		marketInfo,
		isMarketCapReached,
		position,
		leverageSide,
		orderPrice,
		marketAssetRate,
		orderType,
		maxUsdInputAmount,
		{ susdSize },
		selectedPerpsProvider,
		previewStatus,
		openOrder,
		sltpValidity
	) => {
		const MessageType = {
			error: 'error',
			warn: 'warn',
		} as const
		if (!previewTrade) return { message: 'Pending preview' }

		if (previewError) {
			return { message: previewErrorI18n(previewError), show: MessageType.error }
		}
		if (previewTrade?.statusMessage && previewTrade.statusMessage !== 'Success') {
			return { message: previewTrade?.statusMessage, show: MessageType.error }
		}

		const maxLeverage = marketInfo?.appMaxLeverage ?? wei(25)

		const indexPriceWei = wei(indexPrice?.price || 0)
		let canLiquidate =
			previewTrade?.provider === PerpsProvider.SNX_V2_OP
				? (wei(previewTrade.newSize).gt(0) && indexPriceWei.lt(previewTrade?.liqPrice)) ||
					(wei(previewTrade.newSize).lt(0) && indexPriceWei.gt(previewTrade?.liqPrice))
				: (previewTrade?.side === PositionSide.LONG && indexPriceWei.lt(previewTrade?.liqPrice)) ||
					(previewTrade?.side === PositionSide.SHORT && indexPriceWei.gt(previewTrade?.liqPrice))
		canLiquidate = canLiquidate && !wei(previewTrade.liqPrice).eq(0)

		if (canLiquidate) {
			return {
				show: MessageType.warn,
				message: 'Position can be liquidated',
			}
		}
		if (leverage.gt(maxLeverageValue)) {
			return {
				show: MessageType.warn,
				message: `Max leverage ${maxLeverage.toString(0)}x exceeded`,
			}
		}
		if (marketInfo?.isSuspended) {
			return {
				show: MessageType.warn,
				message: 'Market suspended',
			}
		}
		if (
			isMarketCapReached &&
			(!position?.details.side || position?.details.side === leverageSide)
		) {
			return {
				show: MessageType.warn,
				message: 'Open interest limit exceeded',
			}
		}

		const invalidReason = orderPriceInvalidLabel(
			orderPrice,
			leverageSide,
			marketAssetRate,
			orderType
		)

		if (orderType !== OrderTypeEnum.MARKET && !!invalidReason) {
			return {
				message: `Invalid ${orderType} price`,
			}
		}
		if (wei(susdSize || 0).gt(maxUsdInputAmount)) {
			return {
				show: MessageType.warn,
				message: 'Max trade size exceeded',
			}
		}

		if (isZero(susdSize)) {
			return { message: 'Trade size required' }
		}
		if (orderType === OrderTypeEnum.MARKET && !!openOrder && !openOrder.isStale) {
			return {
				show: MessageType.warn,
				message: ERROR_MESSAGES.ORDER_PENDING,
			}
		}
		if (selectedPerpsProvider === PerpsProvider.SNX_V2_OP) {
			if (previewTrade?.status !== 0 || previewStatus === FetchStatus.Loading) {
				return { message: 'awaiting_preview' }
			}
			if (orderType !== OrderTypeEnum.MARKET && isZero(orderPrice)) {
				return { message: 'trade price required' }
			}
		}

		if (sltpValidity.stopLoss.invalidLabel) {
			return {
				show: MessageType.warn,
				message: 'Invalid Stop Loss Price',
			}
		}

		return null
	}
)

export const selectWithdrawableFromIsolatedAccount = createSelector(
	selectAvailableMarginInMarkets,
	selectSnxV2IsolatedBalanceInfo,
	(idleInMarkets, { freeMargin }) => {
		return wei(idleInMarkets).add(wei(freeMargin)).toString()
	}
)
