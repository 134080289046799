import type { NetworkId } from '@kwenta/sdk/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useAccountAbstractionConnect from 'services/biconomy/useAccountAbstractionConnect'
import { createContainer } from 'unstated-next'
import {
	useAccount,
	useAccountEffect,
	useDisconnect,
	usePublicClient,
	useSwitchChain,
	useWalletClient,
} from 'wagmi'

import { DEFAULT_CHAIN } from 'constants/network'
import { selectAccountContext, selectShowTestnets } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress, setWalletClient } from 'state/wallet/actions'
import { disconnect, setWalletNetworkId } from 'state/wallet/reducer'
import { selectIsWatcherMode, selectWatcherWallet } from 'state/wallet/selectors'

import { useConnectModal } from '@rainbow-me/rainbowkit'
import SuccessIcon from 'assets/svg/app/success.svg'
import { notifyError } from 'components/ErrorNotifier'
import { Body } from 'components/Text'
import { FlexDivCol, FlexDivRowCentered } from 'components/layout/flex'
import { useAlchemySmartAccount } from 'hooks/useAlchemySmartAccount'
import { useMarketPageRoute } from 'hooks/useMarketPageRoute'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { SOCIAL_LOGIN_ENABLED } from 'sections/accounts/constants'
import { setOpenModal } from 'state/app/reducer'
import { clearAuthToken, clearSavedAddresses } from 'state/futures/reducer'
import styled from 'styled-components'
import type { Chain } from 'wagmi/chains'
import { generateExplorerFunctions, getBaseUrl } from './blockExplorer'
import { wagmiConfig, wagmiConfigWithTestnet } from './config'

export let blockExplorer = generateExplorerFunctions(getBaseUrl(DEFAULT_CHAIN.id as NetworkId))

const StyledBody = styled(Body)`
	font-size: 13px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.banner.default.text};
`

const SignedOutNotification = () => {
	const { t } = useTranslation()

	return (
		<FlexDivCol rowGap="10px">
			<FlexDivRowCentered justifyContent="flex-start" columnGap="5px">
				<SuccessIcon width={12} height={12} />
				{t('common.transaction.notification-title.success')}
			</FlexDivRowCentered>
			<StyledBody>{t('common.transaction.sign-out-success')}</StyledBody>
		</FlexDivCol>
	)
}

const useConnector = () => {
	// General
	const dispatch = useAppDispatch()
	const router = useRouter()
	const marketPageRoute = useMarketPageRoute()
	const [providerReady, setProviderReady] = useState(false)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const watcherWallet = useAppSelector(selectWatcherWallet)
	const chainWithTestnet = useAppSelector(selectShowTestnets)
	const { network: providerChainId } = useAppSelector(selectAccountContext)
	const config = useMemo(
		() => (chainWithTestnet ? wagmiConfigWithTestnet : wagmiConfig),
		[chainWithTestnet]
	)

	useEffect(() => {
		if (providerChainId) {
			setProviderReady(true)
			blockExplorer = generateExplorerFunctions(getBaseUrl(providerChainId))
		}
	}, [providerChainId])

	// EOA
	const { isConnected: isEoaConnected, chain: eoaChain, address: eoaAddress } = useAccount()
	const { data: eoaWalletClient } = useWalletClient({ config })
	const { openConnectModal } = useConnectModal()
	const { disconnectAsync: eoaDisconnect } = useDisconnect()
	const { switchChain } = useSwitchChain()
	const eoaPublicClient = usePublicClient({ config })
	useAccountAbstractionConnect({ walletClient: eoaWalletClient })

	useEffect(() => {
		if (!isWatcherMode && eoaWalletClient) {
			dispatch(setWalletClient(eoaWalletClient))
		}
	}, [dispatch, isWatcherMode, eoaWalletClient])

	useEffect(() => {
		if (isEoaConnected && eoaChain) {
			dispatch(resetWalletAddress({ address: eoaAddress, selectedType: 'signer' }))
			dispatch(setWalletNetworkId(eoaChain.id as NetworkId))
		}
	}, [dispatch, eoaAddress, eoaChain, isEoaConnected])

	// AA
	const {
		isConnected: isAaConnected,
		chain: aaChain,
		alchemySigner,
		setChain,
	} = useAlchemySmartAccount()

	// wallet connected status and chain info
	const isWalletConnected = isEoaConnected || isAaConnected || (isWatcherMode && !!watcherWallet)
	const activeChain = isWalletConnected ? eoaChain || aaChain : DEFAULT_CHAIN

	// Connect / Disconnect wallet on account change
	useAccountEffect({
		onDisconnect: () => dispatch(disconnect()),
	})

	// Wallet Actions
	const login = useCallback(() => {
		if (SOCIAL_LOGIN_ENABLED) {
			dispatch(setOpenModal('sign_in'))
		} else {
			openConnectModal?.()
		}
	}, [dispatch, openConnectModal])

	const logout = useCallback(async () => {
		try {
			// if not on trade page, redirect to trade page
			if (!router.pathname.includes('market')) {
				router.push(marketPageRoute())
			}
			// Disconnect wallet
			if (isAaConnected && alchemySigner) {
				await alchemySigner.disconnect()
				dispatch(disconnect())
				dispatch(clearAuthToken())
				dispatch(clearSavedAddresses())
			} else {
				await eoaDisconnect()
			}
			toast(<SignedOutNotification />, {
				position: 'top-right',
				toastId: 'logout',
				containerId: 'notifications',
				bodyClassName: 'successBody',
				progressClassName: 'successProgress',
			})
		} catch (e) {
			notifyError('Error disconnecting wallet', e)
		}
	}, [eoaDisconnect, isAaConnected, alchemySigner, router, dispatch, marketPageRoute])

	const switchToChain = useCallback(
		async (network: Chain) => {
			return isAaConnected ? setChain({ chain: network }) : switchChain({ chainId: network.id })
		},
		[isAaConnected, setChain, switchChain]
	)

	return {
		network: activeChain,
		isWalletConnected,
		client: eoaPublicClient,
		walletClient: eoaWalletClient,
		providerReady,
		alchemySigner,
		login,
		logout,
		switchToChain,
	}
}

const Connector = createContainer(useConnector)

export default Connector
