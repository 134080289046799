import type { SupportedAccountTypes } from '@account-kit/core'
import {
	useAccount,
	useChain,
	useSigner,
	useSignerStatus,
	useSmartAccountClient,
	useUser,
} from '@account-kit/react'
import type { NetworkId } from '@kwenta/sdk/types'
import { PROVIDER_MAINNET_CHAINS } from 'constants/network'
import { useCallback, useEffect } from 'react'
import { smartAccount } from 'services/alchemy/alchemySmartAccount'
import { fetchSavedAddresses } from 'state/futures/actions'
import { clearAuthToken, clearSavedAddresses, setAuthToken } from 'state/futures/reducer'
import { selectAccountContext, selectAuthToken } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress } from 'state/wallet/actions'
import {
	setAccountAbstractionOwnerAddress,
	setEmailAddress,
	setSmartAccountEnabled,
	setWalletNetworkId,
} from 'state/wallet/reducer'
import { SIGN_IN_STATEMENT, createMessage, loginWithSiwe } from 'utils/auth'
import logError from 'utils/logError'

const accountType: SupportedAccountTypes = 'MultiOwnerModularAccount'

const invalidAuthToken = (authToken: string | null) => {
	if (!authToken) return true

	const payloadBase64 = authToken.split('.')[1]
	// Decode the base64url encoded payload
	const payload = JSON.parse(atob(payloadBase64))
	// The expiration time is in the 'exp' claim (Unix timestamp in seconds)
	return payload.exp * 1000 <= Date.now()
}

export const useAlchemySmartAccount = () => {
	const dispatch = useAppDispatch()
	const { provider } = useAppSelector(selectAccountContext)
	const authToken = useAppSelector(selectAuthToken)
	const { setChain } = useChain()
	const chain = PROVIDER_MAINNET_CHAINS[provider]
	const { address } = useAccount({ type: accountType })
	const { isConnected } = useSignerStatus()
	const user = useUser()
	const alchemySigner = useSigner()
	const { client: aaWalletClient } = useSmartAccountClient({
		type: accountType,
		opts: {
			feeOptions: {
				preVerificationGas: {
					multiplier: 1.4,
				},
				maxFeePerGas: {
					multiplier: 1.4,
				},
			},
			txMaxRetries: 10,
			txRetryIntervalMs: 1000,
			txRetryMultiplier: 2,
		},
	})

	useEffect(() => {
		if (!user) return
		dispatch(setEmailAddress(user.email))
	}, [dispatch, user])

	useEffect(() => {
		if (aaWalletClient) {
			smartAccount.setClient(aaWalletClient)
		}
		if (alchemySigner) {
			smartAccount.setSigner(alchemySigner)
		}
	}, [aaWalletClient, alchemySigner])

	const fetchAuthToken = useCallback(async () => {
		dispatch(clearSavedAddresses())
		dispatch(clearAuthToken())
		if (!user || !alchemySigner) return
		const message = createMessage(user.address, SIGN_IN_STATEMENT)
		try {
			const signature = await alchemySigner.signMessage(message)
			const { token } = await loginWithSiwe(message, signature)
			dispatch(setAuthToken(token))
		} catch (error) {
			logError(error)
		}
	}, [user, alchemySigner, dispatch])

	useEffect(() => {
		const fetchAaSignerAddress = async () => {
			if (!alchemySigner) return
			const signerAddress = await alchemySigner.getAddress()
			dispatch(setAccountAbstractionOwnerAddress(signerAddress))
		}
		if (isConnected) {
			fetchAaSignerAddress()
			setChain({ chain })
			dispatch(resetWalletAddress({ address, selectedType: 'signer' }))
			dispatch(setWalletNetworkId(chain.id as NetworkId))
			dispatch(setSmartAccountEnabled(true))
		}
	}, [address, dispatch, isConnected, chain, setChain, alchemySigner])

	useEffect(() => {
		if (isConnected && invalidAuthToken(authToken)) {
			fetchAuthToken()
		}
	}, [isConnected, authToken, fetchAuthToken])

	useEffect(() => {
		if (authToken) {
			dispatch(fetchSavedAddresses())
		}
	}, [authToken, dispatch])

	return {
		isConnected,
		chain,
		alchemySigner,
		setChain,
	}
}
