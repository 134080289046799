import { type FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAddPasskey } from '@account-kit/react'
import BaseModal from 'components/BaseModal'
import { TransactionButton } from 'components/Button/Button'
import { notifyError } from 'components/ErrorNotifier'
import ErrorView from 'components/ErrorView'
import Spacer from 'components/Spacer'
import { Body } from 'components/Text'
import { FlexDivCol } from 'components/layout/flex'
import { EVENT_NAME } from 'constants/matomoEvents'
import Connector from 'containers/Connector'
import { useAnalytics } from 'hooks/useAnalytics'
import { setOpenModal } from 'state/app/reducer'
import { useAppDispatch } from 'state/hooks'
import media from 'styles/media'
import logError from 'utils/logError'

type Props = {
	onDismiss(): void
}

const PasskeySetup: FC<Props> = memo(({ onDismiss }) => {
	const { t } = useTranslation()
	const { alchemySigner } = Connector.useContainer()
	const dispatch = useAppDispatch()
	const { trackAccountCreation } = useAnalytics()

	const [showPasskeyModal, setShowPasskeyModal] = useState(false)
	const { addPasskey, isAddingPasskey } = useAddPasskey({
		onSuccess: () => handleCreateAccount(),
		onError: (error) => {
			logError(error)
			notifyError('Failed to add passkey. You can add passkey later in the account page.', error)
		},
	})

	const handleCreateAccount = useCallback(() => {
		setShowPasskeyModal(false)
		trackAccountCreation(EVENT_NAME.CLICK_CREATE_ACCOUNT)
		dispatch(setOpenModal('acknowledgement_terms'))
	}, [dispatch, trackAccountCreation])

	useEffect(() => {
		const stopListening = alchemySigner?.on('newUserSignup', () => {
			setShowPasskeyModal(true)
		})

		return stopListening
	}, [alchemySigner])

	return (
		showPasskeyModal && (
			<StyledBaseModal title={t('account.passkey-setup.title')} onDismiss={onDismiss}>
				<Spacer height={20} />
				<FlexDivCol rowGap="24px">
					<StyledBody color="secondary">{t('account.passkey-setup.copy')}</StyledBody>
					<FlexDivCol rowGap="16px">
						<TransactionButton
							variant="solid-highlight"
							fullWidth
							onClick={() => addPasskey()}
							loading={isAddingPasskey}
							disabled={isAddingPasskey}
							style={{ flex: 2 }}
						>
							{t('account.passkey-setup.setup-passkey-button')}
						</TransactionButton>
						<TransactionButton
							variant="flat"
							fullWidth
							onClick={() => handleCreateAccount()}
							disabled={isAddingPasskey}
							style={{ flex: 1 }}
						>
							{t('account.passkey-setup.later-button')}
						</TransactionButton>
					</FlexDivCol>
					<ErrorView
						messageType="info"
						message={t('account.passkey-setup.message')}
						containerStyle={{
							padding: '10px',
							margin: '0',
							textAlign: 'left',
							fontSize: '12px',
						}}
					/>
				</FlexDivCol>
			</StyledBaseModal>
		)
	)
})

const StyledBody = styled(Body)`
	line-height: 1.5;
	font-size: 13px;
`

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		max-width: 430px;
		margin-top: 300px;
	}

	${media.lessThan('md')`
		[data-reach-dialog-content] {
			max-width: 100%;
		}
	`}
`

export default PasskeySetup
