import { AlchemyAccountProvider } from '@account-kit/react'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/browser'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import {
	type FC,
	type ReactElement,
	type ReactNode,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { WagmiProvider } from 'wagmi'

import '@rainbow-me/rainbowkit/styles.css'
import '@reach/dialog/styles.css'
import { DEFAULT_FONT } from 'constants/ui'
import Connector from 'containers/Connector'
import { alchemyConfig } from 'containers/Connector/alchemyConfig'
import { wagmiConfig, wagmiConfigWithTestnet } from 'containers/Connector/config'
import useMonitorTransactions from 'hooks/useMonitorTransactions'
import MaintenanceModal from 'sections/app/MaintenanceModal'
import PasskeySetup from 'sections/app/PasskeySetup'
import Layout from 'sections/shared/Layout'
import SystemStatus from 'sections/shared/SystemStatus'
import { useAppData } from 'state/app/hooks'
import { setOpenModal } from 'state/app/reducer'
import { selectShowTestnets } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import store from 'state/store'
import '@notifi-network/notifi-react/dist/index.css'
import 'styles/notifi.css'
import 'styles/main.css'
import { themes } from 'styles/theme'
import { IGNORE_ERRORS } from 'utils/logError'
import { getDesignTokens } from 'utils/theme'
import '../i18n'
import { selectCurrentTheme } from 'state/preferences/selectors'

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

Sentry.init({
	dsn: 'https://d48644bc80d04977a26132b346417210@o4504363236851712.ingest.sentry.io/4504363261362177',
	maxBreadcrumbs: 50,
	debug: process.env.NODE_ENV !== 'production',
	enabled: process.env.NODE_ENV === 'production',
	release: `kwenta@${process.env.GIT_HASH_ID!.toString()}`,
	autoSessionTracking: true,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.3,
	ignoreErrors: IGNORE_ERRORS,
})

const queryClient = new QueryClient()

const InnerApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
	const dispatch = useAppDispatch()
	const [isReady, setReady] = useState(false)
	const { providerReady } = Connector.useContainer()
	const onDismiss = useCallback(() => dispatch(setOpenModal(null)), [dispatch])

	useAppData(providerReady)
	useMonitorTransactions()

	const currentTheme = useAppSelector(selectCurrentTheme)
	const theme = useMemo(() => themes[currentTheme], [currentTheme])

	// @ts-ignore palette options
	const muiTheme = useMemo(() => createTheme(getDesignTokens('dark')), [])

	// @ts-ignore palette options
	useEffect(() => {
		setReady(true)
	}, [])

	const content = useMemo(() => {
		const getLayout = Component.getLayout || ((page) => page)

		return process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ? (
			<MaintenanceModal />
		) : (
			<>
				<PasskeySetup onDismiss={onDismiss} />
				<SystemStatus>{getLayout(<Component {...pageProps} />)}</SystemStatus>
			</>
		)
	}, [Component, onDismiss, pageProps])

	return isReady ? (
		<ThemeProvider theme={theme}>
			<MuiThemeProvider theme={muiTheme}>
				<div id="root">
					<style jsx global>{`
							* {
								font-family: ${DEFAULT_FONT.style.fontFamily};
							}
						`}</style>
					<Layout>{content}</Layout>
				</div>
			</MuiThemeProvider>
		</ThemeProvider>
	) : null
}

const WagmiConfigWrapper: FC<AppProps> = memo((props) => {
	const showTestnet = useAppSelector(selectShowTestnets)

	return (
		<WagmiProvider config={showTestnet ? wagmiConfigWithTestnet : wagmiConfig}>
			<RainbowKitProvider theme={darkTheme()}>
				<Connector.Provider>
					<InnerApp {...props} />
				</Connector.Provider>
			</RainbowKitProvider>
		</WagmiProvider>
	)
})

const App: FC<AppProps> = (props) => {
	const { t } = useTranslation()

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
				/>
				<meta name="title" content={t('meta.og.title')} />
				<meta name="description" content={t('meta.description')} />
				{/* open graph */}
				<meta property="og:title" content={t('meta.og.title')} />
				<meta property="og:description" content={t('meta.description')} />
				<meta property="og:url" content="https://exchange.synthetix.io/" />
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content="https://exchange.synthetix.io/images/synthetix-exchange-facebook.jpg"
				/>
				<meta property="og:image:alt" content={t('meta.og.title')} />
				<meta property="og:site_name" content={t('meta.og.site-name')} />
				{/* twitter */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@SNX_Exchange" />
				<meta name="twitter:creator" content="@SNX_Exchange" />
				<meta
					name="twitter:image"
					content="https://exchange.synthetix.io/images/synthetix-exchange-twitter.jpg"
				/>
				<meta name="twitter:url" content="https://exchange.synthetix.io" />
				<link rel="icon" type="image/svg" href="/images/favicon.svg?v=2" sizes="32x32" />
				<link rel="icon" type="image/png" href="/images/favicon.png?v=2" sizes="32x32" />
				<link rel="apple-touch-icon" href="/images/apple-icon.png?v=2" sizes="180x180" />
				<link rel="mask-icon" href="/images/favicon.svg?v=2" color="#00d1ff" />
			</Head>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<AlchemyAccountProvider config={alchemyConfig} queryClient={queryClient}>
						<WagmiConfigWrapper {...props} />
						<ReactQueryDevtools initialIsOpen={false} />
					</AlchemyAccountProvider>
				</QueryClientProvider>
			</Provider>
		</>
	)
}

export default App
