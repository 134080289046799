import { Inter } from 'next/font/google'

export const HEADER_HEIGHT = '96px'

export const HOURS_TOGGLE_HEIGHT = '25px'
export const MOBILE_FOOTER_HEIGHT = '71px'

export enum zIndex {
	BASE = 1,
	HEADER = 10,
	DIALOG_OVERLAY = 51,
	MOBILE_FOOTER = 50,
	DRAWER = 65,
}

export const FEEREBATE_DISABLED = true

export const DASHBOARD_SECTION_HEIGHT = 335
export const DASHBOARD_SECTION_HEIGHT_PX = `${DASHBOARD_SECTION_HEIGHT}px`

export const DEFAULT_FONT = Inter({ subsets: ['latin'] })
