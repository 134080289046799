import type { DepositableAssetKeysV3Type } from '@kwenta/sdk/constants'
import {
	FuturesMarketAsset,
	type NetworkId,
	OrderTypeEnum,
	Period,
	PerpsProvider,
	type PerpsV3Liquidation,
	PositionSide,
	type SynthAssetKeysV3,
} from '@kwenta/sdk/types'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import { createMigrate, createTransform, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import type { SessionInfo } from 'types/accountAbstraction'

import { DEFAULT_PERPS_PROVIDER } from 'constants/futures'
import { ORDER_PREVIEW_ERRORS } from 'queries/futures/constants'
import type { HistoryTab } from 'sections/dashboard/History/HistoryTabs'
import {
	ZERO_STATE_ACCOUNT,
	ZERO_STATE_CM_ACCOUNT,
	ZERO_STATE_ISOLATED_ACCOUNT,
	ZERO_STATE_TRADE_INPUTS,
} from 'state/constants'
import { STORE_VERSION } from 'state/migrations'
import { FetchStatus } from 'state/types'
import { providerIsCrossMargin } from 'utils/futures'

import type { TableFilter } from '../../sections/futures/UserInfo/TableFilters/TableFilters'

import { FuturesTab } from 'sections/futures/UserInfo/UserInfoTabs'
import { addSavedAddress, fetchSavedAddresses, removeSavedAddress } from './actions'
import type {
	EditPositionInputs,
	FetchedGlobalTradeHistoryPayload,
	FetchedMarketsPayload,
	FetchedTraderPositions,
	FetchedVolumesPayload,
	FundingRates,
	PreviewAction,
	TradePanelInputs,
} from './common/types'
import type { IsolatedMarginTradePreview } from './isolatedMargin/types'
import type { BalanceSpenders, SnxPerpsV3TradePreview } from './snxPerpsV3/types'
import {
	type AccountsData,
	type DashboardHistoryTableFilter,
	type DebtPaymentQuote,
	type DelegationAccountInfo,
	type EditConditionalOrderInputs,
	type FuturesState,
	ManageModalType,
	type PartialAccountData,
	type PartialCrossMarginAccountData,
	type ProviderData,
	type TableFilteredFuturesTabs,
	TradingModes,
	type UIPreferencesPayload,
} from './types'

export const FUTURES_INITIAL_STATE: FuturesState = {
	queryStatuses: {},
	selectedMarketAsset: {
		[PerpsProvider.SNX_V2_OP]: FuturesMarketAsset.sETH,
		[PerpsProvider.SNX_V3_BASE]: FuturesMarketAsset.sETH,
	},
	tradePanelDrawerOpen: false,
	accounts: {
		[PerpsProvider.SNX_V2_OP]: {},
		[PerpsProvider.SNX_V3_BASE]: {},
	},
	preferences: {
		profitCalculatorVisible: false,
		selectedManageModalType: ManageModalType.Bridge,
		selectedInputHours: 1,
		selectedChart: 'price',
		selectedInputDenomination: 'usd',
		selectedProvider: DEFAULT_PERPS_PROVIDER,
		selectedAllNetworks: true,
		showHistory: true,
		showFavorites: true,
		showPositionLines: true,
		showOrderLines: true,
		showRealizedPnL: true,
		showTestnets: process.env.NODE_ENV !== 'production',
		showFunding: true,
		tradingMode: TradingModes.DEFAULT,
		unlimitedApproval: true,
		userInfoCollapsed: false,
		userInfoShowAllMarkets: true,
		historicalFundingRatePeriod: Period.TWO_WEEKS,
		favoriteMarkets: [FuturesMarketAsset.sBTC, FuturesMarketAsset.sETH],
		portfolioChartType: 'account',
		userInfoTableFilter: {
			trades: null,
			transaction_history: null,
			order_history: null,
		},
		dashboardHistoryTableFilter: {
			positions: null,
			trades: null,
			transaction_history: null,
			order_history: null,
		},
	},
	oneClickTrading: {
		accountDelegated: false,
		sessionInfo: undefined,
		isSessionActive: false,
	},
	dashboard: {
		selectedPortfolioTimeframe: Period.ONE_WEEK,
	},
	leaderboard: {
		selectedTrader: undefined,
		pageSize: 10,
		selectedTraderPositionHistory: {},
	},
	savedAddresses: [],
	authToken: null,
	delegation: {
		selectedDelegationAddress: '',
		selectedSubAccountAddress: '',
		newNickname: '',
		addressBook: {},
		subAccountsForWallet: {
			[PerpsProvider.SNX_V2_OP]: {},
			[PerpsProvider.SNX_V3_BASE]: {},
		},
	},
	sltpModalInputs: {
		stopLossPrice: '',
		takeProfitPrice: '',
	},
	editConditionalOrderInputs: {
		orderId: 0,
		margin: '',
		size: '',
		orderPrice: {
			price: '',
			invalidLabel: undefined,
		},
	},
	editPositionInputs: {
		nativeSizeDelta: '',
		marginDelta: '',
		orderPrice: '',
		orderType: OrderTypeEnum.MARKET,
	},
	closePositionOrderInputs: {
		orderType: OrderTypeEnum.MARKET,
		nativeSizeDelta: '',
		price: {
			value: '',
			invalidLabel: undefined,
		},
	},
	snxV2: {
		swapDepositSlippage: 0,
		swapDepositCustomSlippage: '',
	},
	snxV3: {
		debtPaymentQuote: undefined,
	},
	tradePreviews: {},
	previewDebounceCount: 0,
	tradePanel: {
		leverageSide: PositionSide.LONG,
		leverageInput: '',
		slippageInput: '',
		inputs: ZERO_STATE_TRADE_INPUTS,
	},
	providerData: {
		markets: {
			[PerpsProvider.SNX_V2_OP]: [],
			[PerpsProvider.SNX_V3_BASE]: [],
		},
		totalFeesPaid: {},
		dailyMarketVolumes: {},
		historicalFundingRates: {},
		globalLiquidationHistory: {},
		supportedCollaterals: {},
		globalTradeHistory: {
			[PerpsProvider.SNX_V2_OP]: {},
			[PerpsProvider.SNX_V3_BASE]: {},
		},
		maxDepositAmounts: {},
		spenders: {},
	},
	selectedTab: FuturesTab.POSITION,
}

const futuresSlice = createSlice({
	name: 'futures',
	initialState: FUTURES_INITIAL_STATE,
	reducers: {
		setMarkets: (state, { payload }: PayloadAction<FetchedMarketsPayload>) => {
			const { markets, provider } = payload
			switch (provider) {
				case PerpsProvider.SNX_V2_OP:
					state.providerData.markets[PerpsProvider.SNX_V2_OP] = markets
					break
				case PerpsProvider.SNX_V3_BASE:
					state.providerData.markets[provider] = markets
					break
			}
		},
		setSupportedCollaterals: (
			state,
			{ payload }: PayloadAction<{ provider: PerpsProvider; collaterals: SynthAssetKeysV3[] }>
		) => {
			const { collaterals, provider } = payload
			state.providerData.supportedCollaterals[provider] = collaterals
		},
		setVolumes: (state, { payload }: PayloadAction<FetchedVolumesPayload>) => {
			const { volumes, provider } = payload
			state.providerData.dailyMarketVolumes[provider] = volumes
		},
		setFundingRatesHistory: (
			state,
			{
				payload,
			}: PayloadAction<{
				provider: PerpsProvider
				rates: FundingRates
				asset: FuturesMarketAsset
			}>
		) => {
			const { provider, rates } = payload
			state.providerData.historicalFundingRates[provider] = {
				...state.providerData.historicalFundingRates[provider],
				[payload.asset]: rates,
			}
		},
		setGlobalTradeHistory: (
			state,
			{
				payload,
			}: PayloadAction<FetchedGlobalTradeHistoryPayload & { marketAsset: FuturesMarketAsset }>
		) => {
			const { provider, trades, marketAsset } = payload

			const existingTrades = state.providerData.globalTradeHistory[provider]?.[marketAsset] ?? []
			const allTrades = [...trades, ...existingTrades]
			const uniqueTrades = allTrades
				.filter((trade, index, self) => index === self.findIndex((t) => t.id === trade.id))
				.sort((a, b) => b.timestamp - a.timestamp)

			state.providerData.globalTradeHistory[provider] = {
				[marketAsset]: uniqueTrades,
			}
		},
		setTraderHistory: (state, { payload }: PayloadAction<FetchedTraderPositions>) => {
			const { provider, positions, traderAddress } = payload
			switch (provider) {
				case PerpsProvider.SNX_V2_OP:
					state.leaderboard.selectedTraderPositionHistory = {
						[PerpsProvider.SNX_V2_OP]: {
							[traderAddress]: positions,
						},
					}
					break
				case PerpsProvider.SNX_V3_BASE:
					state.leaderboard.selectedTraderPositionHistory = {
						[provider]: {
							[traderAddress]: positions,
						},
					}
					break
			}
		},
		setQueryStatus: (
			state,
			{
				payload,
			}: PayloadAction<{
				key: string
				provider?: PerpsProvider
				status: FetchStatus
				error?: string
			}>
		) => {
			state.queryStatuses[payload.key] = {
				provider: payload.provider,
				status: payload.status,
				error: payload.error,
			}
		},
		setSelectedMarketAsset: (
			state,
			action: PayloadAction<{ asset: FuturesMarketAsset; provider: PerpsProvider }>
		) => {
			state.selectedMarketAsset[action.payload.provider] = action.payload.asset
		},
		setAccount: (
			state,
			action: PayloadAction<{
				networkId: NetworkId
				wallet: string
				account: string
				provider: PerpsProvider
				marginEnginePermitted?: boolean
			}>
		) => {
			const { wallet, account, provider, marginEnginePermitted } = action.payload

			switch (provider) {
				case PerpsProvider.SNX_V2_OP:
					state.accounts[provider] = merge(state.accounts[provider] ?? {}, {
						[wallet]: {
							...ZERO_STATE_ACCOUNT,
							network: action.payload.networkId,
							provider: provider,
							account: account,
						},
					})
					break
				case PerpsProvider.SNX_V3_BASE:
					state.accounts[provider] = merge(state.accounts[provider] ?? {}, {
						[wallet]: {
							...ZERO_STATE_CM_ACCOUNT,
							network: action.payload.networkId,
							provider: provider,
							account: account,
							marginEnginePermitted,
						},
					})
					break
			}
		},
		setTradePreview: (
			state,
			{
				payload,
			}: PayloadAction<{
				provider: PerpsProvider
				preview: SnxPerpsV3TradePreview<string> | IsolatedMarginTradePreview<string> | undefined
			}>
		) => {
			switch (payload.provider) {
				case PerpsProvider.SNX_V3_BASE:
					state.tradePreviews[payload.provider] = payload.preview as SnxPerpsV3TradePreview<string>
					break
				case PerpsProvider.SNX_V2_OP:
					state.tradePreviews[payload.provider] =
						payload.preview as IsolatedMarginTradePreview<string>
					break
			}
		},

		clearTradePreview: (state) => {
			state.tradePreviews = {}
		},
		updateAccountData: (
			state,
			{
				payload,
			}: PayloadAction<{
				wallet: string
				data: PartialAccountData
			}>
		) => {
			const { wallet, data } = payload
			if (!wallet) return
			updateAccountDataUtil(state, wallet, data)
		},
		updateCrossMarginAccountData: (
			state,
			{
				payload,
			}: PayloadAction<{
				wallet: string
				data: PartialCrossMarginAccountData
			}>
		) => {
			const { wallet, data } = payload
			if (!wallet) return
			updateAccountDataUtil(state, wallet, data)
		},
		setSelectedTrader: (
			state,
			action: PayloadAction<
				| {
						trader: string
						traderEns: string | undefined | null
						accountId: string | undefined
				  }
				| undefined
			>
		) => {
			state.leaderboard.selectedTrader = action.payload
		},
		setLeaderboardPageSize: (state, action: PayloadAction<number>) => {
			state.leaderboard.pageSize = action.payload
		},
		setSelectedPortfolioTimeframe: (state, action: PayloadAction<Period>) => {
			state.dashboard.selectedPortfolioTimeframe = action.payload
		},
		setTradePanelDrawerOpen: (state, action: PayloadAction<boolean>) => {
			state.tradePanelDrawerOpen = action.payload
		},
		setIsSessionActive: (state, action: PayloadAction<boolean>) => {
			state.oneClickTrading.isSessionActive = action.payload
		},
		setDelegated: (state, action: PayloadAction<boolean>) => {
			state.oneClickTrading.accountDelegated = action.payload
		},
		setSession: (state, action: PayloadAction<SessionInfo | undefined>) => {
			state.oneClickTrading.sessionInfo = action.payload
		},
		setTradeStopLoss: (state, action: PayloadAction<string>) => {
			state.tradePanel.inputs.stopLossPrice = action.payload
		},
		setTradeTakeProfit: (state, action: PayloadAction<string>) => {
			state.tradePanel.inputs.takeProfitPrice = action.payload
		},
		setTradeInputs: (state, action: PayloadAction<Partial<TradePanelInputs<string>>>) => {
			state.tradePanel.inputs = {
				...(state.tradePanel.inputs ?? ZERO_STATE_TRADE_INPUTS),
				...action.payload,
			}
		},
		setMarginDelta: (state, action: PayloadAction<string>) => {
			state.tradePanel.inputs.marginDelta = action.payload
		},
		setLeverageInput: (state, action: PayloadAction<string>) => {
			state.tradePanel.leverageInput = action.payload
		},
		setSlippageInput: (state, action: PayloadAction<string>) => {
			state.tradePanel.slippageInput = action.payload
		},
		setLeverageSide: (state, action) => {
			state.tradePanel.leverageSide = action.payload
		},
		setTradePanelOrderPrice: (state, action: PayloadAction<string>) => {
			state.tradePanel.inputs.orderPrice.price = action.payload
		},
		setOrderPriceInvalidLabel: (state, action: PayloadAction<string | undefined>) => {
			state.tradePanel.inputs.orderPrice.invalidLabel = action.payload
		},
		setTradePanelOrderType: (state, action: PayloadAction<OrderTypeEnum>) => {
			state.tradePanel.inputs.orderType = action.payload
		},
		setClosePositionOrderType: (state, action: PayloadAction<OrderTypeEnum>) => {
			state.closePositionOrderInputs.orderType = action.payload
		},
		setClosePositionSizeDelta: (state, action: PayloadAction<string>) => {
			state.closePositionOrderInputs.nativeSizeDelta = action.payload
		},
		setClosePositionPrice: (
			state,
			action: PayloadAction<{ value: string; invalidLabel: string | null | undefined }>
		) => {
			state.closePositionOrderInputs.price = action.payload
		},
		setSelectedDelegationAddress: (state, action: PayloadAction<string>) => {
			state.delegation.selectedDelegationAddress = action.payload
		},
		setSelectedSubAccountAddress: (state, action: PayloadAction<string>) => {
			state.delegation.selectedSubAccountAddress = action.payload
		},
		setPreferences(state, action: PayloadAction<UIPreferencesPayload>) {
			state.preferences = {
				...state.preferences,
				...action.payload,
			}
		},
		setProfitCalculatorVisible(state, action: PayloadAction<boolean>) {
			state.preferences.profitCalculatorVisible = action.payload
		},
		setAuthToken: (state, action: PayloadAction<string>) => {
			state.authToken = action.payload
		},
		clearAuthToken: (state) => {
			state.authToken = null
		},
		clearSavedAddresses: (state) => {
			state.savedAddresses = []
		},
		addDelegateToAddressBook: (
			state,
			action: PayloadAction<{ wallet: string; delegate: DelegationAccountInfo }>
		) => {
			const { wallet, delegate } = action.payload
			if (state.delegation.addressBook[wallet]) {
				const addressExists = state.delegation.addressBook[wallet].some(
					({ address }) => address.toLowerCase() === delegate.address.toLowerCase()
				)
				if (!addressExists) {
					state.delegation.addressBook[wallet].push(delegate)
				}
			} else {
				state.delegation.addressBook[wallet] = [delegate]
			}
		},
		removeDelegateFromAddressBook: (
			state,
			action: PayloadAction<{ wallet: string; delegatedAddress: string }>
		) => {
			const { wallet, delegatedAddress } = action.payload
			if (state.delegation.addressBook[wallet]) {
				state.delegation.addressBook[wallet] = state.delegation.addressBook[wallet].filter(
					({ address }) => address.toLowerCase() !== delegatedAddress.toLowerCase()
				)
			}
		},
		setSubAccountsForWallet: (
			state,
			action: PayloadAction<{
				delegateeWallet: string
				subAccounts: DelegationAccountInfo[]
				provider: PerpsProvider
			}>
		) => {
			const { delegateeWallet, subAccounts, provider } = action.payload
			state.delegation.subAccountsForWallet[provider][delegateeWallet] = subAccounts
		},
		renameAddress: (
			state,
			action: PayloadAction<{ wallet: string; delegate: DelegationAccountInfo }>
		) => {
			const { wallet, delegate } = action.payload
			if (state.delegation.addressBook[wallet]) {
				const existingDelegateIndex = state.delegation.addressBook[wallet].findIndex(
					({ address }) => address.toLowerCase() === delegate.address.toLowerCase()
				)

				if (existingDelegateIndex !== -1) {
					state.delegation.addressBook[wallet][existingDelegateIndex].nickname = delegate.nickname
				} else {
					state.delegation.addressBook[wallet].push(delegate)
				}
			} else {
				state.delegation.addressBook[wallet] = [delegate]
			}
		},
		setNewNickname: (state, action: PayloadAction<string>) => {
			state.delegation.newNickname = action.payload
		},
		setSLTPModalStopLossPrice: (state, action: PayloadAction<string>) => {
			state.sltpModalInputs.stopLossPrice = action.payload
		},
		setSLTPModalTakeProfitPrice: (state, action: PayloadAction<string>) => {
			state.sltpModalInputs.takeProfitPrice = action.payload
		},
		setSLTPModalStopLossAmount: (state, action: PayloadAction<string>) => {
			state.sltpModalInputs.stopLossAmount = action.payload
		},
		setSLTPModalTakeProfitAmount: (state, action: PayloadAction<string>) => {
			state.sltpModalInputs.takeProfitAmount = action.payload
		},
		clearSLTPModalInputs: (state) => {
			state.sltpModalInputs = {
				stopLossPrice: '',
				takeProfitPrice: '',
			}
		},
		setEditConditonalOrderModalPrice: (state, action: PayloadAction<string>) => {
			state.editConditionalOrderInputs.orderPrice.price = action.payload
		},
		setConditionalOrderPriceInvalidLabel: (state, action: PayloadAction<string | undefined>) => {
			state.editConditionalOrderInputs.orderPrice.invalidLabel = action.payload
		},
		setEditConditonalOrderModalSize: (state, action: PayloadAction<string>) => {
			state.editConditionalOrderInputs.size = action.payload
		},
		setEditConditonalOrderModalMargin: (state, action: PayloadAction<string>) => {
			state.editConditionalOrderInputs.margin = action.payload
		},
		setEditConditonalOrderInputs: (
			smartMargin,
			action: PayloadAction<EditConditionalOrderInputs<string>>
		) => {
			smartMargin.editConditionalOrderInputs = action.payload
		},
		setEditPositionInputs: (state, action: PayloadAction<Partial<EditPositionInputs<string>>>) => {
			state.editPositionInputs = { ...state.editPositionInputs, ...action.payload }
		},
		clearEditPositionInputs: (state) => {
			state.editPositionInputs = {
				nativeSizeDelta: '',
				marginDelta: '',
				orderPrice: '',
				orderType: OrderTypeEnum.MARKET,
			}
		},
		toggleFavoriteMarket: (state, action: PayloadAction<{ asset: FuturesMarketAsset }>) => {
			const { asset } = action.payload
			if (state.preferences.favoriteMarkets) {
				const marketExists = state.preferences.favoriteMarkets.some(
					(market) => market.toLowerCase() === asset.toLowerCase()
				)
				if (marketExists) {
					state.preferences.favoriteMarkets = state.preferences.favoriteMarkets.filter(
						(market) => market.toLowerCase() !== asset.toLowerCase()
					)
				} else {
					state.preferences.favoriteMarkets.push(asset)
				}
			} else {
				state.preferences.favoriteMarkets = [asset]
			}
		},
		setUserInfoTableFilter: (
			state,
			action: PayloadAction<{
				tableFilter: TableFilter | null
				futuresTab: TableFilteredFuturesTabs
			} | null>
		) => {
			if (!action.payload) {
				state.preferences.userInfoTableFilter = {
					trades: null,
					transaction_history: null,
					order_history: null,
				}
			} else {
				const { tableFilter, futuresTab } = action.payload
				state.preferences.userInfoTableFilter[futuresTab] = tableFilter
			}
		},
		setDashboardHistoryTableFilter: (
			state,
			action: PayloadAction<{
				tableFilter: DashboardHistoryTableFilter | null
				historyTab: HistoryTab
			} | null>
		) => {
			if (!action.payload) {
				state.preferences.dashboardHistoryTableFilter = {
					positions: null,
					trades: null,
					transaction_history: null,
					order_history: null,
				}
			} else {
				const { tableFilter, historyTab } = action.payload
				state.preferences.dashboardHistoryTableFilter[historyTab] = tableFilter
			}
		},
		setTotalTradeFees: (
			state,
			action: PayloadAction<{ totalFees: string; provider: PerpsProvider }>
		) => {
			state.providerData.totalFeesPaid[action.payload.provider] = action.payload.totalFees
		},

		setCancellingConditionalOrder: (futures, { payload }: PayloadAction<number | undefined>) => {
			futures.cancellingConditionalOrder = payload
		},
		handlePreviewError: (
			futures,
			{
				payload,
			}: PayloadAction<{
				provider: PerpsProvider
				error: string
				previewType: PreviewAction
			}>
		) => {
			const message = Object.values(ORDER_PREVIEW_ERRORS).includes(payload.error)
				? payload.error
				: 'Failed to get trade preview'

			const queryKey = `get_trade_preview_${payload.previewType}`

			futures.queryStatuses = {
				...futures.queryStatuses,
				[queryKey]: {
					status: FetchStatus.Error,
					error: message,
				},
			}

			if (futures.tradePreviews[payload.provider]) {
				futures.tradePreviews[payload.provider] = undefined
			}
		},
		incrementPreviewCount: (futures) => {
			futures.previewDebounceCount = futures.previewDebounceCount + 1
		},
		setPerpsV3Spenders: (
			state,
			action: PayloadAction<{ spenders: BalanceSpenders | undefined; provider: PerpsProvider }>
		) => {
			state.providerData.spenders[action.payload.provider] = action.payload.spenders
		},

		setGlobalLiquidationHistory: (
			futures,
			action: PayloadAction<{
				provider: PerpsProvider
				marketId: string
				liqHistory: PerpsV3Liquidation<string>[]
			}>
		) => {
			futures.providerData.globalLiquidationHistory[action.payload.provider] = {
				...futures.providerData.globalLiquidationHistory[action.payload.provider],
				[action.payload.marketId]: action.payload.liqHistory,
			}
		},
		setSnxV3MaxDepositAmounts: (
			futures,
			action: PayloadAction<{
				provider: PerpsProvider
				maxDeposits: Partial<Record<DepositableAssetKeysV3Type, string>>
			}>
		) => {
			futures.providerData.maxDepositAmounts[action.payload.provider] = action.payload.maxDeposits
		},
		setSnxV3DebtPaymentQuote: (
			futures,
			action: PayloadAction<{
				quote: DebtPaymentQuote | undefined
			}>
		) => {
			futures.snxV3.debtPaymentQuote = action.payload.quote
		},
		setSelectedTab: (futures, action: PayloadAction<FuturesTab>) => {
			futures.selectedTab = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addSavedAddress.fulfilled, (state, action) => {
			state.savedAddresses.push(action.payload)
		})
		builder.addCase(removeSavedAddress.fulfilled, (state, action) => {
			state.savedAddresses = state.savedAddresses.filter((address) => address !== action.payload)
		})
		builder.addCase(fetchSavedAddresses.fulfilled, (state, action) => {
			state.savedAddresses = action.payload
		})
		builder.addCase(fetchSavedAddresses.rejected, (state) => {
			state.savedAddresses = []
		})
	},
})

const updateAccountDataUtil = (
	futures: FuturesState,
	wallet: string,
	newAccountData: PartialAccountData
) => {
	const { provider, account } = newAccountData
	if (providerIsCrossMargin(provider) && !account) return

	// TODO reduce repetitive code due to types
	if (provider === PerpsProvider.SNX_V3_BASE) {
		const updatedAccount = {
			...ZERO_STATE_CM_ACCOUNT,
			...futures.accounts[PerpsProvider.SNX_V3_BASE]?.[wallet],
			...newAccountData,
			account,
		}

		futures.accounts[provider] = {
			...futures.accounts[PerpsProvider.SNX_V3_BASE],
			[wallet]: updatedAccount,
		}
	}
	if (provider === PerpsProvider.SNX_V2_OP) {
		const updatedAccount = {
			...ZERO_STATE_ACCOUNT,
			...futures.accounts[provider]?.[wallet],
			...newAccountData,
			account,
		}

		futures.accounts[provider] = {
			...futures.accounts[provider],
			[wallet]: updatedAccount,
		}
	}
}

const accountsTransform = createTransform(
	(inboundState: AccountsData, _) => {
		const state = cloneDeep(inboundState)
		forEach(state, (wallets) => {
			forEach(wallets, (accountData) => {
				if (accountData.provider === PerpsProvider.SNX_V3_BASE) {
					accountData.marginSnapshots = ZERO_STATE_CM_ACCOUNT.marginSnapshots
					accountData.liquidations = ZERO_STATE_CM_ACCOUNT.liquidations
				} else if (accountData.provider === PerpsProvider.SNX_V2_OP) {
					accountData.delayedOrders = ZERO_STATE_ACCOUNT.delayedOrders
				}
				if (accountData.provider === PerpsProvider.SNX_V2_OP) {
					accountData.marketMarginTransfers = ZERO_STATE_ISOLATED_ACCOUNT.marketMarginTransfers
				}
				accountData.delegates = ZERO_STATE_ACCOUNT.delegates
				accountData.pnlSnapshots = ZERO_STATE_ACCOUNT.pnlSnapshots
				accountData.orderHistory = ZERO_STATE_ACCOUNT.orderHistory
				accountData.trades = ZERO_STATE_ACCOUNT.trades
				accountData.accountTransfers = ZERO_STATE_ACCOUNT.accountTransfers
				accountData.conditionalOrders = ZERO_STATE_ACCOUNT.conditionalOrders
				accountData.tradesByPosition = ZERO_STATE_ACCOUNT.tradesByPosition
				accountData.positionHistory = ZERO_STATE_ACCOUNT.positionHistory
			})
		})
		return state
	},
	(state, _) => {
		return state
	},
	{ whitelist: ['accounts'] }
)

const providerDataTransform = createTransform(
	(inboundState: ProviderData) => {
		const state = cloneDeep(inboundState)
		state.globalTradeHistory = FUTURES_INITIAL_STATE.providerData.globalTradeHistory
		state.globalLiquidationHistory = FUTURES_INITIAL_STATE.providerData.globalLiquidationHistory
		state.historicalFundingRates = FUTURES_INITIAL_STATE.providerData.historicalFundingRates
		return state
	},
	(state, _) => {
		return state
	},
	{ whitelist: ['providerData'] }
)

const persistConfig = {
	key: 'futures',
	storage,
	version: Number(STORE_VERSION),
	transforms: [accountsTransform, providerDataTransform],
	blacklist: ['queryStatuses', 'leaderboard', 'tradePreviews', 'cancellingConditionalOrder'],
	migrate: createMigrate(
		{
			[STORE_VERSION]: () => {
				// Always reset futures state when there is any migration
				return { ...FUTURES_INITIAL_STATE } as any
			},
		},
		{ debug: true }
	),
}

export default persistReducer<FuturesState>(persistConfig, futuresSlice.reducer)

export const {
	setSelectedMarketAsset,
	setSelectedTrader,
	setLeaderboardPageSize,
	setSelectedPortfolioTimeframe,
	setTradePanelDrawerOpen,
	setDelegated,
	setSession,
	setIsSessionActive,
	setSelectedDelegationAddress,
	setSelectedSubAccountAddress,
	addDelegateToAddressBook,
	removeDelegateFromAddressBook,
	setSubAccountsForWallet,
	renameAddress,
	setNewNickname,
	setPreferences,
	setSLTPModalStopLossPrice,
	setSLTPModalTakeProfitPrice,
	setSLTPModalStopLossAmount,
	setSLTPModalTakeProfitAmount,
	clearSLTPModalInputs,
	setEditConditonalOrderModalPrice,
	setConditionalOrderPriceInvalidLabel,
	setEditConditonalOrderModalSize,
	setEditConditonalOrderModalMargin,
	setEditConditonalOrderInputs,
	setEditPositionInputs,
	clearEditPositionInputs,
	setProfitCalculatorVisible,
	toggleFavoriteMarket,
	setUserInfoTableFilter,
	setDashboardHistoryTableFilter,
	setLeverageSide,
	setLeverageInput,
	setSlippageInput,
	setTradePanelOrderType,
	setTradeInputs,
	setTradePanelOrderPrice,
	setOrderPriceInvalidLabel,
	setTradeStopLoss,
	setTradeTakeProfit,
	setMarginDelta,
	setClosePositionOrderType,
	setClosePositionSizeDelta,
	setClosePositionPrice,
	setMarkets,
	setVolumes,
	setFundingRatesHistory,
	setQueryStatus,
	setGlobalTradeHistory,
	setTraderHistory,
	setAccount,
	updateAccountData,
	setTradePreview,
	setTotalTradeFees,
	clearTradePreview,
	setCancellingConditionalOrder,
	handlePreviewError,
	setPerpsV3Spenders,
	incrementPreviewCount,
	setGlobalLiquidationHistory,
	setSnxV3MaxDepositAmounts,
	setSupportedCollaterals,
	setSnxV3DebtPaymentQuote,
	setAuthToken,
	clearAuthToken,
	clearSavedAddresses,
	setSelectedTab,
} = futuresSlice.actions
