import type { BuildUserOpOptions, UserOpResponse } from '@biconomy/account'
import type { SimulatedRequest } from '@kwenta/sdk/types'
import type { BaseStorage } from 'services/storage/types'
import type { Address, Hash, Hex, PublicClient, WalletClient } from 'viem'

import type { UserOperationStruct } from '@aa-sdk/core'
import type { SendUserOpParams } from '@biconomy/modules'
import type { DateInterval } from 'utils/dates'
import Notifier from 'utils/notifier'

export enum AbstractionToken {
	ETH = 'ETH',
	USDC = 'USDC',
}

// TODO: Move to common
type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
	{
		[K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
	}[Keys]

type ValueOrData = RequireAtLeastOne<
	{
		value: bigint
		data: Hex
	},
	'value' | 'data'
>

export type Transaction = ValueOrData & {
	to: Address
}

export type SendUserOperationsRequest = {
	readyTxs?: Transaction[]
	simulateTxs?: SimulatedRequest[]
	userOp?: Partial<UserOperationStruct>
	options?: BuildUserOpOptions
}

export type SessionInfo = {
	validUntil: number
	validAfter: number
}

export type SendUserOpParameters = {
	userOp: Partial<UserOperationStruct>
	options?: SendUserOpParams
}

export abstract class AbstractAccountAbstraction extends Notifier<AbstractAccountAbstraction> {
	public abstract accountAddress?: Address
	protected abstract _chainId?: number

	protected storage: BaseStorage

	constructor(storage: BaseStorage) {
		super()
		this.storage = storage
	}

	public abstract get chainId(): number | undefined

	protected abstract set chainId(chainId: number | undefined)

	public abstract init(client: WalletClient, publicClient: PublicClient): Promise<void>

	public abstract disconnect(): void

	public abstract createSession(
		interval: DateInterval | number,
		address: Address,
		withApprove?: boolean
	): Promise<any>

	public abstract getSessionInfo(): Promise<SessionInfo | undefined>

	public abstract closeAllSessions(): Promise<any>

	public abstract sendTransactions(
		params: SendUserOperationsRequest
	): Promise<UserOpResponse | Hash>

	public abstract prepareUserOp(params: SendUserOperationsRequest): Promise<SendUserOpParameters>

	public abstract sendUserOperation(params: SendUserOpParameters): Promise<UserOpResponse | Hash>
}
