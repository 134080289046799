import common from './common'

const newTheme = {
	containers: {
		primary: {
			background: common.palette.neutral.n1000,
			overlay: {
				background: common.palette.alpha.gray60,
			},
		},
		secondary: {
			background: common.palette.neutral.n1100,
		},
		cards: {
			background: common.palette.neutral.n1100,
		},
		blue: {
			background: common.palette.blue.b600,
		},
		dark: {
			background: common.palette.neutral.n1000,
		},
		highlight: {
			background: common.palette.navy.n1000,
		},
	},
	border: {
		color: common.palette.neutral.n700,
		yellow: common.palette.yellow.y900,
		style: `1px solid ${common.palette.neutral.n700}`,
		primaryStyle: `1px solid ${common.palette.neutral.n0}`,
		long: `2px solid ${common.palette.green.g500}`,
		short: `2px solid ${common.palette.red.r300}`,
		avata: `1px solid ${common.palette.neutral.n100}`,
		green: `1px solid ${common.palette.green.g400}`,
		cyan: `1px solid ${common.palette.cyan.c500}`,
	},
	dropdown: {
		hover: {
			background: common.palette.neutral.n900,
		},
		icon: {
			fill: common.palette.neutral.n300,
		},
	},
	button: {
		default: {
			border: `1px solid ${common.palette.neutral.n700}`,
			borderColor: common.palette.neutral.n700,
			background: common.palette.neutral.n800,
			color: common.palette.neutral.n0,
			hover: {
				background: common.palette.neutral.n700,
			},
		},
		tab: {
			active: common.palette.neutral.n0,
			inactive: common.palette.neutral.n500,
		},
		position: {
			long: {
				active: {
					background: common.palette.green.g500,
					border: common.palette.green.g600,
					color: common.palette.neutral.n900,
				},
				hover: {
					background: common.palette.green.g600,
					border: common.palette.green.g700,
					color: common.palette.neutral.n900,
				},
			},
			short: {
				active: {
					background: common.palette.red.r300,
					border: common.palette.red.r300,
					color: common.palette.neutral.n900,
				},
				hover: {
					background: common.palette.red.r400,
					border: common.palette.red.r400,
					color: common.palette.neutral.n900,
				},
			},
		},
		cell: {
			background: 'transparent',
			hover: {
				background: common.palette.neutral.n800,
			},
		},
		fund: {
			labelColor: common.palette.neutral.n100,
			activeLabelColor: common.palette.neutral.n0,
			valueColor: common.palette.neutral.n100,
			activeValueColor: common.palette.neutral.n100,
			activeBackground: common.palette.yellow.y1000,
			hoverBackground: common.palette.yellow.y1000,
			activeBorder: common.palette.yellow.y500,
		},
		leverage: {
			disabled: common.palette.neutral.n70,
			active: common.palette.neutral.n0,
			border: `1px solid ${common.palette.neutral.n700}`,
			background: {
				active: common.palette.neutral.n800,
				inactive: common.palette.neutral.n1000,
			},
			account: {
				createOrConnect: {
					background: common.palette.yellow.y500,
					text: common.black,
				},
			},
		},
		slippage: {
			disabled: common.palette.neutral.n70,
			active: common.palette.neutral.n0,
			border: `1px solid ${common.palette.neutral.n700}`,
			background: {
				active: common.palette.neutral.n800,
				inactive: common.palette.neutral.n1000,
			},
		},
		solid: {
			background: common.palette.yellow.y500,
			text: common.black,
		},
		switcher: {
			inactive: {
				text: common.palette.neutral.n90,
			},
			arbitrum: {
				background: '#0a2448',
			},
			optimism: {
				background: '#4e131a',
			},
			base: {
				background: '#193165',
			},
			active: {
				border: common.black,
			},
		},
	},
	text: {
		primary: common.palette.neutral.n0,
		secondary: common.palette.neutral.n70,
		tertiary: common.palette.neutral.n100,
		disabled: common.palette.neutral.n200,
		positive: common.palette.green.g500,
		negative: common.palette.red.r300,
		preview: common.palette.yellow.y500,
		warning: common.palette.yellow.y500,
		sectionHeader: common.palette.yellow.y500,
		green: common.palette.green.g400,
	},
	banner: {
		announce: {
			text: common.palette.yellow.y500,
			background: common.palette.yellow.y1000,
		},
		default: {
			text: common.palette.neutral.n50,
		},
	},
	badge: {
		yellow: {
			text: common.palette.neutral.n900,
			background: common.palette.yellow.y500,
			dark: {
				background: common.palette.yellow.y1000,
				text: common.palette.yellow.y500,
				border: common.palette.alpha.white10,
			},
		},
		primary: {
			text: common.palette.neutral.n900,
			background: common.palette.yellow.y500,
			dark: {
				background: common.palette.yellow.y1000,
				text: common.palette.neutral.n0,
				border: common.palette.alpha.white10,
			},
		},
		gray: {
			text: common.palette.neutral.n900,
			background: common.palette.neutral.n50,
			dark: {
				background: common.palette.neutral.n100,
				text: common.palette.neutral.n900,
				border: common.palette.alpha.white10,
			},
		},
		red: {
			text: common.palette.neutral.n900,
			background: common.palette.red.r300,
			dark: {
				background: common.palette.alpha.red10,
				text: common.palette.red.r300,
				border: common.palette.alpha.white10,
			},
		},
		green: {
			text: common.black,
			background: common.palette.green.g500,
			dark: {
				text: common.black,
				background: common.palette.green.g500,
				border: common.palette.green.g500,
			},
		},
	},
	pill: {
		yellow: {
			text: common.palette.yellow.y500,
			background: common.palette.yellow.y1000,
			border: common.palette.alpha.white10,
			outline: {
				background: 'transparent',
				text: common.palette.yellow.y500,
				border: common.palette.yellow.y500,
			},
			hover: {
				background: common.palette.yellow.y500,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n900,
			},
		},
		yellowGray: {
			text: common.palette.yellow.y500,
			background: common.palette.neutral.n800,
			border: common.palette.neutral.n600,
			outline: {
				text: common.palette.neutral.n900,
				background: common.palette.neutral.n20,
				border: common.palette.alpha.white10,
			},
			hover: {
				background: common.palette.neutral.n700,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n30,
			},
		},
		cyanGray: {
			text: common.palette.cyan.c500,
			background: common.palette.neutral.n800,
			border: common.palette.neutral.n600,
			outline: {
				text: common.palette.neutral.n900,
				background: common.palette.neutral.n20,
				border: common.palette.alpha.white10,
			},
			hover: {
				background: common.palette.neutral.n700,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n30,
			},
		},
		gray: {
			text: common.palette.neutral.n70,
			background: common.palette.neutral.n800,
			border: common.palette.neutral.n600,
			outline: {
				text: common.palette.neutral.n900,
				background: common.palette.neutral.n20,
				border: common.palette.alpha.white10,
			},
			hover: {
				background: common.palette.neutral.n700,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n30,
			},
		},
		default: {
			text: common.palette.neutral.n70,
			background: common.palette.neutral.n800,
			border: common.palette.neutral.n600,
			outline: {
				text: common.palette.neutral.n900,
				background: common.palette.neutral.n20,
				border: common.palette.alpha.white10,
			},
			hover: {
				background: common.palette.neutral.n700,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n30,
			},
		},
		red: {
			text: common.palette.red.r300,
			background: common.palette.alpha.red10,
			border: common.palette.red.r300,
			outline: {
				background: 'transparent',
				text: common.palette.red.r300,
				border: common.palette.red.r300,
			},
			hover: {
				background: common.palette.red.r500,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n900,
			},
		},
		redGray: {
			text: common.palette.red.r300,
			background: common.palette.neutral.n800,
			border: common.palette.neutral.n600,
			outline: {
				background: common.palette.neutral.n20,
				text: common.palette.red.r300,
				border: common.palette.alpha.white10,
			},
			hover: {
				background: common.palette.neutral.n700,
				border: common.palette.alpha.white10,
				text: common.palette.neutral.n30,
			},
		},
		lightGreen: {
			text: common.palette.neutral.n1000,
			background: common.palette.green.g500,
			border: common.palette.alpha.white10,
			outline: {
				background: common.palette.neutral.n20,
				text: common.palette.red.r300,
				border: common.palette.neutral.n20,
			},
			hover: {
				text: common.palette.neutral.n1000,
				background: common.palette.green.g500,
				border: common.palette.alpha.white10,
			},
		},
		green: {
			text: common.palette.green.g500,
			background: common.palette.alpha.green10,
			border: common.palette.alpha.white10,
			outline: {
				background: common.palette.neutral.n20,
				text: common.palette.red.r300,
				border: common.palette.neutral.n20,
			},
			hover: {
				text: common.palette.neutral.n1000,
				background: common.palette.green.g500,
				border: common.palette.alpha.white10,
			},
		},
	},
	checkBox: {
		default: {
			text: common.palette.neutral.n70,
			border: common.palette.neutral.n500,
			background: common.palette.neutral.n700,
			checked: common.palette.yellow.y500,
		},
		highlight: {
			text: common.palette.yellow.y500,
			border: common.palette.yellow.y500,
			background: common.palette.neutral.n700,
			checked: common.palette.yellow.y500,
		},
		simple: {
			text: common.palette.neutral.n70,
			background: common.palette.neutral.n40,
			checked: '#000000',
			label: common.palette.neutral.n70,
		},
	},
	tabs: {
		position: {
			background: common.palette.neutral.n1100,
			inactive: {
				background: common.palette.neutral.n900,
				color: common.palette.neutral.n70,
			},
			long: {
				color: common.palette.green.g500,
			},
			short: {
				color: common.palette.red.r300,
			},
		},
	},
	pencilIcon: {
		color: common.palette.yellow.y500,
		hover: {
			color: common.palette.yellow.y900,
		},
	},
	fundingChart: {
		tooltip: {
			background: common.palette.neutral.n700,
			border: common.palette.neutral.n600,
		},
	},
	exchange: {
		card: common.palette.neutral.n900,
		ratioSelect: {
			background: common.palette.neutral.n700,
		},
	},
	riskStatus: {
		low: common.palette.green.g600,
		moderate: common.palette.yellow.y500,
		high: common.palette.red.r300,
	},
	disclaimer: {
		background: common.palette.yellow.y1000,
		color: common.palette.yellow.y500,
	},
	calendar: {
		selectedDay: {
			background: common.palette.yellow.y500,
			color: common.black,
		},
		dayHover: common.palette.yellow.y1000,
		indicator: common.palette.neutral.n0,
		month: common.palette.neutral.n0,
		outsideMonth: common.palette.neutral.n100,
		resetButton: {
			background: common.palette.neutral.n800,
			color: common.palette.neutral.n50,
		},
	},
	trade: {
		oneClickTradingMessageBg: common.palette.yellow.y1000,
		setup: common.palette.yellow.y500,
	},
	dashboard: {
		menu: {
			active: common.palette.neutral.n900,
			indicator: common.palette.yellow.y500,
		},
	},
	tooltip: {
		border: `1px solid ${common.palette.neutral.n700}`,
		background: common.palette.neutral.n1100,
	},
	progressBar: {
		color: common.palette.yellow.y500,
		circleColor: common.palette.yellow.y500,
		background: common.palette.neutral.n800,
	},
}

const darkTheme = {
	...common.dark,
	red: common.dark.red,
	green: common.dark.green,
	black: common.dark.black,
	white: common.dark.white,
	yellow: common.primaryYellow,
	cyan: common.palette.cyan.c500,
	table: { fill: 'rgba(255, 255, 255, 0.01)', hover: 'rgba(255, 255, 255, 0.05)' },
	gold: '#E4B378',
	badge: {
		red: { background: common.dark.red, text: 'black' },
		yellow: { background: common.primaryYellow, text: 'black' },
		gray: { background: common.primaryGray, text: 'black' },
	},
	tab: { background: { active: '#252525', inactive: 'transparent' } },
	button: {
		border: 'rgb(255 255 255 / 10%)',
		fill: '#252525',
		fillHover: '#2B2A2A',
		background: 'linear-gradient(180deg, #282727 0%, #191818 100%)',
		hover: 'linear-gradient(180deg, #383838 0%, #383838 0.01%, #1E1E1E 100%)',
		shadow:
			'rgb(0 0 0 / 25%) 0px 2px 2px, rgb(255 255 255 / 10%) 0px 1px 0px inset, rgb(255 255 255 / 3%) 0px 0px 20px inset',
		text: {
			primary: common.palette.neutral.n0,
			yellow: common.primaryYellow,
		},
		primary: {
			background: 'linear-gradient(180deg, #BE9461 0%, #9C6C3C 100%)',
			hover: 'linear-gradient(180deg, #E4B378 0%, #B98C55 100%)',
			textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
		},
		secondary: { text: common.palette.neutral.n50 },
		danger: { text: common.dark.red },
		active: {
			shadow: 'inset 0px 0px 20px rgba(255, 255, 255, 0.03)',
			textShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)',
			hover: {
				successBackground:
					'linear-gradient(180deg, rgba(127, 212, 130, 0.2) 0%, rgba(71, 122, 73, 0.2) 100%)',
				dangerBackground:
					'linear-gradient(180deg, rgba(239, 104, 104, 0.2) 0%, rgba(116, 56, 56, 0.2) 100%)',
				successBorder: 'rgba(127, 212, 130, 0.2)',
				dangerBorder: 'rgba(239, 104, 104, 0.2)',
			},
		},
		disabled: { text: common.palette.neutral.n70, background: 'transparent' },
		tab: {
			disabled: { border: '1px solid #353333', text: '#353333' },
		},
		pill: { background: common.dark.yellow, text: common.dark.yellow, hover: common.black },
		yellow: {
			fill: '#3E2D00',
			fillHover: '#513C05',
			border: '#514219',
			text: common.dark.yellow,
		},
		highlight: {
			fill: '#3E2D00',
			fillHover: '#513C05',
			border: '#514219',
			text: common.dark.yellow,
		},
		yellowSolid: {
			fill: common.palette.yellow.y500,
			fillHover: common.palette.yellow.y500,
			border: common.palette.yellow.y500,
			text: common.black,
		},
		red: {
			fill: common.palette.alpha.red15,
			text: common.palette.red.r200,
		},
	},
	input: {
		background: common.palette.neutral.n1100,
		border: `1px solid ${common.palette.neutral.n700}`,
		borderColor: common.palette.neutral.n700,
		secondary: {
			background: '#0b0b0b',
		},
		placeholder: common.palette.neutral.n70,
		shadow: '0px 0.5px 0px rgba(255, 255, 255, 0.08)',
		hover: common.dark.white,
		denominationToggle: {
			background: common.palette.neutral.n700,
		},
		label: {
			primary: common.palette.neutral.n0,
			secondary: common.palette.neutral.n70,
			tertiary: common.palette.neutral.n100,
		},
	},
	segmentedControl: {
		background: common.palette.neutral.n1100,
		button: {
			border: `1px solid ${common.palette.neutral.n700}`,
			background: common.palette.neutral.n800,
			inactive: common.palette.neutral.n70,
			active: common.palette.neutral.n0,
		},
		pill: {
			border: `1px solid ${common.palette.neutral.n700}`,
			selected: common.palette.neutral.n900,
		},
	},
	slider: {
		label: '#787878',
		thumb: {
			disabledBorder: `3px solid ${common.palette.gray.g900}`,
			border: `3px solid ${common.palette.neutral.n40}`,
			background: common.palette.neutral.n700,
		},
		rail: {
			background: '#414141',
		},
		track: {
			background: common.palette.neutral.n0,
		},
		tooltip: {
			background: common.palette.gray.g800,
		},
	},
	select: {
		control: {
			shadow:
				'0px 2px 2px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.08), inset 0px 0px 20px rgba(255, 255, 255, 0.03)',
		},
	},
	cell: {
		fill: '#1E1D1D;',
		gradient: 'linear-gradient(180deg, #1E1D1D 0%, #1b1a1a 100%)',
		hover: '#222222',
		outline: '#2B2A2A',
	},
	text: {
		header: '#B1B1B1',
		value: common.palette.neutral.n0,
		label: common.neautralGray,
		body: common.dark.gray,
	},
	icon: {
		fill: '#787878',
		hover: '#ECE8E3',
		hoverReverse: common.dark.black,
	},
	openInterestBar: {
		border: '1px solid #2b2a2a',
	},
	modal: {
		background: '#252525',
	},
	competitionBanner: {
		border: '1px solid #2b2a2a',
		state: {
			text: common.primaryWhite,
		},
		bg: '#fff',
	},
	chartLine: {
		long: common.palette.green.g700,
		short: common.palette.red.r600,
		default: '#4094E8',
	},
	socket: {
		accent: '#252525',
	},
	landing: {
		border: '1px solid rgb(43, 42, 40, 0.6)',
	},
	watcherMode: {
		active: common.palette.yellow.y500,
		inactive: common.palette.neutral.n0,
		disabled: '#555555',
	},

	newTheme,
	imageInvert: { value: '0' },
}

export default darkTheme
