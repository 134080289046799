import { SL_TP_MAX_SIZE, SL_TP_MAX_SIZE_CROSS_MARGIN, ZERO_WEI } from '@kwenta/sdk/constants'
import {
	type CommonFuturesPosition,
	type ConditionalOrderV3Request,
	type DelayedOrder,
	FuturesMarginType,
	type FuturesMarket,
	type FuturesMarketAsset,
	type FuturesPositionDetails,
	type FuturesTrade,
	type FuturesVolumes,
	type MultiCallTransactionRequest,
	OrderTypeEnum,
	type PerpsMarketV2,
	type PerpsMarketV3,
	PerpsProvider,
	type PerpsV2Position,
	type PerpsV3AsyncOrder,
	type PerpsV3Liquidation,
	type PerpsV3Position,
	type PerpsV3SettlementStrategy,
	PositionSide,
	PotentialTradeStatus,
	type PricesMap,
	type TotalPnl,
	type UnrealizedPnl,
} from '@kwenta/sdk/types'
import { AssetDisplayByAsset, formatNumber, getMarketName, notNill } from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'
import type { TFunction } from 'i18next'
import type {
	ConditionOrderTableItem,
	ConditionOrderTableItemCross,
	ConditionOrderTableItemIsolated,
	FuturesPosition,
} from 'types/futures'

import type { TableFilter } from 'sections/futures/UserInfo/TableFilters/TableFilters'
import type { TransactionOrder } from 'state/app/types'
import type {
	DelayedOrderWithDetails,
	MarkPrices,
	TradePanelInputs,
} from 'state/futures/common/types'
import type {
	IsolatedMarginBalanceInfo,
	IsolatedMarginTradePreview,
} from 'state/futures/isolatedMargin/types'
import type {
	DashboardHistoryTableFilter,
	SerializedPosition,
	UnserializedPosition,
} from 'state/futures/types'

import type {
	CrossMarginProvider,
	MarginInfo,
	SnxPerpsV3TradePreview,
} from '../state/futures/snxPerpsV3/types'

export const getSynthDescription = (synth: FuturesMarketAsset, t: TFunction) => {
	const assetDisplayName = AssetDisplayByAsset[synth]
	return t('common.currency.futures-market-short-name', {
		currencyName: assetDisplayName,
	})
}

export const orderPriceInvalidLabel = (
	orderPrice: string,
	leverageSide: PositionSide,
	currentPrice: string,
	orderType: OrderTypeEnum
): string | undefined => {
	if (!orderPrice || Number(orderPrice) <= 0) return
	const isLong = leverageSide === 'long'

	const isLimit = orderType === OrderTypeEnum.LIMIT || orderType === OrderTypeEnum.TAKE_PROFIT

	if (((isLong && isLimit) || (!isLong && !isLimit)) && wei(orderPrice).gt(currentPrice)) {
		return `max ${formatNumber(currentPrice, { suggestDecimals: true })}`
	}
	if (((!isLong && isLimit) || (isLong && !isLimit)) && wei(orderPrice).lt(currentPrice))
		return `min ${formatNumber(currentPrice, { suggestDecimals: true })}`
	return
}

const serializeMarket = (market: FuturesMarket): FuturesMarket<string> => {
	return {
		...market,
		openInterest: {
			...market.openInterest,
			shortUSD: market.openInterest.shortUSD.toString(),
			longUSD: market.openInterest.longUSD.toString(),
			long: market.openInterest.long.toString(),
			short: market.openInterest.short.toString(),
		},
		marketDebt: market.marketDebt.toString(),
		marketSkew: market.marketSkew.toString(),
		appMaxLeverage: market.appMaxLeverage.toString(),
		minInitialMargin: market.minInitialMargin.toString(),
		settlementFee: market.settlementFee.toString(),
		marketLimitUsd: {
			long: market.marketLimitUsd.long.toString(),
			short: market.marketLimitUsd.short.toString(),
		},
		marketLimitNative: {
			long: market.marketLimitNative.long.toString(),
			short: market.marketLimitNative.short.toString(),
		},
		settings: {
			skewScale: market.settings?.skewScale?.toString(),
		},
	}
}

const serializeV2Market = (m: PerpsMarketV2): PerpsMarketV2<string> => {
	return {
		...serializeMarket(m),
		marginType: FuturesMarginType.ISOLATED_MARGIN,
		currentFundingRate: m.currentFundingRate.toString(),
		provider: PerpsProvider.SNX_V2_OP,
		marketAddress: m.marketAddress,
		contractMaxLeverage: m.contractMaxLeverage.toString(),
		currentFundingVelocity: m.currentFundingVelocity.toString(),
		feeRates: {
			makerFee: m.feeRates.makerFee.toString(),
			takerFee: m.feeRates.takerFee.toString(),
		},
		settings: {
			...m.settings,
			skewScale: m.settings.skewScale.toString(),
		},
	}
}

export const serializeV3Market = (m: PerpsMarketV3): PerpsMarketV3<string> => {
	return {
		...serializeMarket(m),
		marketId: m.marketId,
		marginType: FuturesMarginType.CROSS_MARGIN,
		provider: m.provider,
		settlementStrategies: m.settlementStrategies.map(serializeSettlementStrategy),
		currentFundingRate: m.currentFundingRate.toString(),
		feeRates: {
			makerFee: m.feeRates.makerFee.toString(),
			takerFee: m.feeRates.takerFee.toString(),
		},
		settings: {
			skewScale: m.settings.skewScale.toString(),
			maxLiquidationLimitAccumulationMultiplier:
				m.settings.maxLiquidationLimitAccumulationMultiplier.toString(),
			maxSecondsInLiquidationWindow: m.settings.maxSecondsInLiquidationWindow.toString(),
			minimumInitialMarginRatio: m.settings.minimumInitialMarginRatio.toString(),
			flagRewardRatio: m.settings.flagRewardRatio.toString(),
			maintenanceMarginScalar: m.settings.maintenanceMarginScalar.toString(),
			minimumPositionMargin: m.settings.minimumPositionMargin.toString(),
			initialMarginRatio: m.settings.initialMarginRatio.toString(),
			interestRate: m.settings.interestRate.toString(),
		},
	}
}

export const serializeV2Markets = (markets: PerpsMarketV2[]): PerpsMarketV2<string>[] => {
	return markets.map((m) => serializeV2Market(m))
}

const serializeSettlementStrategy = (
	strat: PerpsV3SettlementStrategy
): PerpsV3SettlementStrategy<string> => {
	return {
		...strat,
		settlementDelay: strat.settlementDelay.toString(),
		settlementWindowDuration: strat.settlementWindowDuration.toString(),
		settlementReward: strat.settlementReward.toString(),
	}
}

export const serializeTransactionRequest = (
	transaction: MultiCallTransactionRequest
): MultiCallTransactionRequest<string> => {
	return {
		...transaction,
		value: transaction.value.toString(),
		gas: transaction.gas ? transaction.gas.toString() : undefined,
	}
}

export const unserializeTransactionRequest = (
	transaction: MultiCallTransactionRequest<string>
): MultiCallTransactionRequest => {
	return {
		...transaction,
		value: BigInt(transaction.value),
		gas: transaction.gas ? BigInt(transaction.gas) : undefined,
	}
}

export const serializeIsolatedMarginBalanceInfo = (
	overview: IsolatedMarginBalanceInfo
): IsolatedMarginBalanceInfo<string> => {
	return {
		freeMargin: overview.freeMargin.toString(),
		keeperEthBal: overview.keeperEthBal.toString(),
		walletEthBal: overview.walletEthBal.toString(),
		allowance: overview.allowance.toString(),
		factoryApproved: overview.factoryApproved,
		idleMarginByMarket: serializeIdleMargin(overview.idleMarginByMarket),
		totalMarginByMarket: serializeIdleMargin(overview.totalMarginByMarket ?? {}),
		balance: overview.balance.toString(),
	}
}

export const serializeFuturesVolumes = (volumes: FuturesVolumes) => {
	return Object.keys(volumes).reduce<FuturesVolumes<string>>((acc, k) => {
		acc[k] = {
			trades: volumes[k].trades.toString(),
			volume: volumes[k].volume.toString(),
		}
		return acc
	}, {})
}

export const unserializeTradeInputs = (tradeInputs: TradePanelInputs<string>): TradePanelInputs => {
	return {
		orderPrice: {
			price: wei(tradeInputs.orderPrice.price || 0),
			invalidLabel: tradeInputs.orderPrice.invalidLabel,
		},
		marginDelta: wei(tradeInputs.marginDelta || 0),
		orderType: tradeInputs.orderType,
		nativeSize: wei(tradeInputs.nativeSize || 0),
		susdSize: wei(tradeInputs.susdSize || 0),
		stopLossPrice: tradeInputs.stopLossPrice ? wei(tradeInputs.stopLossPrice || 0) : undefined,
		takeProfitPrice: tradeInputs.takeProfitPrice
			? wei(tradeInputs.takeProfitPrice || 0)
			: undefined,
	}
}

export const unserializeConditionOrderTableItem = (
	item: ConditionOrderTableItem<string>
): ConditionOrderTableItem => {
	return {
		...item,
		currentPrice: {
			...item.currentPrice,
			price: wei(item.currentPrice.price),
		},
		size: wei(item.size),
		targetPrice: wei(item.targetPrice),
		desiredFillPrice: wei(item.desiredFillPrice),
		marginDelta: wei(item.marginDelta),
		maxExecutorFee: wei(item.maxExecutorFee),
	}
}

export const unserializeConditionOrderTableItemCross = (
	item: ConditionOrderTableItemCross<string>
): ConditionOrderTableItemCross => {
	return {
		...unserializeConditionOrderTableItem(item),
		provider: PerpsProvider.SNX_V3_BASE,
	}
}

export const unserializeConditionOrderTableItemIsolated = (
	item: ConditionOrderTableItemIsolated<string>
): ConditionOrderTableItemIsolated => {
	return {
		...unserializeConditionOrderTableItem(item),
		provider: item.provider,
		marketAddress: item.marketAddress,
	}
}

export const serializeV3AsyncOrder = (order: PerpsV3AsyncOrder): PerpsV3AsyncOrder<string> => ({
	...order,
	sizeDelta: order.sizeDelta.toString(),
	acceptablePrice: order.sizeDelta.toString(),
})

export const serializePrices = (prices: PricesMap) => {
	return Object.entries(prices).reduce<PricesMap<string>>((acc, [key, price]) => {
		acc[key as FuturesMarketAsset] = price.toString()
		return acc
	}, {})
}

const serializePositionDetails = (d: FuturesPositionDetails): FuturesPositionDetails<string> => {
	return {
		...d,
		size: d.size.toString(),
		stats: {
			...d.stats,
			totalVolume: d.stats.totalVolume.toString(),
			totalDeposits: d.stats.totalDeposits?.toString(),
			netTransfers: d.stats.netTransfers?.toString(),
		},
		margin: {
			...d.margin,
			remainingMargin: d.margin.remainingMargin.toString(),
			accessibleMargin: d.margin.accessibleMargin.toString(),
			initialMargin: d.margin.initialMargin.toString(),
			marginRatio: d.margin.marginRatio.toString(),
			initialLeverage: d.margin.initialLeverage.toString(),
			leverage: d.margin.leverage.toString(),
			maxLeverage: d.margin.maxLeverage ? d.margin.maxLeverage.toString() : undefined,
			notionalValue: d.margin.notionalValue.toString(),
		},
		price: {
			...d.price,
			entryPrice: d.price.entryPrice.toString(),
			avgEntryPrice: d.price.avgEntryPrice ? d.price.avgEntryPrice.toString() : undefined,
			exitPrice: d.price.exitPrice ? d.price.exitPrice.toString() : null,
			lastPrice: d.price.lastPrice ? d.price.lastPrice.toString() : undefined,
			liquidationPrice: d.price.liquidationPrice ? d.price.liquidationPrice.toString() : undefined,
		},
		pnl: {
			totalPnl: {
				pnl: d.pnl.totalPnl.pnl.toString(),
				netPnl: d.pnl.totalPnl.netPnl.toString(),
				netPnlPct: d.pnl.totalPnl.netPnlPct ? d.pnl.totalPnl.netPnlPct.toString() : '0',
			},
			rPnl: {
				pnl: d.pnl.rPnl.pnl.toString(),
				netPnl: d.pnl.rPnl.netPnl.toString(),
				netPnlPct: d.pnl.rPnl.netPnlPct.toString(),
			},
			uPnl: d.pnl.uPnl
				? {
						pnl: d.pnl.uPnl.pnl.toString(),
						pnlPct: d.pnl.uPnl.pnlPct.toString(),
					}
				: undefined,
		},
		fees: {
			owedInterest: d.fees.owedInterest.toString(),
			feesPaid: d.fees.feesPaid.toString(),
			netFunding: d.fees.netFunding.toString(),
			accruedFunding: d.fees.accruedFunding.toString(),
			keeperFeesPaid: d.fees.keeperFeesPaid?.toString(),
			liquidationFee: d.fees.liquidationFee?.toString(),
		},
	}
}

export const unserializePositionDetails = (
	d: FuturesPositionDetails<string>
): FuturesPositionDetails => {
	return {
		...d,
		size: wei(d.size),
		stats: {
			...d.stats,
			totalVolume: wei(d.stats.totalVolume),
			totalDeposits: wei(d.stats.totalDeposits ?? 0),
			netTransfers: wei(d.stats.netTransfers ?? 0),
		},
		margin: {
			...d.margin,
			remainingMargin: wei(d.margin.remainingMargin),
			accessibleMargin: wei(d.margin.accessibleMargin),
			initialMargin: wei(d.margin.initialMargin),
			marginRatio: wei(d.margin.marginRatio),
			initialLeverage: wei(d.margin.initialLeverage),
			leverage: wei(d.margin.leverage),
			maxLeverage: d.margin.maxLeverage ? wei(d.margin.maxLeverage) : undefined,
			notionalValue: wei(d.margin.notionalValue),
		},
		price: {
			...d.price,
			entryPrice: wei(d.price.entryPrice),
			avgEntryPrice: d.price.avgEntryPrice ? wei(d.price.avgEntryPrice) : undefined,
			exitPrice: d.price.exitPrice ? wei(d.price.exitPrice) : null,
			lastPrice: d.price.lastPrice ? wei(d.price.lastPrice) : undefined,
			liquidationPrice: d.price.liquidationPrice ? wei(d.price.liquidationPrice) : undefined,
		},
		pnl: {
			totalPnl: {
				pnl: wei(d.pnl.totalPnl.pnl),
				netPnl: wei(d.pnl.totalPnl.netPnl),
				netPnlPct: d.pnl.totalPnl.netPnlPct ? wei(d.pnl.totalPnl.netPnlPct) : wei(0),
			},
			rPnl: {
				pnl: wei(d.pnl.rPnl.pnl),
				netPnl: wei(d.pnl.rPnl.netPnl),
				netPnlPct: wei(d.pnl.rPnl.netPnlPct),
			},
			uPnl: d.pnl.uPnl
				? {
						pnl: wei(d.pnl.uPnl.pnl),
						pnlPct: wei(d.pnl.uPnl.pnlPct),
					}
				: undefined,
		},
		fees: {
			owedInterest: wei(d.fees.owedInterest),
			feesPaid: wei(d.fees.feesPaid),
			netFunding: wei(d.fees.netFunding),
			accruedFunding: wei(d.fees.accruedFunding),
			keeperFeesPaid: d.fees.keeperFeesPaid ? wei(d.fees.keeperFeesPaid) : undefined,
			liquidationFee: d.fees.liquidationFee ? wei(d.fees.liquidationFee) : undefined,
		},
	}
}

export const serializePosition = <T extends PerpsV2Position<Wei> | PerpsV3Position<Wei>>(
	p: T
): SerializedPosition<T> => {
	const { details, ...rest } = p

	return {
		...(rest as CommonFuturesPosition),
		details: serializePositionDetails(details),
	} as SerializedPosition<T>
}

const unserializePosition = <T extends PerpsV2Position<string> | PerpsV3Position<string>>(
	p: T
): UnserializedPosition<T> => {
	const { details, ...rest } = p

	return {
		...(rest as CommonFuturesPosition),
		details: unserializePositionDetails(details),
	} as UnserializedPosition<T>
}

export const serializeV3Liquidations = (
	liquidations: PerpsV3Liquidation[]
): PerpsV3Liquidation<string>[] => {
	return liquidations.map((t) => ({
		...t,
		amount: t.amount.toString(),
		notionalAmount: t.notionalAmount.toString(),
		estimatedPrice: t.estimatedPrice.toString(),
		liquidationPnl: t.liquidationPnl.toString(),
	}))
}

export const serializeTrades = (trades: FuturesTrade[]): FuturesTrade<string>[] => {
	return trades.map((t) => ({
		...t,
		margin: t.margin?.toString(),
		sizeDelta: t.sizeDelta.toString(),
		fillPrice: t.fillPrice.toString(),
		pnl: t.pnl.toString(),
		totalFees: t.totalFees.toString(),
		fundingAccrued: t.fundingAccrued.toString(),
		pnlWithFeesPaid: t.pnlWithFeesPaid.toString(),
		interestCharged: t.interestCharged ? t.interestCharged.toString() : undefined,
	}))
}

export const formatDelayedOrders = (
	orders: DelayedOrder<string>[],
	markets: PerpsMarketV2<string>[]
) => {
	return orders
		.filter((o) => wei(o.size).abs().gt(0))
		.reduce((acc, o) => {
			const market = markets.find((m) => m.marketAddress === o.marketAddress)
			if (!market) return acc

			acc.push({
				...o,
				asset: market.asset,
				market: getMarketName(market.asset),
				executableAtTimestamp:
					market && o.isOffchain // Manual fix for an incorrect
						? o.submittedAtTimestamp +
							(o.isOffchain
								? market.settings.offchainDelayedOrderMinAge * 1000
								: market.settings.minDelayTimeDelta * 1000)
						: o.executableAtTimestamp,
			})
			return acc
		}, [] as DelayedOrderWithDetails<string>[])
}

const serializeIdleMargin = (idleMargin: Partial<Record<FuturesMarketAsset, Wei>>) => {
	return Object.keys(idleMargin).reduce<Partial<Record<FuturesMarketAsset, string>>>(
		(acc, key) => {
			acc[key as FuturesMarketAsset] = idleMargin[key as FuturesMarketAsset]!.toString()
			return acc
		},
		{} as Partial<Record<FuturesMarketAsset, string>>
	)
}

// Disable stop loss when it is within 0% of the liquidation price
const SL_LIQ_DISABLED_PERCENT = 0

// Warn users when their stop loss is within 7.5% of their liquidation price
const SL_LIQ_PERCENT_WARN = 0.075

const furthestSLPrice = (liqPrice: Wei | undefined, leverageSide: PositionSide) => {
	// liquidation price can be zero when using 1x leverage or less
	if (liqPrice?.eq(0)) return leverageSide === PositionSide.LONG ? wei(0) : wei('10000000000000')
	return leverageSide === PositionSide.LONG
		? liqPrice?.mul(1 + SL_LIQ_DISABLED_PERCENT)
		: liqPrice?.mul(1 - SL_LIQ_DISABLED_PERCENT)
}

export const takeProfitValidity = (
	takeProfitPrice: string,
	side: PositionSide,
	offchainPrice: Wei,
	onChainPrice?: Wei
) => {
	const closestPrice =
		side === 'long'
			? onChainPrice?.gt(offchainPrice)
				? onChainPrice
				: offchainPrice
			: onChainPrice?.lt(offchainPrice)
				? onChainPrice
				: offchainPrice

	const invalid =
		side === 'long'
			? takeProfitPrice !== '' && wei(takeProfitPrice || 0).lt(closestPrice)
			: takeProfitPrice !== '' && wei(takeProfitPrice || 0).gt(closestPrice)

	const minMaxLabel = side === 'long' ? 'Min: ' : 'Max: '

	return {
		invalidLabel: invalid
			? minMaxLabel + formatNumber(closestPrice, { suggestDecimals: true })
			: undefined,
		closestPrice: closestPrice.toString(),
	}
}

export const stopLossValidity = (
	stopLossPrice: string,
	liqPrice: Wei | undefined,
	side: PositionSide,
	offchainPrice: Wei,
	onChainPrice?: Wei
) => {
	const furthestPrice = furthestSLPrice(liqPrice, side)

	const chainPrice = onChainPrice?.gt(0) ? onChainPrice : undefined

	const closestPrice =
		side === 'long'
			? chainPrice?.lt(offchainPrice)
				? chainPrice
				: offchainPrice
			: chainPrice?.gt(offchainPrice)
				? chainPrice
				: offchainPrice

	if (stopLossPrice === '' || stopLossPrice === undefined)
		return {
			invalidLabel: undefined,
			minMaxStopPrice: furthestPrice?.toString(),
		}

	if (!furthestPrice || !liqPrice)
		return {
			furthestPrice: furthestPrice?.toString(),
			closestPrice: closestPrice.toString(),
			invalidLabel: 'No position data',
		}

	const formattedClosest = formatNumber(closestPrice, { suggestDecimals: true })
	const formattedFurthest = formatNumber(furthestPrice, { suggestDecimals: true })

	let invalidLabel: string | undefined = undefined
	if (side === 'long') {
		if (wei(stopLossPrice || 0).lt(furthestPrice || 0)) {
			invalidLabel = `Min: ${formattedFurthest}`
		}
		if (wei(stopLossPrice || 0).gt(closestPrice)) {
			invalidLabel = `Max: ${formattedClosest}`
		}
	} else {
		if (wei(stopLossPrice || 0).gt(furthestPrice || 0)) {
			invalidLabel = `Max: ${formattedFurthest}`
		}
		if (wei(stopLossPrice || 0).lt(closestPrice)) {
			invalidLabel = `Min: ${formattedClosest}`
		}
	}

	const percent = wei(stopLossPrice || 0)
		.div(liqPrice.gt(0) ? liqPrice : 1)
		.sub(1)
		.abs()

	return {
		invalidLabel,
		furthestPrice: furthestPrice.toString(),
		closestPrice: closestPrice.toString(),
		showWarning: percent.lt(SL_LIQ_PERCENT_WARN),
	}
}

export const previewStatusToI18nMsg = (status: PotentialTradeStatus) => {
	switch (status) {
		case PotentialTradeStatus.INSUFFICIENT_FREE_MARGIN:
			return 'futures.market.trade.preview.min-margin-required'
		case PotentialTradeStatus.MAX_POSITION_SIZE_EXCEEDED:
			return 'futures.market.trade.preview.max-size-exceeded'
		default:
			return 'futures.market.trade.preview.error'
	}
}

export const formatV3AssetKey = (asset: string) => asset.replace('SNX_', 's')

export const orderConditionFromTargetPrice = (
	targetPrice: Wei,
	currentPrice: Wei
): 'isPriceAbove' | 'isPriceBelow' => {
	return targetPrice.lt(currentPrice) ? 'isPriceBelow' : 'isPriceAbove'
}

export const serializeIsolatedMarginTradePreview = (
	preview: IsolatedMarginTradePreview
): IsolatedMarginTradePreview<string> => ({
	...preview,
	newSize: preview.newSize.toString(),
	sizeDelta: preview.sizeDelta.toString(),
	liqPrice: preview.liqPrice.toString(),
	margin: preview.margin.toString(),
	fillPrice: preview.fillPrice.toString(),
	fee: preview.fee.toString(),
	settlementFee: preview.settlementFee.toString(),
	notionalValue: preview.notionalValue.toString(),
	priceImpact: preview.priceImpact.toString(),
	tradeFee: preview.tradeFee.toString(),
	maxLeverage: preview.maxLeverage?.toString(),
	desiredFillPrice: preview.desiredFillPrice.toString(),
	orderPrice: preview.orderPrice.toString(),
	marginDelta: preview.marginDelta?.toString(),
})

export const calculateV3LiqPrice = (
	accountMargin: MarginInfo<string>,
	{ newSize, fillPrice, sizeDelta, requiredMargin }: SnxPerpsV3TradePreview<string>,
	market: PerpsMarketV3<string>
) => {
	const newSizeWei = wei(newSize)
	const marginDelta = wei(requiredMargin).sub(wei(accountMargin.requiredInitialMargin))

	const deltaMaintenanceMargin = marginDelta.mul(wei(market.settings.maintenanceMarginScalar))
	const newMaintenanceMargin = wei(accountMargin.requiredMaintenanceMargin).add(
		deltaMaintenanceMargin
	)

	const minPosMargin = wei(newSizeWei).sub(sizeDelta).eq(0)
		? wei(market.settings.minimumPositionMargin)
		: wei(0)
	const maintenanceMargin = newMaintenanceMargin.add(minPosMargin)

	const liqPrice =
		fillPrice && !newSizeWei.eq(0)
			? maintenanceMargin.sub(wei(accountMargin.availableMargin)).div(newSizeWei).add(fillPrice)
			: wei(0)
	return liqPrice.gt(0) ? liqPrice : wei(0)
}

export function shouldFilterUserInfoTable(date: Date, tableFilter: TableFilter | null) {
	if (!tableFilter) return true
	const { from: _from, to: _to, span } = tableFilter
	const now = new Date()
	const from = _from ? new Date(_from) : undefined
	const to = _to ? new Date(_to) : undefined

	let pastDate: Date | undefined

	switch (span) {
		case '1d':
			pastDate = subDays(now, 1)
			break
		case '1w':
			pastDate = subWeeks(now, 1)
			break
		case '1m':
			pastDate = subMonths(now, 1)
			break
		case '3m':
			pastDate = subMonths(now, 3)
			break
		default:
			break
	}

	if (from && to) {
		return date >= from && date <= to
	} else if (from && !to) {
		return date >= from
	}

	if (!pastDate) return true

	return date >= pastDate && date <= now
}

export function shouldFilterHistoryTableByDate(
	date: Date,
	tableFilter: DashboardHistoryTableFilter | null
) {
	if (!tableFilter) return true
	const { from: _from, to: _to } = tableFilter
	const from = _from ? new Date(_from) : undefined
	const to = _to ? new Date(_to) : undefined

	if (from && to) {
		return date >= from && date <= to
	} else if (from && !to) {
		return date >= from
	}

	return true
}

export function shouldFilterHistoryTable(
	item: {
		asset?: string
		type?: string
		status?: string
		side?: string
		committedTxHash?: string
		settlementTxHash?: string
	},
	tableFilter: DashboardHistoryTableFilter | null
) {
	if (!tableFilter) return true
	const { asset, type, status, side, search } = tableFilter

	if (
		(asset && asset !== item.asset && asset !== 'all') ||
		(type && type !== item.type && type !== 'all') ||
		(status && status !== item.status && status !== 'all') ||
		(side && side !== item.side && side !== 'all') ||
		(search && !item.committedTxHash?.includes(search) && !item.settlementTxHash?.includes(search))
	) {
		return false
	}

	return true
}

export function createFilterOptions(
	items: string[],
	labelModifier?: (x?: string | null) => string | null
) {
	return Array.from(new Set(items)).map((item) => ({
		value: item,
		label: labelModifier ? labelModifier(item) || item : item,
	}))
}

export const capitalize = (s?: string | null) => (s ? s.charAt(0).toUpperCase() + s.slice(1) : '')

export const unserializeTransactionOrder = (
	preview: TransactionOrder<string>
): TransactionOrder => ({
	...preview,
	newSize: wei(preview.newSize),
	sizeDelta: wei(preview.sizeDelta),
	price: wei(preview.price),
})

export const providerIsCrossMargin = (provider?: PerpsProvider): provider is CrossMarginProvider =>
	provider === PerpsProvider.SNX_V3_BASE

export const isolatedMarginProvider = (
	provider: PerpsProvider
): PerpsProvider.SNX_V2_OP | undefined =>
	provider === PerpsProvider.SNX_V2_OP ? provider : undefined

export const crossMarginProvider = (
	provider: PerpsProvider
): PerpsProvider.SNX_V3_BASE | undefined =>
	provider === PerpsProvider.SNX_V3_BASE ? provider : undefined

const updatePerpsPositionPnl = (
	position: PerpsV2Position<string> | PerpsV3Position<string>,
	prices: MarkPrices<string>
): { uPnl: UnrealizedPnl<string> | undefined; totalPnl: TotalPnl<string> } => {
	const deserializedPosition = unserializePosition(position)
	const offChainPrice = prices[deserializedPosition.asset]
	const { details: positionDetails } = deserializedPosition
	const entry = positionDetails?.price.avgEntryPrice

	const totalPnl: TotalPnl<string> = { ...position.details.pnl.totalPnl }

	if (
		!positionDetails ||
		!offChainPrice ||
		!entry ||
		positionDetails.status === 'closed' ||
		positionDetails.status === 'liquidated'
	) {
		return { uPnl: undefined, totalPnl }
	}

	const uPnl: UnrealizedPnl<string> = {
		pnl: '0',
		pnlPct: '0',
	}

	if (position.details.accountType === FuturesMarginType.ISOLATED_MARGIN) {
		const initialMargin = positionDetails.margin.initialMargin
		const pnl = positionDetails.size.mul(
			entry.sub(offChainPrice).mul(positionDetails.side === PositionSide.LONG ? -1 : 1)
		)

		totalPnl.pnl = positionDetails.pnl.rPnl.pnl.add(pnl).toString()
		totalPnl.netPnl = positionDetails.pnl.rPnl.netPnl.add(pnl).toString()

		const earnedCapital = positionDetails.margin.remainingMargin
			.add(
				positionDetails.pnl.totalPnl.netPnl.gt(0) ? positionDetails.pnl.totalPnl.netPnl : ZERO_WEI
			)
			.add(positionDetails.stats.totalDeposits ?? ZERO_WEI)
			.sub(positionDetails.stats.netTransfers ?? ZERO_WEI)

		const riskedCapital = positionDetails.margin.initialMargin.add(
			positionDetails.stats.totalDeposits ?? ZERO_WEI
		)

		totalPnl.netPnlPct = earnedCapital.sub(riskedCapital).div(riskedCapital).toString()
		uPnl.pnl = pnl.toString()
		uPnl.pnlPct = initialMargin.eq(ZERO_WEI) ? '0' : wei(uPnl.pnl).div(initialMargin).toString()
	} else {
		const priceDiff = (positionDetails.price.avgEntryPrice ?? ZERO_WEI).sub(offChainPrice)
		uPnl.pnl = positionDetails.size
			.mul(priceDiff)
			.abs()
			.mul(
				(positionDetails.side === PositionSide.LONG && priceDiff.lt(0)) ||
					(positionDetails.side === PositionSide.SHORT && priceDiff.gt(0))
					? 1
					: -1
			)
			.toString()

		uPnl.pnlPct = '0'

		totalPnl.pnl = positionDetails.pnl.rPnl.pnl.add(uPnl.pnl).toString()
		totalPnl.netPnl = positionDetails.pnl.rPnl.pnl
			.add(uPnl.pnl)
			.add(positionDetails.fees.accruedFunding)
			.sub(positionDetails.fees.feesPaid)
			.toString()
		totalPnl.netPnlPct = '0'
	}

	return { uPnl, totalPnl }
}

const formatFuturesPosition = (
	position: PerpsV2Position<string> | PerpsV3Position<string>,
	markets: (PerpsMarketV3<string> | PerpsMarketV2<string>)[],
	orders: ConditionOrderTableItem<string>[],
	markPrices: MarkPrices<string>
): FuturesPosition<string> | undefined => {
	const market = markets.find((m) => m.asset === position.asset)
	if (!market) return

	const SL_TP_SIZE = providerIsCrossMargin(market.provider)
		? SL_TP_MAX_SIZE_CROSS_MARGIN
		: SL_TP_MAX_SIZE

	const stopLoss = orders.find((o) => {
		return (
			o.asset === market.asset &&
			wei(o.size).abs().eq(SL_TP_SIZE) &&
			o.reduceOnly &&
			o.orderType === OrderTypeEnum.STOP_LOSS
		)
	})
	const takeProfit = orders.find(
		(o) =>
			o.asset === market.asset &&
			wei(o.size).abs().eq(SL_TP_SIZE) &&
			o.reduceOnly &&
			o.orderType === OrderTypeEnum.TAKE_PROFIT
	)

	const { uPnl, totalPnl } = markPrices
		? updatePerpsPositionPnl(position, markPrices)
		: { uPnl: position.details.pnl.uPnl, totalPnl: position.details.pnl.totalPnl }

	return {
		...position,
		market,
		details: {
			...position.details,
			size: wei(position.details.size).abs().toString(),
			stopLoss: stopLoss,
			takeProfit: takeProfit,
			pnl: {
				...position.details.pnl,
				uPnl,
				totalPnl,
			},
		},
	}
}

export const formatFuturesPositions = (
	positions: PerpsV2Position<string>[] | PerpsV3Position<string>[],
	markets: (PerpsMarketV3<string> | PerpsMarketV2<string>)[],
	orders: ConditionOrderTableItem<string>[],
	markPrices: MarkPrices<string>
): FuturesPosition<string>[] => {
	const formatted = positions
		.map((p) => formatFuturesPosition(p, markets, orders, markPrices))
		.filter(notNill)
	return formatted.sort((a, b) => b.details.openTimestamp - a.details.openTimestamp)
}

export const formatOrderForSigning = (order: ConditionalOrderV3Request<bigint>) => ({
	orderDetails: {
		marketId: BigInt(order.orderDetails.marketId),
		accountId: BigInt(order.orderDetails.accountId),
		sizeDelta: order.orderDetails.sizeDelta,
		settlementStrategyId: BigInt(order.orderDetails.settlementStrategyId),
		acceptablePrice: order.orderDetails.acceptablePrice,
		isReduceOnly: order.orderDetails.isReduceOnly,
		trackingCode: order.orderDetails.trackingCode,
		referrer: order.orderDetails.referrer,
	},
	signer: order.signer,
	nonce: BigInt(order.nonce),
	requireVerified: order.requireVerified,
	trustedExecutor: order.trustedExecutor,
	maxExecutorFee: order.maxExecutorFee,
	conditions: order.conditions,
})
