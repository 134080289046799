import axios from 'axios'
import type { Hex } from 'viem'
import { createSiweMessage } from 'viem/siwe'

export const SIGN_IN_STATEMENT = 'Please sign this message to authenticate your account'

export const createMessage = (address: string, statement: string): string => {
	if (!process.env.NEXT_PUBLIC_NONCE) {
		throw new Error('NEXT_PUBLIC_NONCE is not set')
	}
	return createSiweMessage({
		domain: window.location.host,
		address: address as Hex,
		statement,
		uri: window.location.origin,
		version: '1',
		chainId: 1,
		nonce: process.env.NEXT_PUBLIC_NONCE,
	})
}

export const loginWithSiwe = async (
	message: string,
	signature: string
): Promise<{ token: string }> => {
	if (!process.env.NEXT_PUBLIC_KWENTA_SERVER) {
		throw new Error('NEXT_PUBLIC_KWENTA_SERVER is not set')
	}
	const response = await axios.post(
		`${process.env.NEXT_PUBLIC_KWENTA_SERVER}/auth/token`,
		{ message, signature },
		{
			headers: {
				'Content-Type': 'application/json',
			},
		}
	)
	if (response.status !== 200) throw new Error('Authentication failed')
	return response.data
}

export const fetchSavedAddresses = async (authToken: string): Promise<string[]> => {
	const response = await axios.get(`${process.env.NEXT_PUBLIC_KWENTA_SERVER}/profile/contacts`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${authToken}`,
		},
	})
	if (response.status !== 200) throw new Error('Failed to fetch saved addresses')
	return response.data
}

export const addSavedAddress = async (authToken: string, address: string): Promise<string> => {
	const response = await axios.post(
		`${process.env.NEXT_PUBLIC_KWENTA_SERVER}/profile/contacts`,
		{ contactAddress: address },
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`,
			},
		}
	)
	if (response.status !== 200) throw new Error('Failed to add saved address')
	return address
}

export const removeSavedAddress = async (authToken: string, address: string): Promise<string> => {
	const response = await axios.delete(
		`${process.env.NEXT_PUBLIC_KWENTA_SERVER}/profile/contacts/${address}`,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken}`,
			},
		}
	)
	if (response.status !== 200) throw new Error('Failed to remove saved address')
	return address
}
