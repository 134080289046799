import type { SmartAccountSigner, SmartContractAccount, UserOperationStruct_v7 } from '@aa-sdk/core'
import { type AlchemySmartAccountClient, setChain } from '@account-kit/core'
import { simulatedRequestToTxRequest } from '@kwenta/sdk/utils'
import { alchemyConfig } from 'containers/Connector/alchemyConfig'
import type { SendUserOperationsRequest } from 'types/accountAbstraction'
import logError from 'utils/logError'
import { type Chain, isAddress, isHex } from 'viem'

export class AlchemySmartAccountService {
	private client: AlchemySmartAccountClient | null = null
	private signer: SmartAccountSigner | null = null

	public setClient(client: AlchemySmartAccountClient) {
		this.client = client
	}

	public getClient() {
		return this.client
	}

	public setSigner(signer: SmartAccountSigner) {
		this.signer = signer
	}

	public getSigner() {
		return this.signer
	}

	// create public methods to send user operation
	async submitTransaction(params: SendUserOperationsRequest, chain?: Chain) {
		if (!this.client) {
			throw new Error('Smart account client not initialized')
		}

		const { readyTxs, simulateTxs, userOp } = params

		if (!(readyTxs?.length || simulateTxs?.length || userOp)) {
			throw new Error('No transactions to send')
		}

		if (userOp) {
			const request = await this.client.signUserOperation({
				account: this.client.account as SmartContractAccount,
				uoStruct: userOp as UserOperationStruct_v7,
			})
			if (this.client.account) {
				const entryPointAddress = this.client.account.getEntryPoint().address
				const hash = await this.client.sendRawUserOperation(request, entryPointAddress)
				return await this.client.waitForUserOperationTransaction({ hash })
			} else {
				throw new Error('Smart account client not initialized')
			}
		}

		const formattedTxs = readyTxs ?? []

		if (simulateTxs) {
			formattedTxs.push(...simulateTxs.map(simulatedRequestToTxRequest))
		}

		const mappedTxs = formattedTxs.map((tx) => {
			if (!isAddress(tx.to) || !isHex(tx.data)) {
				throw new Error('Invalid transaction')
			}

			return {
				value: BigInt(tx.value || 0),
				target: tx.to,
				data: tx.data,
			}
		})

		if (chain) {
			await setChain(alchemyConfig, chain)
		}

		const { hash, request } = await this.client.sendUserOperation({
			account: this.client.account as SmartContractAccount,
			uo: mappedTxs,
		})
		try {
			return await this.client.waitForUserOperationTransaction({ hash })
		} catch (err) {
			logError(err)
			const { hash: newHash } = await this.client.dropAndReplaceUserOperation({
				account: this.client.account as SmartContractAccount,
				uoToDrop: request,
			})

			return await this.client.waitForUserOperationTransaction({ hash: newHash })
		}
	}
}

export const smartAccount = new AlchemySmartAccountService()
