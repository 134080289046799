import {
	DEFAULT_PRICE_IMPACT_DELTA_PERCENT,
	DepositableAssetKeysV3,
	type DepositableV3Assets,
	DepositableV3AssetsBase,
	PERIOD_IN_SECONDS,
	SL_TP_MAX_SIZE_CROSS_MARGIN,
	SNX_V3_PERPS_ADDRESSES,
	TOKEN_ADDRESSES,
	ZERO_WEI,
} from '@kwenta/sdk/constants'
import {
	type ConditionalOrderInput,
	ConditionalOrderStatusV3,
	FuturesMarginType,
	type MultiCallTransactionRequest,
	type NetworkId,
	OrderTypeEnum,
	Period,
	PerpsProvider,
	PositionSide,
	RawCondition,
	type SnxV3NetworkIds,
	type SynthAssetKeysV3,
	TransactionStatus,
	type WriteContractParameters,
} from '@kwenta/sdk/types'
import {
	DepositTokenToSynth,
	calculateDesiredFillPrice,
	depositableAssetToToken,
	floorNumber,
	getDefaultPriceImpact,
	isZero,
	parseUsdcValue,
	tokenDecimals,
} from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { ConditionOrderTableItem } from 'types/futures'
import { formatUnits, parseUnits } from 'viem'

import { notifyError } from 'components/ErrorNotifier'
import { monitorAndAwaitTransaction, monitorFollowingTransaction } from 'state/app/helpers'
import {
	handleTransactionError,
	setOpenModal,
	setShowConditionalOrderModal,
	setShowEditPositionModal,
	setTransaction,
	updateTransactionStatus,
	updateTransactionStep,
} from 'state/app/reducer'
import { fetchBalancesAndAllowances } from 'state/balances/actions'
import { selectTokenAllowances } from 'state/balances/selectors'
import { KEEPER_USD_GAS_FEE } from 'state/constants'
import {
	selectAccountContext,
	selectActivePositions,
	selectClosePositionPreview,
	selectEditPositionModalInfo,
	selectEditPositionPreview,
} from 'state/futures/selectors'
import { handleFetchError } from 'state/helpers'
import { selectOffchainPricesInfo, selectPrices } from 'state/prices/selectors'
import type { AppThunk } from 'state/store'
import { FetchStatus, type ThunkConfig } from 'state/types'
import { selectAbstractionWalletEnabled, selectWallet } from 'state/wallet/selectors'
import { currentTimestamp } from 'utils/dates'
import {
	orderConditionFromTargetPrice,
	orderPriceInvalidLabel,
	providerIsCrossMargin,
	serializeTrades,
	serializeTransactionRequest,
	serializeV3Liquidations,
} from 'utils/futures'
import logError from 'utils/logError'

import type { TransactionOrder } from 'state/app/types'

import { DEFAULT_REFETCH_INTERVAL } from 'constants/futures'
import type { SendUserOpParameters, Transaction } from 'types/accountAbstraction'
import { refetchWithComparator } from 'utils/queries'
import {
	fetchAccountData,
	fetchOpenConditionalOrders,
	fetchPendingMarketOrders,
	fetchPerpsAccounts,
	stageTradePreview,
} from '../actions'
import { submitFuturesTransaction, submitTransaction } from '../common/actions'
import {
	selectClosePositionOrderInputs,
	selectEditCOModalInputs,
	selectEditPositionInputs,
	selectLeverageSide,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectPerpsProvider,
	selectSlTpModalInputs,
	selectSnxV3Network,
	selectSnxV3Provider,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectV3Markets,
} from '../common/selectors'
import type { SnxPerpsV3TradePreviewParams } from '../common/types'
import {
	clearEditPositionInputs,
	clearTradePreview,
	handlePreviewError,
	setAccount,
	setClosePositionPrice,
	setClosePositionSizeDelta,
	setConditionalOrderPriceInvalidLabel,
	setEditConditonalOrderModalPrice,
	setEditConditonalOrderModalSize,
	setEditPositionInputs,
	setGlobalLiquidationHistory,
	setGlobalTradeHistory,
	setLeverageInput,
	setOrderPriceInvalidLabel,
	setQueryStatus,
	setSlippageInput,
	setSnxV3DebtPaymentQuote,
	setSnxV3MaxDepositAmounts,
	setSupportedCollaterals,
	setTradeInputs,
	setTradePanelOrderPrice,
	setTradePreview,
	setTradeStopLoss,
	setTradeTakeProfit,
	updateAccountData,
} from '../reducer'
import {
	selectAllSnxV3SLTPOrders,
	selectCrossMarginConditionalOrders,
	selectCrossMarginMarginInfo,
	selectCrossMarginTradePreview,
	selectCrossMarginTradeableMargin,
	selectDebtPaymentQuote,
	selectDepositAllowances,
	selectMarginEnginePermitted,
	selectMargineEngineEnabled,
	selectMarkPricesV3,
	selectSnxV3Account,
	selectSnxV3AccountContext,
	selectGlobalLiquidationsForMarket as selectSnxV3GlobalLiquidationsForMarket,
	selectSnxV3GlobalTradesForMarket,
	selectV3SelectedMarketId,
	selectV3SelectedMarketInfo,
	selectV3SkewAdjustedPrice,
	selectV3SynthPrices,
} from './selectors'
import type { SnxPerpsV3TradePreview, SnxV3AccountData } from './types'

const fetchKeeperBalance = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchKeeperBalance',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { accountId, network, wallet, provider } = selectSnxV3AccountContext(getState())
		const marginEngineEnabled = selectMargineEngineEnabled(getState())

		if (!accountId || !wallet || !marginEngineEnabled) return
		try {
			dispatch(
				setQueryStatus({
					key: 'get_keeper_balance',
					status: FetchStatus.Loading,
				})
			)
			const balance =
				provider === PerpsProvider.SNX_V3_BASE
					? await sdk.snxPerpsV3.getAccountUsdcCredit(BigInt(accountId), network)
					: wei(0)
			dispatch(
				updateAccountData({
					wallet,
					data: {
						network,
						account: accountId.toString(),
						usdcBalance: balance.toString(),
						provider: provider,
					},
				})
			)
			dispatch(
				setQueryStatus({
					key: 'get_keeper_balance',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_keeper_balance', err)
		}
	}
)

export const fetchPerpsV3Balances = createAsyncThunk<void, void, ThunkConfig>(
	'balances/fetchPerpsV3Balances',
	async (_, { dispatch }) => {
		dispatch(fetchBalancesAndAllowances())
		dispatch(fetchMaxDepositAmounts())
		dispatch(fetchKeeperBalance())
	}
)

export const fetchSupportedCollaterals = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchSupportedCollaterals',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		try {
			const network = selectSnxV3Network(getState())
			const provider = selectSnxV3Provider(getState())
			dispatch(
				setQueryStatus({
					key: 'get_supported_collaterals',
					status: FetchStatus.Loading,
				})
			)
			const collaterals = await sdk.snxPerpsV3.getSupportedCollaterals(network)
			dispatch(setSupportedCollaterals({ provider: provider, collaterals }))
			dispatch(
				setQueryStatus({
					key: 'get_supported_collaterals',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_supported_collaterals', err)
		}
	}
)

export const fetchCrossMarginAccountLiquidations = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchCrossMarginAccountLiquidations',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		try {
			const { wallet, network, accountId, provider } = selectSnxV3AccountContext(getState())
			if (!wallet || !accountId) return
			dispatch(
				setQueryStatus({
					key: 'get_account_liquidations',
					status: FetchStatus.Loading,
				})
			)
			const liquidations = await sdk.snxPerpsV3.getAllAccountLiquidations(
				BigInt(accountId),
				network
			)
			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: provider,
						account: accountId.toString(),
						network,
						liquidations: serializeV3Liquidations(liquidations),
					},
				})
			)
			dispatch(
				setQueryStatus({
					key: 'get_account_liquidations',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_account_liquidations', err)
		}
	}
)

export const refetchCrossMarginAccountLiquidations = createAsyncThunk<void, void, ThunkConfig>(
	'futures/refetchCrossMarginAccountLiquidations',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { wallet, accountId, provider, network } = selectSnxV3AccountContext(getState())
		if (!wallet || !accountId) return
		if (!providerIsCrossMargin(provider))
			throw new Error('Should be used only for cross margin (SNX V3)')

		const { liquidations: existingLiquidations } = selectAccountContext(
			getState()
		) as unknown as SnxV3AccountData

		try {
			const { data: liquidations, status } = await refetchWithComparator(
				() => sdk.snxPerpsV3.getAllAccountLiquidations(BigInt(accountId), network),
				// @ts-expect-error Serialized value, but we compare by strings anyway
				existingLiquidations,
				(existing, next) => existing?.at(0)?.txHash === next.at(0)?.txHash,
				DEFAULT_REFETCH_INTERVAL
			)

			if (status === 'timeout') {
				throw new Error('Timeout fetching account liquidations')
			}

			if (liquidations?.length === 0 && existingLiquidations.length === 0) {
				return
			}

			if (!liquidations) {
				throw new Error('No liquidations found')
			}

			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: provider,
						account: accountId.toString(),
						network,
						liquidations: serializeV3Liquidations(liquidations),
					},
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_account_liquidations', err, { provider, notify: false })
		}
	}
)

export const fetchPerpsV3GlobalLiquidations = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchPerpsV3GlobalLiquidations',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		try {
			const { wallet, accountId, provider } = selectSnxV3AccountContext(getState())
			const marketId = selectV3SelectedMarketId(getState())
			const chain = selectSnxV3Network(getState())
			if (!marketId || !wallet || !accountId) return
			const maxTimestamp = currentTimestamp()
			const minTimestamp = 0
			dispatch(
				setQueryStatus({
					key: 'get_global_liquidations',
					status: FetchStatus.Loading,
				})
			)
			const liquidations = await sdk.snxPerpsV3.getAllLiquidationsByMarket(marketId, chain, {
				minTimestamp,
				maxTimestamp,
			})
			dispatch(
				setGlobalLiquidationHistory({
					marketId,
					liqHistory: serializeV3Liquidations(liquidations),
					provider: provider,
				})
			)
			dispatch(
				setQueryStatus({
					key: 'get_global_liquidations',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_global_liquidations', err)
		}
	}
)
export const refetchPerpsV3GlobalLiquidations = createAsyncThunk<void, void, ThunkConfig>(
	'futures/refetchPerpsV3GlobalLiquidations',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const provider = selectPerpsProvider(getState())
		const marketId = selectV3SelectedMarketId(getState())
		const { network } = selectAccountContext(getState())
		if (!providerIsCrossMargin(provider))
			throw new Error('Should be used only for cross margin (SNX V3)')

		if (!marketId || !network) return

		try {
			const existingLiquidations = selectSnxV3GlobalLiquidationsForMarket(getState())

			const maxTimestamp = currentTimestamp()
			const minTimestamp = 0

			const { data: liquidations, status } = await refetchWithComparator(
				() =>
					sdk.snxPerpsV3.getAllLiquidationsByMarket(marketId, network as SnxV3NetworkIds, {
						minTimestamp,
						maxTimestamp,
					}),
				// @ts-expect-error Serialized value, but we compare by strings anyway
				existingLiquidations,
				(existing, next) => existing.at(0)?.txHash === next.at(0)?.txHash,
				DEFAULT_REFETCH_INTERVAL
			)

			if (status === 'timeout') {
				throw new Error('Timeout fetching global liquidations')
			}

			if (liquidations?.length === 0 && existingLiquidations.length === 0) {
				return
			}

			if (!liquidations) {
				throw new Error('No liquidations found')
			}

			dispatch(
				setGlobalLiquidationHistory({
					marketId,
					liqHistory: serializeV3Liquidations(liquidations),
					provider,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_global_liquidations', err, { provider })
		}
	}
)

type CollateralBalancesMap = Partial<Record<SynthAssetKeysV3, string>>

export const fetchAccountCollateralBalances = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchAccountCollateralBalances',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { wallet, network, accountId, provider } = selectSnxV3AccountContext(getState())

		if (!wallet || !accountId) return
		try {
			dispatch(
				setQueryStatus({
					key: 'get_collateral_balances',
					status: FetchStatus.Loading,
				})
			)
			const balances = await sdk.snxPerpsV3.getCollateralBalances(BigInt(accountId), network)
			const balancesMap = balances.reduce<CollateralBalancesMap>((acc, cur) => {
				acc[cur.synthId] = cur.amount.toString()
				return acc
			}, {})
			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: provider,
						account: accountId.toString(),
						network,
						collateralBalances: balancesMap,
					},
				})
			)
			dispatch(
				setQueryStatus({
					key: 'get_collateral_balances',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_collateral_balances', err)
		}
	}
)

const fetchSnxV3AccountData = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchSnxV3AccountData',
	async (_, { dispatch, getState }) => {
		const { provider } = selectSnxV3AccountContext(getState())
		dispatch(fetchAccountData([provider]))
	}
)

export const removeStaleOrders = createAsyncThunk<void, void, ThunkConfig>(
	'futures/removeStaleOrders',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { accountId, network, provider } = selectSnxV3AccountContext(getState())
		if (accountId) {
			await sdk.snxPerpsV3.removeStaleConditionalOrders(BigInt(accountId), network)
			dispatch(fetchOpenConditionalOrders([provider]))
		}
	}
)

export const fetchMarginSnapshotsV3 = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchMarginSnapshotsV3',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const {
			network: networkId,
			accountId,
			wallet,
			provider,
		} = selectSnxV3AccountContext(getState())
		if (!wallet || !accountId) return

		try {
			dispatch(
				setQueryStatus({
					key: 'get_margin_snapshots',
					status: FetchStatus.Loading,
				})
			)
			const yearSnapshots = await sdk.snxPerpsV3.getMarginSnapshots(BigInt(accountId), networkId, {
				resolution: '1h',
				fromTimestamp: Date.now() / 1000 - PERIOD_IN_SECONDS.ONE_YEAR,
				toTimestamp: Date.now() / 1000,
			})

			const monthSnapshots = await sdk.snxPerpsV3.getMarginSnapshots(BigInt(accountId), networkId, {
				resolution: '4h',
				fromTimestamp: Date.now() / 1000 - PERIOD_IN_SECONDS.ONE_MONTH,
				toTimestamp: Date.now() / 1000,
			})

			const weekSnapshots = await sdk.snxPerpsV3.getMarginSnapshots(BigInt(accountId), networkId, {
				resolution: '1h',
				fromTimestamp: Date.now() / 1000 - PERIOD_IN_SECONDS.ONE_WEEK,
				toTimestamp: Date.now() / 1000,
			})

			dispatch(
				updateAccountData({
					wallet,
					data: {
						network: networkId,
						provider: provider,
						account: accountId.toString(),
						marginSnapshots: {
							[Period.ONE_YEAR]: yearSnapshots.map((s) => ({
								...s,
								margin: s.margin.toString(),
							})),
							[Period.ONE_MONTH]: monthSnapshots.map((s) => ({
								...s,
								margin: s.margin.toString(),
							})),
							[Period.ONE_WEEK]: weekSnapshots.map((s) => ({
								...s,
								margin: s.margin.toString(),
							})),
						},
					},
				})
			)
			dispatch(
				setQueryStatus({
					key: 'get_margin_snapshots',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_margin_snapshots', err)
		}
	}
)

export const updateV3ConditionalOrder = createAsyncThunk<
	void,
	{
		order: ConditionOrderTableItem
		sizeDelta: Wei
		targetPrice: Wei
	},
	ThunkConfig
>(
	'futures/updateConditionalOrder',
	async (
		{ order, sizeDelta, targetPrice },
		{ getState, dispatch, extra: { sdk, smartAccount } }
	) => {
		const { network: networkId, accountId, provider } = selectSnxV3AccountContext(getState())
		const prices = selectMarkPricesV3(getState())
		const markets = selectV3Markets(getState())
		const abstractionWalletEnabled = selectAbstractionWalletEnabled(getState())
		const market = markets.find((m) => m.asset === order.asset)
		if (!market) throw new Error('No market found')
		const price = prices[order.asset]
		if (!price) throw new Error(`Missing price data for market ${order.asset}`)
		if (!accountId) return

		if (order.nonce === undefined) throw new Error('No nonce for existing order')

		const orderCondition = orderConditionFromTargetPrice(targetPrice, wei(price))
		try {
			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
				})
			)

			const acceptablePrice = calculateDesiredFillPrice(
				sizeDelta,
				targetPrice,
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.LIMIT)
			)

			await sdk.snxPerpsV3.updateConditionalOrders(
				[
					{
						orderId: order.id,
						nonce: order.nonce,
						inputs: {
							marketId: market.marketId,
							networkId: networkId,
							accountId: BigInt(accountId),
							sizeDelta: sizeDelta.toBigInt(),
							acceptablePrice: acceptablePrice.toBigInt(),
							isReduceOnly: order.reduceOnly,
							maxExecutorFee: order.maxExecutorFee.toBigInt(),
							rawConditions: {
								[orderCondition]: targetPrice.toBigInt(),
							},
						},
					},
				],
				networkId,
				abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
			)
			dispatch(fetchOpenConditionalOrders([provider]))
		} catch (err) {
			logError(err)
			notifyError('Failed to update order', err)
			throw err
		} finally {
			dispatch(setShowConditionalOrderModal(null))
			dispatch(setShowEditPositionModal(null))
		}
	}
)

export const clearCrossMarginTradeInputs = createAsyncThunk<void, void, ThunkConfig>(
	'futures/clearCrossMarginTradeInputs',
	async (_, { dispatch }) => {
		dispatch(setTradeInputs({ nativeSize: '', susdSize: '' }))
		dispatch(clearTradePreview())
		dispatch(clearEditPositionInputs())
		dispatch(setLeverageInput(''))
		dispatch(setSlippageInput(''))
		dispatch(setTradeStopLoss(''))
		dispatch(setTradeTakeProfit(''))
		dispatch(setClosePositionPrice({ value: '', invalidLabel: null }))
		dispatch(setClosePositionSizeDelta(''))
	}
)

export const editCrossMarginTradeSize =
	(size: string, currencyType: 'usd' | 'native'): AppThunk =>
	(dispatch, getState) => {
		const indexPrice = selectMarketIndexPrice(getState())
		const tradeSide = selectLeverageSide(getState())
		const marketInfo = selectV3SelectedMarketInfo(getState())
		const tradeableMargin = selectCrossMarginTradeableMargin(getState())
		const orderType = selectTradeOrderType(getState())
		const marketPrice = selectV3SkewAdjustedPrice(getState())
		const orderPrice = selectTradePanelOrderPriceInput(getState())
		const accountId = selectSnxV3Account(getState())

		if (!marketInfo) throw new Error('No market selected')

		if (size === '' || wei(indexPrice).eq(0)) {
			dispatch(setTradeInputs({ nativeSize: '', susdSize: '' }))
			dispatch(setTradePreview({ provider: marketInfo.provider, preview: undefined }))
			dispatch(setLeverageInput(''))
			return
		}

		const nativeSize =
			currencyType === 'native' ? size : String(floorNumber(wei(size).div(indexPrice), 4))
		const usdSize =
			currencyType === 'native' ? String(floorNumber(wei(indexPrice).mul(size), 4)) : size
		const leverage = tradeableMargin?.gt(0) ? wei(usdSize).div(tradeableMargin.abs()) : '0'
		const sizeDeltaWei =
			tradeSide === PositionSide.LONG ? wei(nativeSize || 0) : wei(nativeSize || 0).neg()

		dispatch(
			setTradeInputs({
				susdSize: usdSize,
				nativeSize: nativeSize,
			})
		)
		dispatch(setLeverageInput(leverage.toString(2)))
		dispatch(
			stageTradePreview({
				provider: marketInfo.provider,
				accountId: accountId?.toString(),
				market: marketInfo,
				orderPrice: wei(orderPrice || marketPrice),
				indexPrice: wei(indexPrice),
				sizeDelta: sizeDeltaWei,
				action: 'trade',
				marketPrice: wei(marketPrice),
				isConditional: orderType !== OrderTypeEnum.MARKET,
			})
		)
	}

export const editCrossMarginCloseAmount =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setClosePositionSizeDelta(nativeSizeDelta))
		const provider = selectSnxV3Provider(getState())
		const { price, isConditional } = selectClosePositionOrderInputs(getState())

		if (nativeSizeDelta === '' || !nativeSizeDelta) {
			dispatch(setTradePreview({ provider: provider, preview: undefined }))
			return
		}
		const { market, marketPrice, indexPrice, accountId } = selectEditPositionModalInfo(getState())

		if (
			market?.marginType !== FuturesMarginType.CROSS_MARGIN ||
			!indexPrice ||
			!accountId ||
			(!price?.value && isConditional)
		)
			return

		try {
			const previewParams: SnxPerpsV3TradePreviewParams = {
				provider: provider,
				accountId,
				market,
				orderPrice: isConditional && price?.value ? wei(price.value) : undefined,
				indexPrice: indexPrice,
				sizeDelta: wei(nativeSizeDelta),
				action: 'close',
				marketPrice: wei(marketPrice),
			}
			dispatch(stageTradePreview(previewParams))
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: provider,
				})
			)
		}
	}

// Contract Mutations

export const createPerpsV3Account = createAsyncThunk<
	{ account: string; wallet: string; network: NetworkId } | undefined,
	void,
	ThunkConfig
>(
	'futures/createPerpsV3Account',
	async (_, { getState, dispatch, extra: { sdk }, rejectWithValue }) => {
		const wallet = selectWallet(getState())
		const networkId = selectSnxV3Network(getState())
		const marginEngineEnabled = selectMargineEngineEnabled(getState())
		const provider = selectPerpsProvider(getState())

		if (!wallet) return undefined
		if (!providerIsCrossMargin(provider)) throw new Error('Invalid perps provider')
		const accounts = getState().futures.accounts[provider]

		// Already have an account fetched and persisted for this address
		if (accounts[wallet]?.network === networkId) {
			notifyError('There is already an account associated with this wallet')
			rejectWithValue('Account already created')
		}

		try {
			const accountIds = await sdk.snxPerpsV3.getAccounts(wallet, marginEngineEnabled, networkId)

			if (accountIds.length > 0) {
				// Already have an account, no need to create one
				const { accountId, marginEnginePermitted } = accountIds[0]
				dispatch(
					setAccount({
						provider: provider,
						account: accountId.toString(),
						marginEnginePermitted,
						wallet: wallet,
						networkId,
					})
				)
				return
			}

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'create_cross_margin_account',
					hash: null,
				})
			)
			const { request } = await sdk.snxPerpsV3.createAccount(networkId)

			await dispatch(submitTransaction({ request }))
			dispatch(fetchPerpsAccounts({ providers: [provider] }))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const grantMarginEnginePermission = createAsyncThunk<void, void, ThunkConfig>(
	'futures/grantMarginEnginePermission',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const { provider, network, accountId } = selectSnxV3AccountContext(getState())

		if (!accountId) throw new Error('No account id found')

		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'permit_margin_engine',
					hash: null,
				})
			)
			const request = await sdk.snxPerpsV3.grantMarginEnginePermission(BigInt(accountId), network)
			await dispatch(submitTransaction({ request }))
			dispatch(fetchPerpsAccounts({ providers: [provider], refetch: true }))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const depositWrappedTokenCrossMargin = createAsyncThunk<
	void,
	{ amount: Wei; token: DepositableV3Assets; closeOnComplete?: boolean },
	ThunkConfig
>(
	'futures/depositWrappedTokenCrossMargin',
	async ({ amount, token, closeOnComplete }, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network, provider } = selectSnxV3AccountContext(getState())
		const tokenAllowances = selectTokenAllowances(getState())
		const depositAllowances = selectDepositAllowances(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const marginEngineAddress = SNX_V3_PERPS_ADDRESSES.MarginEngine[network]

		const synth = DepositTokenToSynth[provider][token]

		try {
			if (!synth) throw new Error(`Synth not found for ${token}`)

			const depositToken = depositableAssetToToken(token)

			const tokenAllowance = tokenAllowances[depositToken]
			const firstSpender = marginEngineAddress

			let engineAllowance = depositAllowances[depositToken]?.marginEngine ?? wei(0)

			const decimals = tokenDecimals('USDC', network)

			engineAllowance = wei(parseUnits(engineAllowance.toString(), decimals))

			const firstAllowance = wei(formatUnits(engineAllowance.toBigInt(), decimals))
			if (!accountId) throw new Error('Account id not found')
			if (!tokenAllowance) throw new Error(`Token allowance not found for ${depositToken}`)
			if (!firstSpender) throw new Error('Unsupported network')

			if (!marginEnginePermitted) {
				await dispatch(grantMarginEnginePermission())
			}

			if (firstAllowance?.lt(amount) && token !== DepositableV3AssetsBase.ETH) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'approve_cross_margin',
						hash: null,
					})
				)
				const address = TOKEN_ADDRESSES[depositToken][network]

				if (!address) throw new Error(`Address not found for ${depositToken}`)
				const approveAmount = parseUnits(amount.toString(), tokenDecimals(token, network))
				const { request } = await sdk.tokens.approveTokenSpend({
					address: address,
					spender: firstSpender,
					chainId: network,
					amount: approveAmount,
				})

				await dispatch(submitTransaction({ request }))
			}

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_cross_margin',
					hash: null,
				})
			)

			const request =
				token === DepositableV3AssetsBase.USDC && provider === PerpsProvider.SNX_V3_BASE
					? await sdk.snxPerpsV3.depositUsdcZap({
							accountId: BigInt(accountId),
							amountWei: amount.toBigInt(),
							chainId: network,
						})
					: await sdk.snxPerpsV3.depositWrappedToken({
							accountId: BigInt(accountId),
							token: token,
							amountInWei: amount.toBigInt(),
							chainId: network,
						})

			await dispatch(submitTransaction({ request }))
			if (closeOnComplete) {
				dispatch(setOpenModal(null))
			}
			dispatch(fetchSnxV3AccountData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const withdrawCrossMargin = createAsyncThunk<
	void,
	{
		amount: Wei
		asset: SynthAssetKeysV3
		unwrapToToken?: DepositableV3Assets
		payDebtWithCollateral: boolean
	},
	ThunkConfig
>(
	'futures/withdrawCrossMargin',
	async (
		{ amount, asset, unwrapToToken, payDebtWithCollateral },
		{ getState, dispatch, extra: { sdk } }
	) => {
		const { accountId, network, provider } = selectSnxV3AccountContext(getState())
		const marginEngineEnabled = selectMargineEngineEnabled(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const depositAllowances = selectDepositAllowances(getState())
		const { debt } = selectCrossMarginMarginInfo(getState())

		if (!accountId) throw new Error('Account id not found')
		try {
			if (!marginEnginePermitted) {
				await dispatch(grantMarginEnginePermission())
			}
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_cross_margin',
					hash: null,
				})
			)

			const baseToken = DepositableAssetKeysV3.USDC

			const allowance =
				(marginEngineEnabled && !!unwrapToToken
					? depositAllowances[baseToken]?.marginEngine
					: depositAllowances[baseToken]?.marketProxy) ?? wei(0)

			const allowanceWei = wei(allowance.toString())

			let requiredPayment = wei(debt)
			if (baseToken === DepositableAssetKeysV3.USDC && provider === PerpsProvider.SNX_V3_BASE) {
				requiredPayment = wei(debt).add(1)
			}

			if (!payDebtWithCollateral && wei(debt).gt(0) && allowanceWei.lt(requiredPayment)) {
				// Approve transferring USDx (arb) or USDC (base) to pay down debt with USDx wallet balance

				const address = TOKEN_ADDRESSES.USDC[network]
				if (!address) throw new Error('Address not found for USDx')
				const { request } = await sdk.tokens.approveTokenSpend({
					address,
					spender:
						marginEngineEnabled && !!unwrapToToken
							? SNX_V3_PERPS_ADDRESSES.MarginEngine[network]
							: SNX_V3_PERPS_ADDRESSES.PerpsV3MarketProxy[network],
					chainId: network,
					amount: BigInt(parseUsdcValue(requiredPayment.toString())),
				})

				await dispatch(submitTransaction({ request }))
			}

			if (unwrapToToken && !marginEngineEnabled) {
				const network = selectSnxV3Network(getState())
				const spotMarketAddress = network
					? SNX_V3_PERPS_ADDRESSES.SpotV3MarketProxy[network]
					: undefined

				const synthSymbol = DepositTokenToSynth[provider][unwrapToToken]

				if (!spotMarketAddress) throw new Error('Unsupported network')
				if (!synthSymbol) throw new Error(`Synth not found for ${unwrapToToken}`)

				const synthTokenAllowance = depositAllowances[synthSymbol]

				if (
					provider === PerpsProvider.SNX_V3_BASE &&
					depositAllowances.USDx?.spotProxy.lt(amount)
				) {
					// Approve swapping sUSD back to USDC on Base
					dispatch(
						setTransaction({
							chainId: network,
							status: TransactionStatus.AwaitingExecution,
							type: 'approve_cross_margin',
							hash: null,
						})
					)
					const address = TOKEN_ADDRESSES.USDx[network]
					if (!address) throw new Error('Address not found for USDx')
					const { request } = await sdk.tokens.approveTokenSpend({
						address: address,
						spender: spotMarketAddress,
						chainId: network,
					})

					await dispatch(submitTransaction({ request }))
					dispatch(fetchPerpsV3Balances())
				}

				if (!synthTokenAllowance || synthTokenAllowance.spotProxy?.lt(amount)) {
					// Approve unwrapping sToken back to token
					dispatch(
						setTransaction({
							chainId: network,
							status: TransactionStatus.AwaitingExecution,
							type: 'approve_cross_margin',
							hash: null,
						})
					)
					// @ts-expect-error TODO: Fix type (sETH)
					const address = TOKEN_ADDRESSES[synthSymbol][network]
					if (!address) throw new Error(`Address not found for ${synthSymbol}`)
					const { request } = await sdk.tokens.approveTokenSpend({
						address: address,
						spender: spotMarketAddress,
						chainId: network,
					})

					await dispatch(submitTransaction({ request }))
				}
			}

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_cross_margin',
					hash: null,
				})
			)

			const openPositions = selectActivePositions(getState()).map((p) => p.asset)
			const debtPaymentQuote = selectDebtPaymentQuote(getState())

			if (wei(debt).gt(0) && !debtPaymentQuote && unwrapToToken !== 'USDC' && payDebtWithCollateral)
				throw new Error('Missing quote for debt payment')

			const swapPrice =
				unwrapToToken === DepositableV3AssetsBase.USDC
					? 1n
					: wei(debtPaymentQuote?.quotedPrice || 0).toBigInt()

			const request =
				wei(debt).eq(0) &&
				unwrapToToken === DepositableV3AssetsBase.USDC &&
				provider === PerpsProvider.SNX_V3_BASE
					? await sdk.snxPerpsV3.withdrawZapUsdc({
							accountId: BigInt(accountId),
							amountWei: amount.toBigInt(),
							chainId: network,
						})
					: unwrapToToken
						? await sdk.snxPerpsV3.withdrawUnwrapped({
								accountId: BigInt(accountId),
								token: unwrapToToken,
								amountWei: amount.toBigInt(),
								chainId: network,
								quotedSwapPrice: swapPrice,
								settleDebtWithCollateral: payDebtWithCollateral,
								maxSwapSlippage: 0.05, // Keeping this loose as traders are warned in the UI what slippage they incur
								openPositions,
								// validated above
								path: debtPaymentQuote?.path ? debtPaymentQuote!.path : '0x',
							})
						: await sdk.snxPerpsV3.withdrawCollateral({
								accountId: BigInt(accountId),
								synthKey: asset,
								amount,
								useMarginEngine: false,
								chainId: network,
							})

			await dispatch(submitTransaction({ request }))

			dispatch(fetchSnxV3AccountData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const quoteDebtPaymentSwap = createAsyncThunk<
	void,
	{ asset: DepositableV3Assets; debtAmount: Wei },
	ThunkConfig
>(
	'futures/quoteDebtPaymentSwap',
	async ({ asset, debtAmount }, { getState, dispatch, extra: { sdk } }) => {
		const { network, provider } = selectSnxV3AccountContext(getState())
		const prices = selectV3SynthPrices(getState())

		try {
			dispatch(
				setQueryStatus({
					key: 'quote_debt_payment',
					status: FetchStatus.Loading,
				})
			)

			const assetIn = DepositTokenToSynth[provider][asset]
			if (!assetIn) throw new Error(`Synth not found for ${asset}`)
			const price = prices[assetIn]
			if (!price) throw new Error(`Price not found for ${asset}`)

			const quote = await sdk.snxPerpsV3.quoteCollateralDebtPayment({
				amountWei: debtAmount.toBigInt(),
				token: depositableAssetToToken(asset),
				chainId: network,
			})

			dispatch(
				setSnxV3DebtPaymentQuote({
					quote: {
						amountIn: quote.quotedAmount.toString(),
						token: asset,
						amountOut: debtAmount.toString(),
						priceImpact: quote.priceImpact.toString(),
						quotedPrice: quote.priceAfter.toString(),
						indexPrice: price.toString(),
						path: quote.path,
					},
				})
			)

			dispatch(
				setQueryStatus({
					key: 'quote_debt_payment',
					status: FetchStatus.Success,
				})
			)
		} catch (err) {
			dispatch(
				setQueryStatus({
					key: 'quote_debt_payment',
					status: FetchStatus.Error,
					error: 'Failed to fetch quote for debt payment',
				})
			)
			logError(err)
			notifyError('Failed to get quote', err)
			throw err
		}
	}
)

export const prepareCrossMarginTradeTx = createAsyncThunk<
	MultiCallTransactionRequest<string>,
	SnxPerpsV3TradePreview<string>,
	ThunkConfig
>(
	'futures/prepareCrossMarginTradeTx',
	async (preview, { getState, extra: { sdk } }): Promise<MultiCallTransactionRequest<string>> => {
		const state = getState()
		const accountId = selectSnxV3Account(state)
		const networkId = selectSnxV3Network(state)
		const marginEnginePermitted = selectMarginEnginePermitted(state)

		const marginEngineAddress = SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]

		if (!accountId || !preview) throw new Error('Invalid order submission')
		if (!marginEngineAddress) throw new Error('Unsupported network')

		const request = await sdk.snxPerpsV3.submitOrder(
			{
				marketId: BigInt(preview.marketId),
				accountId,
				sizeDelta: wei(preview.sizeDelta),
				acceptablePrice: wei(preview.desiredFillPrice),
				chainId: networkId,
			},
			{
				useEngine: marginEnginePermitted,
			}
		)

		return serializeTransactionRequest(request)
	}
)

export const submitCrossMarginTradePanelOrder = createAsyncThunk<
	void,
	Transaction | SendUserOpParameters | undefined,
	ThunkConfig
>(
	'futures/submitCrossMarginTradePanelOrder',
	async (request, { dispatch, getState, extra: { sdk, smartAccount } }) => {
		const state = getState()
		const { provider } = selectAccountContext(state)
		const {
			nativeSizeDelta: nativeSizeDeltaString,
			stopLossPrice,
			takeProfitPrice,
			orderPrice,
		} = selectTradePanelInputs(state)
		const market = selectV3SelectedMarketInfo(state)
		const accountId = selectSnxV3Account(state)
		const preview = selectCrossMarginTradePreview(state)
		const networkId = selectSnxV3Network(state)
		const orderType = selectTradeOrderType(state)
		const currentPrice = selectMarketIndexPrice(state)
		const orders = selectCrossMarginConditionalOrders(state)

		const side = selectLeverageSide(state)
		const marginEngineAddress = SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]

		const nativeSizeDelta = wei(nativeSizeDeltaString || 0)
		const abstractionWalletEnabled = selectAbstractionWalletEnabled(state)
		try {
			if (!market || !accountId || !preview) throw new Error('Invalid order submission')
			if (!marginEngineAddress) throw new Error('Unsupported network')

			const order: TransactionOrder<string> = {
				marketAsset: market.asset,
				newSize: wei(preview.newSize).abs().toString(),
				sizeDelta: wei(preview.sizeDelta).abs().toString(),
				type: orderType,
				side: side,
				price: preview.fillPrice,
			}

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
					step: 'submit-trade',
					order,
				})
			)

			// Remove stale orders
			await dispatch(removeStaleOrders())

			let limitOrderId: number | undefined

			if (orderType === OrderTypeEnum.MARKET) {
				if (!request) {
					throw new Error('No request found for market order')
				}

				await dispatch(
					submitFuturesTransaction({
						request,
					})
				)

				dispatch(fetchSnxV3AccountData())
			} else {
				if (!orderPrice?.price) throw new Error('No price set for limit / stop order')

				const orderPriceWei = wei(orderPrice.price || 0)
				const orderCondition = orderConditionFromTargetPrice(orderPriceWei, wei(currentPrice))

				const existingOrder = orders.find((order) => {
					const orderConditionPrice = order.decodedConditions[orderCondition]
					const oPrice = orderPrice.price
					return (
						orderConditionPrice &&
						oPrice &&
						wei(orderConditionPrice).eq(oPrice) &&
						order.orderDetails.marketId === market.marketId &&
						order.status === ConditionalOrderStatusV3.Pending &&
						!wei(order.orderDetails.sizeDelta).abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN)
					)
				})

				const params = {
					marketId: market.marketId,
					networkId: networkId,
					accountId,
					isReduceOnly: false,
					sizeDelta: nativeSizeDelta.toBigInt(),
					acceptablePrice: wei(preview.desiredFillPrice).toBigInt(),
					maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
					rawConditions: {
						[orderCondition]: orderPriceWei.toBigInt(),
					},
				}

				if (existingOrder) {
					// updateConditionalOrder with new sizeDelta (sum current into existing)
					await sdk.snxPerpsV3.updateConditionalOrders(
						[
							{
								orderId: existingOrder.id,
								nonce: existingOrder.nonce,
								inputs: {
									...params,
									sizeDelta: nativeSizeDelta.add(existingOrder.orderDetails.sizeDelta).toBigInt(),
								},
							},
						],
						networkId,
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
					limitOrderId = existingOrder.id
				} else {
					// Create a limit order and get its ID for dependent SL/TP orders
					const limitOrderResponse = await sdk.snxPerpsV3.createConditionalOrders(
						[params],
						networkId,
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
					limitOrderId = limitOrderResponse?.orders[0]?.id
				}

				if (!limitOrderId) throw new Error('Failed to create limit order')
				await dispatch(fetchOpenConditionalOrders([provider]))
			}

			const maxSizeDelta = nativeSizeDelta.gt(0)
				? SL_TP_MAX_SIZE_CROSS_MARGIN.neg()
				: SL_TP_MAX_SIZE_CROSS_MARGIN

			const sltpOrders: {
				type: RawCondition
				price: Wei
				acceptablePrice: Wei
				sizeDelta: Wei
				step: string
			}[] = []
			if (Number(stopLossPrice) > 0) {
				const desiredSLFillPrice = calculateDesiredFillPrice(
					maxSizeDelta,
					wei(stopLossPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
				)
				sltpOrders.push({
					type: nativeSizeDelta.gt(0) ? RawCondition.IsPriceBelow : RawCondition.IsPriceAbove,
					price: wei(stopLossPrice ?? 0),
					acceptablePrice: desiredSLFillPrice,
					sizeDelta: maxSizeDelta,
					step: 'sign-sl',
				})
			}

			if (Number(takeProfitPrice) > 0) {
				const desiredTPFillPrice = calculateDesiredFillPrice(
					maxSizeDelta,
					wei(takeProfitPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
				)
				sltpOrders.push({
					type: nativeSizeDelta.gt(0) ? RawCondition.IsPriceAbove : RawCondition.IsPriceBelow,
					price: wei(takeProfitPrice ?? 0),
					acceptablePrice: desiredTPFillPrice,
					sizeDelta: maxSizeDelta,
					step: 'sign-tp',
				})
			}
			if (sltpOrders.length > 0) {
				for (let i = 0; i < sltpOrders.length; i++) {
					const o = sltpOrders[i]
					dispatch(updateTransactionStep(o.step))
					await sdk.snxPerpsV3.createConditionalOrders(
						[
							{
								marketId: market.marketId,
								networkId: networkId,
								accountId,
								isReduceOnly: true,
								sizeDelta: o.sizeDelta.toBigInt(),
								acceptablePrice: o.acceptablePrice.toBigInt(),
								maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
								rawConditions: {
									[o.type]: o.price.toBigInt(),
								},
								dependentOnOrderId: limitOrderId,
							},
						],
						networkId,
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
				}
			}
			dispatch(updateTransactionStep(undefined))
			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
			dispatch(setOpenModal(null))
			dispatch(clearCrossMarginTradeInputs())
			dispatch(fetchSnxV3AccountData())
			dispatch(fetchOpenConditionalOrders([provider]))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			dispatch(updateTransactionStep(undefined))
			dispatch(setOpenModal(null))
			throw err
		}
	}
)

export const submitCrossMarginLimitOrder = createAsyncThunk<void, boolean, ThunkConfig>(
	'futures/submitCrossMarginLimitOrder',
	async (overridePriceProtection, { dispatch, getState, extra: { sdk } }) => {
		const state = getState()
		const { provider } = selectAccountContext(state)

		const { nativeSizeDelta: nativeSizeDeltaString, orderPrice } = selectEditPositionInputs(state)
		const { market } = selectEditPositionModalInfo(state)
		const accountId = selectSnxV3Account(state)
		const preview = selectCrossMarginTradePreview(state)
		const networkId = selectSnxV3Network(state)
		const currentPrice = selectMarketIndexPrice(state)

		const marginEngineAddress = SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
		const nativeSizeDelta = wei(nativeSizeDeltaString || 0)
		const orderPriceWei = wei(orderPrice || 0)

		try {
			if (!market || !accountId || !preview) throw new Error('Invalid order submission')
			if (!marginEngineAddress) throw new Error('Unsupported network')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const desiredFillPrice = calculateDesiredFillPrice(
				wei(nativeSizeDelta),
				orderPriceWei,
				getDefaultPriceImpact(OrderTypeEnum.LIMIT)
			)

			const order: TransactionOrder<string> = {
				marketAsset: market.asset,
				newSize: wei(preview.newSize).abs().toString(),
				sizeDelta: wei(preview.sizeDelta).abs().toString(),
				type: OrderTypeEnum.LIMIT,
				side: preview.side,
				price: preview.fillPrice,
			}

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
					step: 'submit-trade',
					order,
				})
			)

			const orderCondition = orderConditionFromTargetPrice(orderPriceWei, wei(currentPrice))

			const params = {
				marketId: market.marketId,
				networkId: networkId,
				accountId,
				isReduceOnly: false,
				sizeDelta: nativeSizeDelta.toBigInt(),
				acceptablePrice: desiredFillPrice.toBigInt(),
				maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
				rawConditions: {
					[orderCondition]: orderPriceWei.toBigInt(),
				},
			}

			await sdk.snxPerpsV3.createConditionalOrders([params], networkId)
			await dispatch(fetchOpenConditionalOrders([provider]))

			dispatch(updateTransactionStep(undefined))
			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
			dispatch(setShowEditPositionModal(null))
			dispatch(clearEditPositionInputs())
			dispatch(fetchSnxV3AccountData())
			dispatch(fetchOpenConditionalOrders([provider]))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			dispatch(updateTransactionStep(undefined))
			dispatch(setShowEditPositionModal(null))
			throw err
		}
	}
)

export const submitCrossMarginSLTPUpdates = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitCrossMarginSLTPUpdates',
	async (_, { dispatch, getState, extra: { sdk, smartAccount } }) => {
		const { market, position } = selectEditPositionModalInfo(getState())
		const accountId = selectSnxV3Account(getState())
		const networkId = selectSnxV3Network(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const existingOrders = selectAllSnxV3SLTPOrders(getState())
		const { stopLossPrice, takeProfitPrice } = selectSlTpModalInputs(getState())
		const abstractionWalletEnabled = selectAbstractionWalletEnabled(getState())
		const marginEngineAddress = networkId
			? SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
			: undefined

		try {
			if (!marginEngineAddress) throw new Error('Unsupported network')
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !accountId)
				throw new Error('Invalid order submission')
			if (!marginEnginePermitted) throw new Error('Margin engine not permitted')
			if (!position) throw new Error('No position for orders')

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
				})
			)

			const stopLoss = existingOrders.find(
				(o) => o.asset === market.asset && o.orderType === OrderTypeEnum.STOP_LOSS
			)
			const takeProfit = existingOrders.find(
				(o) => o.asset === market.asset && o.orderType === OrderTypeEnum.TAKE_PROFIT
			)

			const stopLossExists = !!stopLoss
			const takeProfitExists = !!takeProfit

			const stopLossPriceNotEmpty = stopLossPrice !== ''
			const takeProfitPriceNotEmpty = takeProfitPrice !== ''

			const stopLossChanged =
				(!stopLossExists && stopLossPriceNotEmpty) ||
				(stopLossExists &&
					(stopLossPrice === '' || !wei(stopLoss.targetPrice).eq(wei(stopLossPrice))))

			const takeProfitChanged =
				(!takeProfitExists && takeProfitPriceNotEmpty) ||
				(takeProfitExists &&
					(takeProfitPrice === '' || !wei(takeProfit.targetPrice).eq(wei(takeProfitPrice))))

			const isLong = position?.details.side === PositionSide.LONG

			const maxSizeDelta = isLong ? SL_TP_MAX_SIZE_CROSS_MARGIN.neg() : SL_TP_MAX_SIZE_CROSS_MARGIN

			const sltpOrders: {
				updateOrder?: {
					orderId: number
					nonce: number
					status?: ConditionalOrderStatusV3
					inputs: ConditionalOrderInput
				}
				cancelOrder?: {
					orderId: number
					nonce: number
				}
				createOrder?: ConditionalOrderInput
			}[] = []

			const sharedParams = {
				marketId: market.marketId,
				networkId: networkId,
				accountId,
				isReduceOnly: true,
				sizeDelta: maxSizeDelta.toBigInt(),
				maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
			}

			if (stopLossChanged) {
				if (stopLossPriceNotEmpty) {
					const desiredSLFillPrice = calculateDesiredFillPrice(
						maxSizeDelta,
						wei(stopLossPrice || 0),
						wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
					)
					const conditionType = isLong ? RawCondition.IsPriceBelow : RawCondition.IsPriceAbove
					sltpOrders.push(
						stopLoss
							? {
									updateOrder: {
										orderId: stopLoss.id,
										nonce: stopLoss.nonce!,
										inputs: {
											...sharedParams,
											acceptablePrice: desiredSLFillPrice.toBigInt(),
											rawConditions: {
												[conditionType]: wei(stopLossPrice).toBigInt(),
											},
										},
									},
								}
							: {
									createOrder: {
										...sharedParams,
										acceptablePrice: desiredSLFillPrice.toBigInt(),
										rawConditions: {
											[conditionType]: wei(stopLossPrice).toBigInt(),
										},
									},
								}
					)
				} else {
					sltpOrders.push({
						cancelOrder: {
							orderId: stopLoss!.id,
							nonce: stopLoss!.nonce!,
						},
					})
				}
			}

			if (takeProfitChanged) {
				if (takeProfitPriceNotEmpty) {
					const desiredTPFillPrice = calculateDesiredFillPrice(
						maxSizeDelta,
						wei(takeProfitPrice || 0),
						wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
					)
					const conditionType = isLong ? RawCondition.IsPriceAbove : RawCondition.IsPriceBelow
					sltpOrders.push(
						takeProfit
							? {
									updateOrder: {
										orderId: takeProfit.id,
										nonce: takeProfit.nonce!,
										inputs: {
											...sharedParams,
											acceptablePrice: desiredTPFillPrice.toBigInt(),
											rawConditions: {
												[conditionType]: wei(takeProfitPrice).toBigInt(),
											},
										},
									},
								}
							: {
									createOrder: {
										...sharedParams,
										acceptablePrice: desiredTPFillPrice.toBigInt(),
										rawConditions: {
											[conditionType]: wei(takeProfitPrice).toBigInt(),
										},
									},
								}
					)
				} else {
					sltpOrders.push({
						cancelOrder: takeProfit?.nonce
							? {
									orderId: takeProfit?.id,
									nonce: takeProfit?.nonce,
								}
							: undefined,
					})
				}
			}

			const updates = sltpOrders.filter((o) => !!o.updateOrder)
			const creates = sltpOrders.filter((o) => !!o.createOrder)
			const cancels = sltpOrders.filter((o) => !!o.cancelOrder)

			if (sltpOrders.length > 0) {
				dispatch(
					setTransaction({
						chainId: networkId,
						status: TransactionStatus.AwaitingExecution,
						type: 'submit_cross_order',
						hash: null,
					})
				)

				if (updates.length > 0) {
					await sdk.snxPerpsV3.updateConditionalOrders(
						updates.map((o) => ({
							orderId: o.updateOrder!.orderId,
							nonce: o.updateOrder!.nonce,
							inputs: o.updateOrder!.inputs,
							status: o.updateOrder?.status,
						})),
						networkId,
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
				}
				if (cancels.length > 0) {
					await sdk.snxPerpsV3.cancelConditionalOrders(
						cancels.map((o) => o.cancelOrder!.orderId),
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
				}
				if (creates.length > 0) {
					await sdk.snxPerpsV3.createConditionalOrders(
						creates.map((o) => o.createOrder!),
						networkId,
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
				}
			}

			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
			dispatch(fetchSnxV3AccountData())
			dispatch(setShowEditPositionModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitCrossMarginPartialSLTP = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitCrossMarginPartialSLTP',
	async (_, { dispatch, getState, extra: { sdk, smartAccount } }) => {
		const { market, position } = selectEditPositionModalInfo(getState())
		const accountId = selectSnxV3Account(getState())
		const networkId = selectSnxV3Network(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())
		const abstractionWalletEnabled = selectAbstractionWalletEnabled(getState())
		const { stopLossPrice, stopLossAmount, takeProfitPrice, takeProfitAmount } =
			selectSlTpModalInputs(getState())

		const marginEngineAddress = networkId
			? SNX_V3_PERPS_ADDRESSES.MarginEngine[networkId]
			: undefined

		try {
			if (!marginEngineAddress) throw new Error('Unsupported network')
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !accountId)
				throw new Error('Invalid order submission')
			if (!marginEnginePermitted) throw new Error('Margin engine not permitted')
			if (!position) throw new Error('No position for orders')

			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
				})
			)

			const stopLossChanged = stopLossPrice !== '' && stopLossAmount !== ''
			const takeProfitChanged = takeProfitPrice !== '' && takeProfitAmount !== ''
			const isLong = position?.details.side === PositionSide.LONG

			const sltpOrders: ConditionalOrderInput[] = []

			const sharedParams = {
				marketId: market.marketId,
				networkId: networkId,
				accountId,
				isReduceOnly: true,
				maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
			}

			if (stopLossChanged) {
				const sizeDelta = isLong
					? wei(stopLossAmount).neg().toBigInt()
					: wei(stopLossAmount).toBigInt()
				const desiredSLFillPrice = calculateDesiredFillPrice(
					wei(sizeDelta),
					wei(stopLossPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
				)

				const conditionType = isLong ? RawCondition.IsPriceBelow : RawCondition.IsPriceAbove
				sltpOrders.push({
					...sharedParams,
					sizeDelta: sizeDelta,
					acceptablePrice: desiredSLFillPrice.toBigInt(),
					rawConditions: {
						[conditionType]: wei(stopLossPrice).toBigInt(),
					},
				})
			}

			if (takeProfitChanged) {
				const sizeDelta = isLong
					? wei(takeProfitAmount).neg().toBigInt()
					: wei(takeProfitAmount).toBigInt()
				const desiredTPFillPrice = calculateDesiredFillPrice(
					wei(sizeDelta),
					wei(takeProfitPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
				)

				const conditionType = isLong ? RawCondition.IsPriceAbove : RawCondition.IsPriceBelow
				sltpOrders.push({
					...sharedParams,
					sizeDelta: sizeDelta,
					acceptablePrice: desiredTPFillPrice.toBigInt(),
					rawConditions: {
						[conditionType]: wei(takeProfitPrice).toBigInt(),
					},
				})
			}

			if (sltpOrders.length > 0) {
				dispatch(
					setTransaction({
						chainId: networkId,
						status: TransactionStatus.AwaitingExecution,
						type: 'submit_cross_order',
						hash: null,
					})
				)

				if (sltpOrders.length > 0) {
					await sdk.snxPerpsV3.createConditionalOrders(
						sltpOrders,
						networkId,
						abstractionWalletEnabled ? smartAccount.getSigner() ?? undefined : undefined
					)
				}
			}

			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
			dispatch(fetchSnxV3AccountData())

			dispatch(setEditPositionInputs({ nativeSizeDelta: '', marginDelta: '', orderPrice: '' }))
			dispatch(setShowEditPositionModal(null))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const withdrawCMAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const address = selectSnxV3Account(getState())
		const networkId = selectSnxV3Network(getState())
		try {
			if (!address) throw new Error('No cross margin account')
			dispatch(
				setTransaction({
					chainId: networkId,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_keeper_balance',
					hash: null,
				})
			)

			const { request } = await sdk.snxPerpsV3.withdrawCreditForOrders(address, amount, networkId)
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				networkId
			)
			await monitorAndAwaitTransaction(networkId, dispatch, txHash)
			dispatch(fetchPerpsV3Balances())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitCrossMarginReducePositionOrder = createAsyncThunk<
	void,
	Transaction | SendUserOpParameters | undefined,
	ThunkConfig
>(
	'futures/submitCrossMarginReducePositionOrder',
	async (request, { getState, dispatch, extra: { sdk, smartAccount } }) => {
		const networkId = selectSnxV3Network(getState())
		const { market, position, marketPrice } = selectEditPositionModalInfo(getState())
		const accountId = selectSnxV3Account(getState())
		const { nativeSizeDelta, orderType, price } = selectClosePositionOrderInputs(getState())
		const preview = selectClosePositionPreview(getState())
		const marginEnginePermitted = selectMarginEnginePermitted(getState())

		try {
			if (!position) throw new Error('Missing position data')
			if (!accountId) throw new Error('Account not found')
			if (!preview) throw new Error('Missing trade preview')
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN) throw new Error('Missing market')

			const order: TransactionOrder<string> = {
				marketAsset: market.asset,
				newSize: wei(preview.newSize).abs().toString(),
				sizeDelta: wei(preview.sizeDelta).abs().toString(),
				type: orderType,
				side: preview.side,
				price: preview.fillPrice,
			}

			if (orderType !== OrderTypeEnum.MARKET) {
				if (!marginEnginePermitted) {
					await dispatch(grantMarginEnginePermission())
				}

				if (!price?.value) throw new Error('Missing price')
				const orderCondition = orderConditionFromTargetPrice(wei(price.value), wei(marketPrice))

				await sdk.snxPerpsV3.createConditionalOrders(
					[
						{
							marketId: market.marketId,
							accountId,
							networkId,
							sizeDelta: wei(nativeSizeDelta).toBigInt(),
							acceptablePrice: wei(preview.desiredFillPrice).toBigInt(),
							isReduceOnly: true,
							maxExecutorFee: wei(KEEPER_USD_GAS_FEE).toBigInt(),
							rawConditions: {
								[orderCondition]: wei(price.value).toBigInt(),
							},
						},
					],
					networkId,
					smartAccount.getSigner() ?? undefined
				)
			} else {
				if (!request) throw new Error('Missing request')

				dispatch(
					setTransaction({
						chainId: networkId,
						status: TransactionStatus.AwaitingExecution,
						type: 'close_cross_margin',
						hash: null,
						order,
					})
				)

				await dispatch(
					submitFuturesTransaction({
						request,
					})
				)
			}
			dispatch(setShowEditPositionModal(null))
			dispatch(clearCrossMarginTradeInputs())

			dispatch(fetchPendingMarketOrders([market.provider]))
			dispatch(fetchSnxV3AccountData())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
		// TODO: hookup submit v3 order
	}
)

export const executeAsyncOrder = createAsyncThunk<void, `0x${string}`, ThunkConfig>(
	'futures/executeAsyncOrder',
	async (originTxHash, { getState, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV3AccountContext(getState())
		if (!accountId) throw new Error('No wallet connected')

		const request = await sdk.snxPerpsV3.executeAsyncOrder(BigInt(accountId), network)
		const txHash = await sdk.transactions.sendTransaction(request, network)
		await monitorFollowingTransaction(originTxHash, txHash, network)
	}
)

export const editCrossMarginTradeOrderPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const rate = selectV3SkewAdjustedPrice(getState())
		const orderType = selectTradeOrderType(getState())
		const side = selectLeverageSide(getState())
		const inputs = selectTradePanelInputs(getState())
		dispatch(setTradePanelOrderPrice(price))
		const invalidLabel = orderPriceInvalidLabel(price, side, rate, orderType)
		dispatch(setOrderPriceInvalidLabel(invalidLabel))
		if (!invalidLabel && price && inputs.susdSize) {
			// Recalc the trade
			dispatch(editCrossMarginTradeSize(inputs.susdSize, 'usd'))
		}
	}

export const editCrossMarginTradeSlippage =
	(slippage: string): AppThunk =>
	(dispatch, getState) => {
		const inputs = selectTradePanelInputs(getState())
		dispatch(setSlippageInput(slippage))
		// Recalc the trade
		dispatch(editCrossMarginTradeSize(inputs.susdSize, 'usd'))
	}

export const editCrossMarginPositionSize =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		const { orderPrice, orderType } = selectEditPositionInputs(getState())
		const { marketPrice, market, indexPrice, accountId } = selectEditPositionModalInfo(getState())
		if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !indexPrice || !accountId)
			throw new Error('Missing market data')

		if (isZero(nativeSizeDelta) || (isZero(orderPrice) && orderType !== OrderTypeEnum.MARKET)) {
			dispatch(clearTradePreview())
			return
		}

		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market: market,
					orderPrice: wei(orderPrice || marketPrice),
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'edit',
					indexPrice,
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editCrossMarginPositionOrderPrice =
	(orderPrice: string): AppThunk =>
	(dispatch, getState) => {
		const { nativeSizeDelta } = selectEditPositionInputs(getState())
		const { marketPrice, market, indexPrice, accountId } = selectEditPositionModalInfo(getState())
		if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !indexPrice || !accountId)
			throw new Error('Missing market data')
		if (isZero(orderPrice) || isZero(nativeSizeDelta)) {
			dispatch(clearTradePreview())
			return
		}

		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market: market,
					orderPrice: wei(orderPrice || marketPrice),
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'edit',
					indexPrice,
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editCrossMarginConditionalOrder =
	(order: ConditionOrderTableItem, newSize: string, newPrice: string): AppThunk =>
	(dispatch, getState) => {
		const markets = selectV3Markets(getState())
		const prices = selectPrices(getState())
		const accountId = selectSnxV3Account(getState())

		const market = markets.find((m) => m.asset === order.asset)

		if (!market) throw new Error('Missing market data')
		if (!accountId) throw new Error('Missing account data')

		const price = prices[market.asset]
		const marketPrice = price?.offChain
			? wei(price.offChain).mul(wei(market.marketSkew).div(market.settings.skewScale).add(1))
			: ZERO_WEI

		if (!market || !price.offChain) throw new Error('Missing price data')
		try {
			dispatch(
				stageTradePreview({
					accountId: accountId.toString(),
					provider: market.provider,
					market: market,
					orderPrice: wei(newPrice || 0),
					sizeDelta: wei(newSize || 0),
					action: 'edit',
					indexPrice: price.offChain,
					marketPrice: marketPrice,
					isConditional: true,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editCrossMarginConditionalOrderPrice =
	(order: ConditionOrderTableItem, newPrice: string): AppThunk =>
	(dispatch, getState) => {
		const side = order.tradeDirection
		const { size } = selectEditCOModalInputs(getState())
		const priceInfo = selectOffchainPricesInfo(getState())
		const marketPrice = priceInfo[order.asset]?.price
		if (!marketPrice) throw new Error('Missing price data')

		dispatch(setEditConditonalOrderModalPrice(newPrice))
		const invalidLabel = orderPriceInvalidLabel(newPrice, side, marketPrice, order.orderType)
		dispatch(setConditionalOrderPriceInvalidLabel(invalidLabel))
		dispatch(editCrossMarginConditionalOrder(order, size || '0', newPrice))
	}

export const editCrossMarginConditionalOrderSize =
	(order: ConditionOrderTableItem, size: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setEditConditonalOrderModalSize(size))
		const { orderPrice } = selectEditCOModalInputs(getState())

		dispatch(editCrossMarginConditionalOrder(order, size, orderPrice.price || '0'))
	}

export const submitCrossMarginAdjustPositionSize = createAsyncThunk<
	void,
	{ overridePriceProtection: boolean; request: Transaction | SendUserOpParameters | undefined },
	ThunkConfig
>(
	'futures/submitCrossMarginAdjustPositionSize',
	async ({ overridePriceProtection, request }, { getState, dispatch }) => {
		const state = getState()
		const { market } = selectEditPositionModalInfo(state)
		const account = selectSnxV3Account(state)
		const chainId = selectSnxV3Network(state)
		const preview = selectEditPositionPreview(state)
		const { nativeSizeDelta } = selectEditPositionInputs(state)

		try {
			if (!request) throw new Error('Missing request')
			if (market?.marginType !== FuturesMarginType.CROSS_MARGIN)
				throw new Error('Market info not found')
			if (!account) throw new Error('No account found')
			if (!nativeSizeDelta || nativeSizeDelta === '') throw new Error('No margin amount set')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const order: TransactionOrder<string> = {
				marketAsset: market.asset,
				newSize: wei(preview.newSize).abs().toString(),
				sizeDelta: wei(preview.sizeDelta).abs().toString(),
				type: OrderTypeEnum.MARKET,
				side: preview.side,
				price: preview.fillPrice,
			}

			dispatch(
				setTransaction({
					chainId,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_cross_order',
					hash: null,
					order,
				})
			)

			await dispatch(
				submitFuturesTransaction({
					request,
				})
			)

			dispatch(setShowEditPositionModal(null))
			dispatch(clearCrossMarginTradeInputs())
			dispatch(fetchSnxV3AccountData())
			dispatch(clearCrossMarginTradeInputs())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const editCloseCMPositionPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const state = getState()
		const { nativeSizeDelta, orderType } = selectClosePositionOrderInputs(state)
		const { position, marketPrice, market, indexPrice, accountId } =
			selectEditPositionModalInfo(state)

		if (market?.marginType !== FuturesMarginType.CROSS_MARGIN || !indexPrice || !accountId)
			throw new Error('No market or account data')

		const closeTradeSide =
			position?.details.side === PositionSide.SHORT ? PositionSide.LONG : PositionSide.SHORT
		const invalidLabel = orderPriceInvalidLabel(
			price || '0',
			closeTradeSide,
			marketPrice,
			orderType
		)

		dispatch(setClosePositionPrice({ value: price, invalidLabel }))

		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market: market,
					orderPrice: Number.isNaN(Number(price)) ? undefined : wei(price),
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'close',
					isConditional: orderType !== OrderTypeEnum.MARKET,
					indexPrice,
					marketPrice: wei(marketPrice),
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: market.provider,
				})
			)
		}
	}

const fetchMaxDepositAmounts = createAsyncThunk<void, void, ThunkConfig>(
	'futures/maxDepositAmounts',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const network = selectSnxV3Network(getState())
		const provider = selectPerpsProvider(getState())
		if (!providerIsCrossMargin) return
		try {
			const assets = Object.keys(DepositableV3AssetsBase)

			const maxDepositAmounts = await sdk.snxPerpsV3.getMaxDepositAmounts(
				assets as DepositableV3Assets[],
				network
			)

			dispatch(
				setSnxV3MaxDepositAmounts({
					maxDeposits: { ...maxDepositAmounts },
					provider,
				})
			)
		} catch (err) {
			logError(err)
			throw err
		}
	}
)

export const refetchGlobalTradeHistory = createAsyncThunk<void, void, ThunkConfig>(
	'futures/refetchGlobalTradeHistory',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const provider = selectPerpsProvider(getState())
		const marketAsset = selectMarketAsset(getState())
		const { network } = selectAccountContext(getState())
		if (!providerIsCrossMargin(provider))
			throw new Error('Should be used only for cross margin (SNX V3)')

		const existingTrades = selectSnxV3GlobalTradesForMarket(getState())

		const maxTimestamp = currentTimestamp()
		const minTimestamp = 0

		const { data: trades, status } = await refetchWithComparator(
			() =>
				sdk.snxPerpsV3.getAllTradesByMarket(marketAsset, network as SnxV3NetworkIds, {
					minTimestamp,
					maxTimestamp,
					pageSize: 50,
				}),
			// @ts-expect-error Serialized value, but we compare by strings anyway
			existingTrades,
			(existing, next) => existing.at(0)?.txnHash === next.at(0)?.txnHash,
			DEFAULT_REFETCH_INTERVAL
		)

		if (status === 'timeout') {
			throw new Error('Timeout fetching global trades')
		}

		if (existingTrades.length === 0 && trades?.length === 0) {
			return
		}

		if (!trades) {
			throw new Error('No trades found')
		}

		dispatch(
			setGlobalTradeHistory({
				trades: serializeTrades(trades),
				provider,
				marketAsset,
			})
		)
	}
)
