import { cookieStorage, createConfig } from '@account-kit/core'
import { alchemy, base, baseSepolia, optimism, optimismSepolia } from '@account-kit/infra'

import { DEFAULT_CHAIN, DEFAULT_SESSION_MS } from 'constants/network'

export const alchemyConfig = createConfig({
	chain: DEFAULT_CHAIN,
	transport: alchemy({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY! }),
	chains: [base, baseSepolia, optimism, optimismSepolia].map((chain) => ({
		chain,
		policyId: process.env.NEXT_PUBLIC_ALCHEMY_POLICY_ID!,
	})),
	ssr: true,
	storage: cookieStorage,
	enablePopupOauth: true,
	sessionConfig: {
		expirationTimeMs: DEFAULT_SESSION_MS,
	},
})
