import { DialogContent, DialogOverlay } from '@reach/dialog'
import CloseIcon from 'assets/svg/app/close.svg'
import CrossIcon from 'assets/svg/app/cross.svg'
import Card, { CardHeader, CardBody } from 'components/Card'
import { zIndex } from 'constants/ui'
import { type CSSProperties, type FC, type ReactNode, memo } from 'react'
import { type Props, Rnd } from 'react-rnd'

import useWindowSize from 'hooks/useWindowSize'
import Logo from 'sections/shared/Layout/Logo'
import styled from 'styled-components'
import { resetButtonCSS } from 'styles/common'
import media from 'styles/media'
import Button from './Button'
import Body from './Text/Body'
import { FlexDivRowCentered } from './layout/flex'

const CircleBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`

const Circle = styled.div`
  position: absolute;
  border-radius: 50%;
  border: 1px solid #00F0FF;
  box-sizing: border-box;
`

const TopLeftCircle = styled(Circle)`
  transform: translate(-50%, -50%);
  left: -200px;
  top: -200px;
`

const BottomRightCircle = styled(Circle)`
  transform: translate(50%, 50%);
  right: -200px;
  bottom: -200px;
`

// Generate circle components
const generateCircles = (
	CircleComponent: FC<{ style: CSSProperties }>,
	deviceType?: 'mobile' | 'tablet' | 'desktop'
) => {
	let count = 20
	if (deviceType === 'tablet' || deviceType === 'mobile') {
		count = 15
	}

	const startDiameter = 540
	const diameterIncrement = 90
	const startOpacity = 0.5

	return Array.from({ length: count }, (_, index) => {
		const diameter = startDiameter + index * diameterIncrement
		const opacity = startOpacity - index * (startOpacity / (count - 1))

		return (
			<CircleComponent
				// biome-ignore lint/suspicious/noArrayIndexKey: it's fixed circle
				key={index}
				style={{
					width: `${diameter}px`,
					height: `${diameter}px`,
					opacity: opacity,
				}}
			/>
		)
	})
}

type BaseModalProps = {
	title: ReactNode
	isOpen?: boolean
	onDismiss: () => void
	children: ReactNode
	showCross?: boolean
	lowercase?: boolean
	rndProps?: Props
	headerBackground?: ReactNode
	transparentOverlay?: boolean
}

type ModalContentWrapperProps = {
	children: ReactNode
	rndProps?: Props
}

const ModalContentWrapper: FC<ModalContentWrapperProps> = memo(({ children, rndProps }) => {
	if (rndProps?.disableDragging) {
		return <>{children}</>
	} else {
		return <Rnd {...rndProps}>{children}</Rnd>
	}
})

const BaseModal: FC<BaseModalProps> = memo(
	({
		onDismiss,
		title,
		children,
		isOpen,
		showCross = true,
		lowercase,
		rndProps = { disableDragging: true, enableResizing: false },
		headerBackground,
		transparentOverlay = true,
		...rest
	}) => {
		const { deviceType } = useWindowSize()

		return (
			<StyledDialogOverlay
				onDismiss={transparentOverlay ? onDismiss : undefined}
				isOpen={isOpen}
				transparent={transparentOverlay}
				{...rest}
			>
				{!transparentOverlay && (
					<CircleBackground>
						<Container>
							<Logo />
							<CloseButton size="small" onClick={onDismiss}>
								<FlexDivRowCentered columnGap="6px">
									<Body size="large" weight="bold">
										Exit
									</Body>
									<StyledCloseIcon width={10} height={10} />
								</FlexDivRowCentered>
							</CloseButton>
						</Container>
						{deviceType !== 'mobile' && generateCircles(TopLeftCircle, deviceType)}
						{generateCircles(BottomRightCircle, deviceType)}
					</CircleBackground>
				)}
				<StyledDialogContent aria-label="modal">
					<ModalContentWrapper rndProps={rndProps}>
						<StyledCard className="card">
							{headerBackground}
							{title && (
								<StyledCardHeader lowercase={lowercase} noBorder className="card-header">
									{title}
									{showCross && (
										<DismissButton onClick={onDismiss}>
											<CrossIcon />
										</DismissButton>
									)}
								</StyledCardHeader>
							)}
							<StyledCardBody className="card-body">{children}</StyledCardBody>
						</StyledCard>
					</ModalContentWrapper>
				</StyledDialogContent>
			</StyledDialogOverlay>
		)
	}
)

const StyledCloseIcon = styled(CloseIcon)`
	path {
		stroke: white;
		stroke-width: 3;
	}
`

const Container = styled(FlexDivRowCentered)`
	align-items: center;
	padding: 10px 15px;
`

const StyledDialogOverlay = styled(DialogOverlay)<{ transparent: boolean }>`
	z-index: ${zIndex.DIALOG_OVERLAY};
	background: ${(props) =>
		props.transparent
			? 'rgba(0, 0, 0, 0.7)'
			: props.theme.colors.selectedTheme.newTheme.containers.secondary.background};
			
	${media.lessThan('md')`
		z-index: ${zIndex.MOBILE_FOOTER};
		overflow: scroll;
		display: flex;
		align-items: flex-start;
		padding-top: 50px;
	`}
`

const StyledDialogContent = styled(DialogContent)`
	padding: 0;
	border: 0;
	background: none;
	width: 502px;

	${media.lessThan('md')`
		&&& {
			width: 476px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	`}

	${media.lessThan('sm')`
		&&& {
			width: 100%;
			margin: 0;
		}
	`}
`

const StyledCard = styled(Card)`
	background-color: ${(props) => props.theme.colors.selectedTheme.background};
	border-radius: 10px;
	position: relative;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	${media.lessThan('md')`
		&&& {
			width: 100%;
			border-radius: 10px 10px 0 0;
			margin: 10px;
		}
		svg.bg {
			margin-left: -16px;
		}
	`}
	max-height: 100vh;
	overflow-y: auto;
	svg.bg {
		margin-bottom: -120px;
	}
`

const StyledCardHeader = styled(CardHeader)`
	min-height: 45px;
	height: fit-content;
	font-size: 16px;
	font-weight: 400;
	padding: 20px;
`

const StyledCardBody = styled(CardBody)`
	overflow-y: scroll;
	padding: 0 20px;
	padding-bottom: 20px;
`

const DismissButton = styled.button`
	${resetButtonCSS};
	position: absolute;
	right: 20px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}
`

const CloseButton = styled(Button)`
		z-index: 100;
`
export const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		width: 400px;
	}
`

export default BaseModal
