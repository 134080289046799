import type KwentaSDK from '@kwenta/sdk'
import type { PerpsProvider } from '@kwenta/sdk/types'

import type { AlchemySmartAccountService } from 'services/alchemy/alchemySmartAccount'
import type { AccountAbstractionFactory } from './accountAbstraction'
import type { AppDispatch, RootState } from './store'

export enum FetchStatus {
	Idle = 0,
	Loading = 1,
	Success = 2,
	Error = 3,
}

export type QueryStatus = {
	status: FetchStatus
	provider?: PerpsProvider
	error?: string | null
}

export type ThunkConfig = {
	dispatch: AppDispatch
	state: RootState
	extra: {
		sdk: KwentaSDK
		accountAbstractionFactory: AccountAbstractionFactory
		smartAccount: AlchemySmartAccountService
	}
}
